import axios from "axios";

const apiURL = (category) =>
  `${process.env.REACT_APP_API_URL}/${category}/api/`;

const apiConnection = (category) =>
  axios.create({
    baseURL: apiURL(category),
  });

// api gestao
export const gestaoApi = apiConnection("gestao");

// api portal
export const portalApi = apiConnection("portal");

// api cbca
export const governancaApi = apiConnection("governanca");

//esportes
export const CbfutsalApi = apiConnection("cbfutsal");
export const CBBApi = apiConnection("cbb");
export const CBVApi = apiConnection("cbv");

export const CBDE_api = apiConnection("cbde");
