import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

//components
import PageTitle from '../../components/PageTitle';

//material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

//router
import { Link, Route, useParams } from 'react-router-dom';

// - api
import { governancaApi } from '../../services/api';

import MetaTags from 'react-meta-tags';

//static data
// import SocialFotosProjeto from '../../assets/data/dimensoes/socialFotosProjeto';
// import SocialVideosProjeto from '../../assets/data/dimensoes/socialVideosProjeto';
// import EsportsVideosProjeto from '../../assets/data/dimensoes/esportsVideosTorneio';
// import EsportsVideosDesafio from '../../assets/data/dimensoes/esportsVideoDesafio';

// import Gallery from './sections/Gallery';
// import Videos from './sections/Videos';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '30px 0',
    },
    SectionTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        padding: '6px 0px 10px 0px',
    },
    sectionDimensao: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0',
    },
    container: {
        margin: '25px auto',
        maxWidth: '1400px'
    },
    corpo: {
        fontSize: '1rem',
        '& p': {
            margin: '0px !important',
        },
        '& img': {
            maxWidth: '100%',
            height: 'auto',
            transform: 'all 250ms ease-in-out',
            '&:hover': {
                opacity: 0.8,
                cursor: 'pointer'
            },
        },
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    errorContainer: {
        paddingTop: '1rem',
        paddingBottom: '4rem',
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
}));

function FeemgDimensoes(params) {
    const classes = useStyles();
    const [totalPages, setTotalPages] = useState(1);
    const page = params.page;
    const { idMenu, titulo } = useParams();
    const [orgao, setOrgao] = useState([])
    const [tituloMenu, setTituloMenu] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function getOrgao() {
            await governancaApi.get('orgao', {
                params: {
                    id_menu: idMenu,
                    flag_del: 0,
                    sort: '-data',
                    page: page
                },
            }).then((response) => {
                setOrgao(response.data.items)
                setPageCount(response.data._meta.totalCount)
                setTotalPages(response.data._meta.pageCount)
                setLoading(false)
            });
        }
        getOrgao()

        async function getTituloMenu() {
            await governancaApi.get('menu-governanca', {
                params: {
                    id: idMenu,
                },
            }).then((response) => {
                setTituloMenu(response.data.items[0].titulo)
            });
        }
        getTituloMenu()

    }, [idMenu, page])


    if (loading) {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="FEEMG | DIMENSÕES" pageTitle="" />
                <Paper square elevation={0} className={classes.root}>
                    <div className={classes.spinnerContainer}>
                        <CircularProgress />
                    </div>
                </Paper>
            </React.Fragment>
        )
    } else if (pageCount > 0) {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{tituloMenu} - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="FEEMG | DIMENSÕES" pageTitle={tituloMenu} />
                <Paper elevation={0} square className={classes.sectionDimensao}>
                    {orgao.map((item, key) => (
                        <Container key={key} maxWidth="lg" className={classes.container}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Typography className={classes.SectionTitle} variant="h5" component="h5" color="primary">{item.titulo}</Typography>
                                    <SimpleReactLightbox>
                                        <SRLWrapper>
                                            <div className={classes.corpo}>
                                                {ReactHtmlParser(item.corpo)}
                                            </div>
                                        </SRLWrapper>
                                    </SimpleReactLightbox>
                                </Grid>
                            </Grid>
                        </Container>
                    ))}
                    <Container>
                        {totalPages > 1 ?
                            <div className={classes.paginationContainer}>
                                <Route>
                                    {({ location }) => {
                                        const query = new URLSearchParams(location.search);
                                        const page = parseInt(query.get('p') || '1', 10);

                                        return (
                                            <Pagination
                                                page={page}
                                                count={totalPages}
                                                renderItem={(item) => {
                                                    return (
                                                        <PaginationItem
                                                            component={Link}
                                                            to={`/feemg-7d/${idMenu}/${titulo}${item.page === 1 ? '' : `?p=${item.page}`}`}
                                                            {...item}
                                                        />
                                                    )
                                                }}
                                            />
                                        );
                                    }}
                                </Route>
                            </div>
                            :
                            <></>
                        }

                    </Container>
                </Paper>
            </React.Fragment>
        )


    } else {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="FEEMG | DIMENSÕES" pageTitle="" />
                <Paper elevation={0} square className={classes.sectionDimensao} style={{ height: '20vh' }}>
                    <Container>
                        <div className={classes.errorContainer}>
                            <Alert severity="error">A pesquisa não retornou resultados.</Alert>
                        </div>
                    </Container>
                </Paper>
            </React.Fragment>
        )
    }
}

export default FeemgDimensoes;
