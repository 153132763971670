export const formataCNPJ = (cnpj) => {
    var _cnpj = cnpj.replace(/\./g, '').replace("/", "").replace(/-/g, '')
    return `${_cnpj.slice(0, 2)}.${_cnpj.slice(2, 5)}.${_cnpj.slice(5, 8)}/${_cnpj.slice(8, 12)}-${_cnpj.slice(12, 14)}`
}

export const formataTelefone = (telefone) => {
    var tel = telefone.replace(/\(|\)/g, "").replace(/\./g, '').replace(/ /g, '')
    if (tel.length === 8) {
        return `${tel.slice(0, 4)}-${tel.slice(4, 8)}`
    } else if (tel.length === 10) {
        return `(${tel.slice(0, 2)}) ${tel.slice(2, 6)}-${tel.slice(6, 10)}`
    } else {
        return `(${tel.slice(0, 2)}) ${tel.slice(2, 7)}-${tel.slice(7, 11)}`
    }
}

export const formataCEP = (cep) => {
    var _cep = cep.replaceAll(".", "").replaceAll("-", "")
    return `${_cep.slice(0, 5)}-${_cep.slice(5, 9)}`
}

export const slugNoticia = (titulo) => {
    return titulo.normalize("NFD").replace(new RegExp('\\.', 'g'), '').replace(new RegExp('!', 'g'), '').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').replace(/[^0-9A-Za-zÀ-ÖØ-öø-ÿ]/ig, '-').replace("---", "-").replace("--", "-").toLowerCase()
}

export const getAno = (data) => {
    var date = new Date(data);
    return date.getFullYear().toString()
}