const regionais = [
    {
        id: 1,
        titulo: 'Regional Norte',
        professor: 'Nilson Silva Filho',
        email: 'regionalnorte@feemg.com.br',
        sre: [
            { titulo: 'Curvelo' },
            { titulo: 'Diamantina' },
            { titulo: 'Janaúba' },
            { titulo: 'Januária' },
            { titulo: 'Montes Claros' },
            { titulo: 'Pirapora' },
        ]
    },
    {
        id: 2,
        titulo: 'Regional Metropolitana',
        professor: 'Cláudio Olívio Vilela Lima',
        email: 'regionalmetropolitana@feemg.com.br',
        sre: [
            { titulo: 'Metropolitana A' },
            { titulo: 'Metropolitana B' },
            { titulo: 'Metropolitana C' },
        ]
    },
    {
        id: 3,
        titulo: 'Regional Vale do Aço',
        professor: 'Nestor Fontes Moreira',
        email: 'regionalvaledoaco@feemg.com.br',
        sre: [
            { titulo: 'Almenara' },
            { titulo: 'Araçuaí' },
            { titulo: 'Caratinga' },
            { titulo: 'Guanhães' },
            { titulo: 'Manhuaçu' },
            { titulo: 'Coronel Fabriciano' },
            { titulo: 'Nova Era' },
            { titulo: 'Teófilo Otoni' },
            { titulo: 'Governador Valadares' },
        ]
    },
    {
        id: 4,
        titulo: 'Regional Triângulo',
        professor: 'Polidoro Camilo de Sousa',
        email: 'regionaltriangulo@feemg.com.br',
        sre: [
            { titulo: 'Ituiutaba' },
            { titulo: 'Monte Carmelo' },
            { titulo: 'Paracatu' },
            { titulo: 'Patrocínio' },
            { titulo: 'Patos de Minas' },
            { titulo: 'Uberaba' },
            { titulo: 'Uberlândia' },
            { titulo: 'Unaí' },
        ]
    },
    {
        id: 5,
        titulo: 'Regional Belo Horizonte',
        professor: 'Gustavo Lara Barbosa',
        email: 'regionalbh@feemg.com.br',
        sre: []
    },
    {
        id: 6,
        titulo: 'Regional Centro',
        professor: 'Pedro Emílio Drumond Moreira',
        email: 'regionalcentro@feemg.com.br',
        sre: [
            { titulo: 'Conselheiro Lafaiete' },
            { titulo: 'Divinópolis' },
            { titulo: 'Ouro Preto' },
            { titulo: 'Pará de Minas' },
            { titulo: 'Sete Lagoas' },
        ]
    },
    {
        id: 7,
        titulo: 'Regional Sul',
        professor: 'Márcio Aurélio Messias Franco',
        email: 'regionalsul@feemg.com.br',
        sre: [
            { titulo: 'Campo Belo' },
            { titulo: 'Caxambu' },
            { titulo: 'Itajubá' },
            { titulo: 'Passos' },
            { titulo: 'Poços de Caldas' },
            { titulo: 'Pouso Alegre' },
            { titulo: 'São Sebastião do Paraíso' },
            { titulo: 'Varginha' },
        ]
    },
    {
        id: 8,
        titulo: 'Regional Zona da Mata',
        professor: 'Adilson José Mattos',
        email: 'regionalzonadamata@feemg.com.br',
        sre: [
            { titulo: 'Barbacena' },
            { titulo: 'Carangola' },
            { titulo: 'Juiz de Fora' },
            { titulo: 'Leopoldina' },
            { titulo: 'Muriaé' },
            { titulo: 'Ponte Nova' },
            { titulo: 'São João del - Rei' },
            { titulo: 'Ubá' },
        ]
    },
]

export default regionais;
