import React, { useEffect, useState } from 'react'

import MetaTags from 'react-meta-tags'
import ReactHtmlParser from 'react-html-parser';

//components
import PageTitle from '../../../components/PageTitle';
import ListagemDocumentos from '../../../components/ListagemDocumentos';

//material-ui
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

// - api
import { governancaApi } from '../../../services/api';

const useStyles = makeStyles({
    wrapper: {
        padding: '30px 0'
    },
});

export default function TransparenciaDefault() {
    const classes = useStyles();
    const { id, title } = useParams()

    const [orgao, setOrgao] = useState([])
    const [pageCount, setPageCount] = useState(0)

    useEffect(() => {
        async function getOrgao() {
            await governancaApi.get('orgao', {
                params: {
                    id_menu: id,
                    flag_del: 0,
                    expand: 'orgaoAnexos'
                },
            }).then((response) => {
                setOrgao(response.data.items)
                setPageCount(response.data._meta.totalCount)
                // console.log(response.data.items)
            });
        }
        getOrgao()
    }, [id])


    if (pageCount > 0) {
        return (
            <>
                <MetaTags>
                    <title>{orgao[0].titulo} - Transparência - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="Transparência" pageTitle={orgao[0].titulo} />

                <Paper className={classes.wrapper} square>
                    {orgao[0].corpo !== "" || orgao[0].orgaoAnexos.length > 0 ?
                        <>
                            {orgao.map((item, key) => (
                                <>
                                    <Container maxWidth="lg" square style={{ marginBottom: '2rem' }}>
                                        {ReactHtmlParser(item.corpo)}
                                    </Container>
                                    <Container maxWidth="lg" square>
                                        {item.orgaoAnexos.length > 0 ?
                                            <>
                                                <Typography variant="h6" component="h4" color="primary">Anexos:</Typography>
                                                <ListagemDocumentos documentos={item.orgaoAnexos} />
                                            </>
                                            :
                                            <></>
                                        }
                                    </Container>
                                </>
                            ))}
                        </>
                        :
                        <Container maxWidth="lg" square>
                            <Alert severity="info">Não há registros.</Alert>
                        </Container>
                    }
                </Paper>
            </>
        )
    } else {
        return (
            <>
                <MetaTags>
                    <title>{title} - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle={title} />
                <Paper className={classes.wrapper} square>
                    <Container maxWidth="lg" square>
                        <Alert severity="info">Não há registros.</Alert>
                    </Container>
                </Paper>
            </>
        )
    }

}