import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2),
  },
  eventoInfo: {
    textAlign: "center",
    marginTop: 20,
    "& .MuiTypography-root": {
      lineHeight: "1.2em",
      fontFamily: '"Montserrat",sans-serif',
      textTransform: "uppercase",
    },
    "& .MuiTypography-subtitle2": {
      fontSize: "10px",
      fontWeight: "bold",
    },
    "& .MuiTypography-subtitle1": {
      marginBottom: ".2em",
      fontSize: ".9rem",
      fontWeight: 500,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  cardClubePartida: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiAvatar-root": {
      height: 60,
      width: 60,
    },
    "&.visitante": {
      "& .MuiAvatar-root": {
        marginLeft: "auto",
      },
    },
    "&.casa": {
      textAlign: "right",
      "& .MuiAvatar-root": {
        marginRight: "auto",
      },
    },
    "& .MuiTypography-root": {
      fontWeight: "bold",
      lineHeight: 1.2,
      fontSize: "1rem",
      fontFamily: '"Montserrat",sans-serif',
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiTypography-root": {
        fontSize: "1.2rem",
      },
      "& .MuiAvatar-root": {
        height: 100,
        width: 100,
      },
    },
  },
  cardPlacar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "& .MuiTypography-root": {
      padding: "0 8px",
      fontSize: "1.75rem",
      fontWeight: 700,
      fontFamily: '"Montserrat",sans-serif',
      color: "#66686b",
      position: "relative",
      "&.palacar-divider": {
        fontSize: "1.9em",
      },
      "&.winner": {
        color: "#31404b",
      },
      "&.placar-casa.winner:after": {
        content: "''",
        display: "block",
        width: 0,
        height: 0,
        position: "absolute",
        right: "auto",
        left: -10,
        top: "50%",
        transform: "translateY(-50%) scaleX(-1)",
        borderRight: "8px solid #e6202f",
        borderTop: "8px solid transparent",
        borderBottom: "8px solid transparent",
      },
      "&.placar-visitante.winner:after": {
        content: "''",
        display: "block",
        width: 0,
        height: 0,
        position: "absolute",
        right: -10,
        top: "50%",
        transform: "translateY(-50%)",
        borderRight: "8px solid #e6202f",
        borderTop: "8px solid transparent",
        borderBottom: "8px solid transparent",
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiTypography-root": {
        fontSize: "2.75rem",
      },
    },
  },
}));

export default useStyles;
