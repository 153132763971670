import axios from "axios";
import { ApiEsporte, esporteApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchEventoPartidaAtleta(props) {
  const { evento, idPartida, idEquipe, esporte } = props;
 
  const _api = ApiEsporte[evento.id_esporte] || esporteApiEsporte[esporte];

  const res = await axios.get(`${baseURL}/${_api}/api/evento-partida-atleta`, {
    params: {
      forcewhere: `id_partida:${idPartida}`,
      id_equipe: idEquipe,
      forcejoin: "atleta",
      expand: "scouts,atleta,estabelecimento,scout.id",
      pageSize: 30,
      flag_del: 0,
    },
  });
  const data = res.data.items;
  return data;
}
