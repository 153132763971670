import React, { useEffect, useRef, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { gestaoApi } from '../../../../services/api';

//material-ui
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { PillButtonSuccess } from '../../../../components/Styles';

import TableAlunos from '../Aluno/TableAlunos';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '40px',
        fontSize: '1rem'
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    formControl: {
        marginBottom: theme.spacing(3),
        minWidth: 200,
        maxWidth: 500
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'unset',
        '& a': {
            marginTop: 0
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
        },
    },
}));

export default function Alunos(params) {
    const classes = useStyles();
    const { escolaId, slugEscola, page, queryParam, t_page, t_queryParam } = params

    const [atletas, setAtletas] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState(queryParam);

    let history = useHistory();
    const sectionTop = useRef(null);
    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: sectionTop.current.offsetTop - 350 });

    useEffect(() => {
        if (escolaId) {
            async function getAtleta() {
                await gestaoApi.get('atleta', {
                    params: {
                        expand: 'atletaHistoricoAtual',
                        'atletaHistoricoAtual.id_estabelecimento': escolaId,
                        sort: 'nome_completo',
                        flag_del: 0,
                        nome_completo: queryParam,
                        page: page
                    },
                }).then((response) => {

                    setTotalPages(response.data._meta.pageCount)
                    setAtletas(response.data.items)
                });
            }
            getAtleta()
        }
    }, [escolaId, page, queryParam])

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch()
        }
    }

    const handleSearch = () => {
        if (searchTerm !== "") {
            history.push(`/feemg/escola/${escolaId}/${slugEscola}?q=${searchTerm}${t_queryParam ? `&t_q=${t_queryParam}` : ''}${t_page ? `&t_p=${t_page}` : ''}`);
        } else {
            history.push(`/feemg/escola/${escolaId}/${slugEscola}?${t_queryParam ? `t_q=${t_queryParam}&` : ''}${t_page ? `t_p=${t_page}` : ''}`);
        }
    }

    useEffect(() => {
        if (queryParam || page) {
            executeScroll();
        }
    }, [queryParam, page])

    return (
        <div style={{ padding: '0 0  20px 0' }}>
            <div ref={sectionTop}></div>
            <div className={classes.formControl}>
                <div className={classes.inputGroup}>
                    <TextField label="Pesquisar estudante-atleta" size="small" style={{ marginRight: '.4rem' }} fullWidth={true} value={searchTerm} onKeyDown={(e) => handleSearchKeyDown(e)} onChange={(e) => setSearchTerm(e.target.value)} variant="filled" />
                    <div>
                        <Link component={PillButtonSuccess} className={classes.searchButton} onClick={handleSearch} variant="contained" disableElevation>Pesquisar</Link>
                    </div>
                </div>
            </div>

            <TableAlunos atletas={atletas} escolaId={escolaId} slugEscola={slugEscola} />

            <div className={classes.paginationContainer}>
                <Route>
                    {({ location }) => {
                        const query = new URLSearchParams(location.search);
                        const page = parseInt(query.get('p') || '1', 10);
                        return (
                            <Pagination
                                page={page}
                                count={totalPages}
                                renderItem={(item) => {
                                    if (!queryParam) {
                                        return (
                                            <PaginationItem
                                                component={Link}
                                                to={`/feemg/escola/${escolaId}/${slugEscola}?${item.page === 1 ? '' : `p=${item.page}&`}${t_queryParam ? `t_q=${t_queryParam}&` : ''}${t_page ? `t_p=${t_page}` : ''}`}
                                                {...item}
                                            />
                                        )
                                    } else {
                                        return (
                                            <PaginationItem
                                                component={Link}
                                                to={`/feemg/escola/${escolaId}/${slugEscola}?q=${queryParam}${item.page === 1 ? '' : `&p=${item.page}`}${t_queryParam ? `&t_q=${t_queryParam}` : ''}${t_page ? `&t_p=${t_page}` : ''}`}
                                                {...item}
                                            />
                                        )
                                    }

                                }
                                }
                            />
                        );
                    }}
                </Route>
            </div>
        </div>
    )
}
