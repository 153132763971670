import React from 'react';

//components
import PageTitle from '../../../components/PageTitle';

//material ui
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//styles
import { Accordion, AccordionSummary, AccordionDetails } from '../../../components/Styles';

import ReactHtmlParser from 'react-html-parser';

import MetaTags from 'react-meta-tags'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#fff',
        padding: '40px 0'
    },
}));


function AccordionPage(params) {
    const classes = useStyles();
    const menuTitle = params.menuTitle;
    const pageTitle = params.pageTitle;
    const orgao = params.orgao;

    return (
        <>
            <MetaTags>
                <title>{pageTitle} - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle={menuTitle} pageTitle={pageTitle} />

            <div className={classes.root}>
                <Container maxWidth="lg">
                    {orgao.map((item, i) => (
                        <Accordion key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${i}-content`}
                                id={`panel${i}-header`}
                            >
                                <Typography className={classes.heading}>{item.titulo}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {ReactHtmlParser(item.corpo)}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Container>
            </div>
        </>
    )
}

export default AccordionPage;