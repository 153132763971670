import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 900,
        fontSize: '1.55rem',
        textTransform:'uppercase'
    },
}));

export default function OuvidoriaSection() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography className={classes.title} variant="h4" component="h4" color="primary">Sobre</Typography>
            <p>
                Este é um canal exclusivo de interlocução entre você e a Federação. Aqui você faz sugestões, reclamações, críticas, denúncias e elogios. A ouvidoria é um canal que deve ser acionado quando as reclamações não foram solucionadas pelos nossos departamentos ou pelos canais de atendimento da entidade.
            </p>
            <p>
                O objetivo é ampliar nosso comprometimento com a transparência e a melhoria da execução dos serviços prestados à sociedade, bem como o cumprimento da nossa missão institucional. Essa ferramenta independente, sigilosa e imparcial está disponível pra os públicos externo e interno.
            </p>
        </React.Fragment>
    )
}
