import React, { useEffect, useState } from 'react';
// - api
import { portalApi } from '../../services/api';

import { Link, Route, useHistory } from 'react-router-dom';

//material-ui
import DateFnsUtils from '@date-io/date-fns';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

//date
import { DatePicker } from "@material-ui/pickers";
import pt from 'date-fns/locale/pt';

//components
import { PillButtonSuccess } from '../../components/Styles';
import ListNoticias from './components/ListNoticias';
//util
import scrollToTopFunction from '../../util/scrollToTopFunction';


const useStyles = makeStyles((theme) => ({
  filtrosContainer: {
    marginTop: '20px',
    marginBottom:'20px',
    [theme.breakpoints.up('md')]: {
      margin: '20px 0px',
    }
  },
  cardFiltros: {
    padding: '20px',
    '& .MuiPickersToolbarText-toolbarBtnSelected': {
      textTransform: 'capitalize'
    },
    '& .MuiTypography-body1': {
      textTransform: 'capitalize'
    }
  },
  paginationContainer: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    }
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 100,
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'wrap',
      '& a': {
        marginTop: '1rem'
      }
    },
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'unset',
      '& a': {
        marginTop: 0
      }
    }
  },
  datePickerContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center'
    }
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
    paddingBottom: '4rem',
  },
  errorContainer: {
    paddingTop: '1rem',
    paddingBottom: '4rem',
  },
  filterTitle: {
    marginBottom: '1rem',
    fontWeight: 500,
    color: '#696969',
  },
  pageTitle: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    fontWeight: '900',
    paddingTop: '20px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '30px',
    }
  }
}));

function Noticias(params) {
  const classes = useStyles();
  const page = params.page;
  const queryParam = params.searchTerm;
  const [newsList, setNewsList] = useState([]);
  const [paginationPage, setPaginationPage] = useState(page);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState(queryParam);
  const [dateMin, setDateMin] = useState('')
  const [dateMax, setDateMax] = useState('')
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  let history = useHistory();
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (paginationPage) {
      setLoading(true);
      setError(false);

      let params = {}
      if (queryParam) {
        params = {
          flag_del: 0,
          flag_publicado: 1,
          arrayLike: `titulo:${queryParam},corpo:${queryParam}`,
          sort: '-created_at',
          betweenField: 'created_at',
          betweenValue1: dateMin,
          betweenValue2: dateMax,
          lessField: 'created_at',
          lessValue: (new Date()).getTime().toString().substr(0, 10),
          page: paginationPage
        }
      } else {
        params = {
          flag_del: 0,
          flag_publicado: 1,
          sort: '-created_at',
          betweenField: 'created_at',
          betweenValue1: dateMin,
          betweenValue2: dateMax,
          lessField: 'created_at',
          lessValue: (new Date()).getTime().toString().substr(0, 10),
          page: paginationPage
        }
      }

      async function getNews() {
        await portalApi.get('noticia', {
          params: params,
        }).then((response) => {
          setNewsList(response.data.items)
          setTotalPages(response.data._meta.pageCount)
          if (response.data._meta.totalCount < 1) {
            setError(true)

          }
          scrollToTopFunction();
          setLoading(false);
        });
      }
      getNews();
    }

  }, [paginationPage, queryParam, dateMin, dateMax])


  // const [tipoNoticia, setTipoNoticia] = React.useState(1);

  // const handleChangeTipo = (event) => {
  //   setTipoNoticia(event.target.value);
  // };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [errorData, setErrorData] = useState(false)

  const handleDateChange = (date) => {
    if (date) {
      if (date.toJSON()) {
        setSelectedDate(date);
        setDateMin(new Date(date.toJSON().slice(0, 10).concat("T00:00:00")).getTime().toString().slice(0, -3))
        setDateMax(new Date(date.toJSON().slice(0, 10).concat("T23:59:59")).getTime().toString().slice(0, -3))
        setErrorData(false)
      } else {
        console.log('erro')
        setErrorData(true)
      }

    } else {
      setSelectedDate(new Date().getTime());
      setDateMin("")
      setDateMax("")
      setErrorData(true)
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchTerm !== "") {
        history.push(`/noticias?q=${searchTerm}`);
      } else {
        history.push("/noticias");
      }
    }
  }



  return (
    <Container>
      <Grid container spacing={matchesMD ? 3 : 0} justify='space-between' className={classes.filtrosContainer}>

        <Grid item xs={12} md={4}>
          <Paper className={classes.cardFiltros} square>
            <Typography variant="h6" gutterBottom className={classes.filterTitle}>Filtrar notícias</Typography>
            {/* <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
              <InputLabel>Tipo:</InputLabel>
              <Select
                value={tipoNoticia}
                onChange={handleChangeTipo}
                label="Tipo"
              >
                <MenuItem value={1}>
                  Tudo
                </MenuItem>
                <MenuItem value={2}>Comunicado</MenuItem>
              </Select>
            </FormControl> */}
            <div className={classes.formControl}>
              <div className={classes.inputGroup}>
                <TextField label="Pesquisar" size="small" style={{ marginRight: '.4rem' }} fullWidth={true} value={searchTerm} onKeyDown={(e) => handleSearchKeyDown(e)} onChange={(e) => setSearchTerm(e.target.value)} variant="outlined" />
                <div>
                  {searchTerm ?
                    <Link component={PillButtonSuccess} className={classes.searchButton} to={`/noticias${searchTerm !== '' || searchTerm !== null ? `?q=${searchTerm}` : ''}`} variant="contained" disableElevation>Pesquisar</Link>
                    :
                    <Link component={PillButtonSuccess} className={classes.searchButton} to="/noticias" variant="contained" disableElevation>Pesquisar</Link>
                  }
                </div>
              </div>
            </div>
            <div className={classes.datePickerContainer}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt} >
                {matchesLG ?
                  <DatePicker
                    autoOk
                    variant="static"
                    openTo="date"
                    disableFuture={true}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  :
                  <>
                    <KeyboardDatePicker
                      margin="normal"
                      label="Pesquisar por data"
                      format="dd/MM/yyyy"
                      size="small"
                      value={selectedDate}
                      fullWidth={true}
                      error={errorData}
                      className={classes.formControl}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <FormHelperText error={errorData}>{errorData ? 'Data inválida' : ''}</FormHelperText>
                  </>
                }
              </MuiPickersUtilsProvider>
            </div>
            <Button href="/noticias">Limpar Filtros</Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper square>
            <Container>
              <Typography variant="h5" className={classes.pageTitle}>Notícias</Typography>
              {loading ?
                <div className={classes.spinnerContainer}>
                  <CircularProgress />
                </div>
                :
                <>
                  {error ?
                    <div className={classes.errorContainer}>
                      <Alert severity="error">A pesquisa não retornou resultados.</Alert>
                    </div>
                    :
                    <>
                      <ListNoticias newsList={newsList} />
                      <div className={classes.paginationContainer}>
                        <Route>
                          {({ location }) => {
                            const query = new URLSearchParams(location.search);
                            const page = parseInt(query.get('p') || '1', 10);
                            setPaginationPage(page);
                            return (
                              <Pagination
                                page={page}
                                count={totalPages}
                                renderItem={(item) => {
                                  if (queryParam === null) {
                                    return (
                                      <PaginationItem
                                        component={Link}
                                        to={`/noticias${item.page === 1 ? '' : `?p=${item.page}`}`}
                                        {...item}
                                      />
                                    )
                                  } else {
                                    return (
                                      <PaginationItem
                                        component={Link}
                                        to={`/noticias${item.page === 1 ? '' : `?p=${item.page}&q=${queryParam}`}`}
                                        {...item}
                                      />
                                    )
                                  }

                                }
                                }
                              />
                            );
                          }}
                        </Route>
                      </div>
                    </>
                  }
                </>

              }
            </Container>
          </Paper>
        </Grid>
      </Grid>

    </Container>
  )
}

export default Noticias;