import FormatParams from "../util/FormatParams"

export default async function fetchEventos(params) {
    const stringParams = FormatParams(params)
    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/gestao/api/evento?${stringParams}`
    )
    if (res.status !== 200) throw new Error('failed to fetch eventos')
    return res.json().then((res) => {
        return res.items
    })
}
