import { Card, CardActionArea, Typography } from '@material-ui/core'
import React from 'react'
import { organogramaSyles } from './styles'

function LabelRender({ label, pessoa, modalPessoas, handleClick, openDialog, ...props }) {
  const classes = organogramaSyles()

  if (modalPessoas) {
    return (
      <Card elevation={0} className={classes.nodeModal} {...props}>
        <CardActionArea onClick={() => openDialog(modalPessoas)}>
          <Typography>{label}</Typography>
        </CardActionArea>
      </Card>
    )
  }

  if (pessoa) {
    return (
      <Card className={classes.nodePessoa} {...props} elevation={0}>
        <CardActionArea onClick={(e) => handleClick(e, pessoa)}>
          <Typography>
            {pessoa.label ? pessoa.label :
              <>{pessoa.cargo}<br />
                {pessoa.nome}</>
            }
          </Typography>
        </CardActionArea>
      </Card>
    )
  }
  return (
    <Card className={classes.nodeDep} {...props} elevation={0}>
      <Typography>{label}</Typography>
    </Card>
  )
}



export default LabelRender