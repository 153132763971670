import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

function AtletasDestaque(props) {
  const classes = useStyles();
  const { artilheiros, id_esporte } = props;
  const tipoPonto = { 5: "Cestas", 6: "Pts" };

  return (
    <TableContainer className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className="thAtleta">Atleta</TableCell>
            <TableCell>{tipoPonto[id_esporte] || "Gols"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {artilheiros.map((item, key) => (
            <React.Fragment key={key}>
              <TableRow className="atletaTr">
                <TableCell>
                  <div className="atletasCard">
                    <Avatar
                      className={classes.fotoAtleta}
                      src={item.foto}
                      alt={item.nome_atleta_evento}
                    />
                    <div className="atletasInfo">
                      <h3 className="atletaNome">{item.nome_atleta_evento}</h3>
                      <h4 className="atletaClube">{item.sigla_clube}</h4>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="tdPontos">{item.soma}</TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AtletasDestaque;
