import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "40px",
    fontSize: "1rem",
  },
  fontBold: {
    fontWeight: 600,
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "2rem",
    paddingBottom: "4rem",
  },
  backLink: {
    textDecoration: "none",
    color: "inherit",
    fontWeight: 600,
    "&:active, &:hover ": {
      opacity: 0.6,
    },
  },
  tableTitle: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    textTransform:"uppercase",
    color: "inherit",
  },
  largeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(15),
    fontSize: theme.spacing(6),
  },
}));