import React, { useEffect, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
//components
import PageTitle from '../../../components/PageTitle';

import { PillButtonSuccess } from '../../../components/Styles';

//material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
//api
import { gestaoApi } from '../../../services/api';

//util
import { formataCEP, formataCNPJ, formataTelefone } from '../../../util/formatString';
import scrollToTopFunction from '../../../util/scrollToTopFunction';

import MetaTags from 'react-meta-tags';

import Capitalize from '../../../util/Capitalize';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '40px',
        paddingBottom: '40px',
    },
    formControl: {
        marginBottom: theme.spacing(3),
        maxWidth: 500
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        '& a': {
            marginTop: 0
        }
    },
    card: {
        fontSize: '.9rem',
        fontWeight: 500,
        height: '100%',
        '& .MuiCardContent-root': {
            height: '100%'
        },
        '& a': {
            textDecoration: 'none',
            '&:hover': {
                opacity: '0.85'
            }
        }
    },
    title: {
        fontSize: '1rem',
        fontWeight: 'bolder',
    },
    cardTextBold: {
        fontSize: '1rem',
        fontWeight: 'bolder'
    },
    errorContainer: {
        paddingTop: '1rem'
    },
}));


function FornecedoresCadastrados(params) {
    const classes = useStyles();
    const [orgao, setOrgao] = useState([])

    const page = params.page;
    const queryParam = params.searchTerm;
    const ramoQuery = params.ramoQuery;
    const [searchTerm, setSearchTerm] = useState(queryParam);
    // const [ramoID, setRamoID] = useState(ramoQuery);
    const [totalPages, setTotalPages] = useState(1);
    const [ramos, setRamos] = useState([])
    // const [idRamo, setIdRamo] = useState('');

    let history = useHistory();

    useEffect(() => {
        async function getFornecedoresCadastrados() {
            await gestaoApi.get('financeiro-fornecedor', {
                params: {
                    flag_fornecedor: 1,
                    id_estabelecimento: 26,
                    expand: 'ramos,ramo',
                    flag_del: 0,
                    pageSize: 21,
                    'ramos.id_ramo': ramoQuery,
                    razao_social: queryParam,
                    page: page
                },
            }).then((response) => {
                setOrgao(response.data.items);
                setTotalPages(response.data._meta.pageCount);
                scrollToTopFunction();
            });
        }
        getFornecedoresCadastrados()
    }, [page, queryParam, ramoQuery])

    useEffect(() => {
        async function getRamos() {
            await gestaoApi.get('financeiro-ramo', {
                params: {
                    sort: 'descricao',
                    flag_del: 0,
                    pageSize:200,
                },
            }).then((response) => {
                // console.log(response.data.items)
                setRamos(response.data.items)
            });
        }
        getRamos()
    }, [])

    const handleChangeRamo = (event) => {
        if (event.target.value) {
            history.push(`/fornecedores/fornecedores-cadastrados?ramo=${event.target.value}${queryParam ? `&q=${queryParam}` : ''}`);
        } else {
            history.push(`/fornecedores/fornecedores-cadastrados${queryParam ? `?q=${queryParam}` : ''}`);
        }

    };

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (searchTerm !== "") {
                history.push(`/fornecedores/fornecedores-cadastrados?q=${searchTerm}`);
            } else {
                history.push("/fornecedores/fornecedores-cadastrados");
            }
        }
    }



    return (
        <>
            <MetaTags>
                <title>Fornecedores Cadastrados - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="Fornecedores" pageTitle="Fornecedores Cadastrados" />
            <Paper square className={classes.root} >
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4}>
                            <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
                                <InputLabel>Ramo de atividade:</InputLabel>
                                <Select
                                    value={ramoQuery}
                                    onChange={handleChangeRamo}
                                    label="Ramo de atividade"
                                >
                                    <MenuItem value=""> Todos</MenuItem>
                                    {ramos.map((item, key) => {
                                        return (<MenuItem key={key} value={item.id}>{item.descricao}</MenuItem>)
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <div className={classes.formControl}>
                                <div className={classes.inputGroup}>
                                    <TextField label="Pesquisar" size="small" style={{ marginRight: '.4rem' }} fullWidth={true} value={searchTerm} onKeyDown={(e) => handleSearchKeyDown(e)} onChange={(e) => setSearchTerm(e.target.value)} variant="outlined" />
                                    <div>
                                        {searchTerm ?
                                            <Link component={PillButtonSuccess} className={classes.searchButton} to={`/fornecedores/fornecedores-cadastrados${searchTerm !== '' || searchTerm !== null ? `?q=${searchTerm}` : ''}`} variant="contained" disableElevation>Pesquisar</Link>
                                            :
                                            <Link component={PillButtonSuccess} className={classes.searchButton} to="/fornecedores/fornecedores-cadastrados" variant="contained" disableElevation>Pesquisar</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    {orgao.length > 0 ?
                        <Grid container spacing={3}>
                            {orgao.map((fornecedor, key) => (
                                <Grid item xs={12} sm={6} md={4} >
                                    <Card key={key} className={classes.card} square elevation={1} style={{border: '1px solid #dedede'}}>
                                        <CardContent>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                <div style={{ marginBottom: '.4rem' }}>
                                                    <Typography className={classes.title} color="primary" gutterBottom>
                                                        Razão Social: {fornecedor.razao_social}
                                                    </Typography>
                                                    <Typography color="textSecondary" className={classes.cardTextBold} variant="inherit" display="block" gutterBottom>
                                                        CNPJ: {formataCNPJ(fornecedor.cnpj)}
                                                    </Typography>
                                                    <Typography color="textSecondary" variant="inherit" display="block" gutterBottom>
                                                        Ramos:  {fornecedor.ramos.map((ramo) => ramo.ramo.descricao).join(', ')}
                                                    </Typography>
                                                </div>
                                                <div style={{ marginBottom: '.4rem' }}>
                                                    <Typography color="textSecondary" variant="inherit" display="block" >
                                                        Endereço: {fornecedor.tipo_logradouro}  {fornecedor.logradouro}, {fornecedor.numero}
                                                    </Typography>
                                                    <Typography color="textSecondary" variant="inherit" display="block" gutterBottom>
                                                        {fornecedor.bairro ? `${fornecedor.bairro}, ` : ''}{fornecedor.municipio ? Capitalize(fornecedor.municipio.descricao) : ''} - CEP: {formataCEP(fornecedor.cep)}
                                                    </Typography>
                                                </div>
                                                <div style={{ marginBottom: '.4rem' }}>
                                                    Contato:
                                                    <Typography color="textSecondary" variant="inherit" display="block" gutterBottom>
                                                        Tel: {formataTelefone(fornecedor.numero_telefone)}
                                                    </Typography>
                                                    
                                                    {fornecedor.site ?
                                                        <Typography color="textSecondary" variant="inherit" display="block" gutterBottom>
                                                            <a href={`//${fornecedor.site}`} target="_blank" rel="noreferrer">{fornecedor.site}</a>
                                                        </Typography>
                                                        :
                                                        <React.Fragment></React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <div style={{ padding: '20px 0', display: 'flex', justifyContent: 'center' }}>
                                    <Route>
                                        {({ location }) => {
                                            const query = new URLSearchParams(location.search);
                                            const page = parseInt(query.get('p') || '1', 10);
                                            return (
                                                <Pagination
                                                    page={page}
                                                    count={totalPages}
                                                    renderItem={(item) => {
                                                        if (!queryParam && !ramoQuery) {
                                                            return (
                                                                <PaginationItem
                                                                    component={Link}
                                                                    to={`/fornecedores/fornecedores-cadastrados${item.page === 1 ? '' : `?p=${item.page}`}`}
                                                                    {...item}
                                                                />
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <PaginationItem
                                                                    component={Link}
                                                                    to={`/fornecedores/fornecedores-cadastrados${ramoQuery ? `?ramo=${ramoQuery}&` : '?'}${queryParam ? `q=${queryParam}&` : ''}${item.page === 1 ? '' : `p=${item.page}`}`}
                                                                    {...item}
                                                                />
                                                            )
                                                        }
                                                    }
                                                    }
                                                />
                                            );
                                        }}
                                    </Route>
                                </div>
                            </Grid>
                        </Grid>
                        :
                        <div className={classes.errorContainer}>
                            <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
                        </div>
                    }
                </Container>
            </Paper>
        </>
    )
}

export default FornecedoresCadastrados;