import moment from 'moment'

export const dataAtual = Number(moment().format('YYYY'))
export const temporadasOptions = [
  {
    label: `${dataAtual}`,
    value: `${dataAtual}`,
  },
  {
    label: `${dataAtual - 1}`,
    value: `${dataAtual - 1}`,
  },
  {
    label: `${dataAtual - 2}`,
    value: `${dataAtual - 2}`,
  },
  {
    label: `${dataAtual - 3}`,
    value: `${dataAtual - 3}`,
  },
  {
    label: `${dataAtual - 4}`,
    value: `${dataAtual - 4}`,
  },
  {
    label: `${dataAtual - 5}`,
    value: `${dataAtual - 5}`,
  },
]

export const temporadasDesde = (firstDate) => {
  const first = Number(firstDate)
  const thisYear = moment().format('YYYY')
  const mapRange = Number(thisYear) - first

  const temporadas = Array.from(new Array(mapRange + 1)).map((item, key) => ({
    label: `${first + key}`,
    value: `${first + key}`,
  }))

  return temporadas.reverse()
}
