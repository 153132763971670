import { Divider } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    overflowX: 'hidden'
    // backgroundColor: '#f4f4f4',
  },
  Card: {
    // marginBottom: 15,
    height: '100%',
  },
  CardContent: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // textAlign: 'center',
    // alignItems: 'center',
    padding: 0,
    paddingBottom: '0 !important',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  body1: {
    fontSize: '.9rem',
    lineHeight: 1.2,
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, handleClose, ...other } = props
  console.log(`render title`)
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {handleClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function OrganogramaDialog({ open, handleClose, modalPessoas }) {
  const classes = useStyles()

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" handleClose={handleClose}>{modalPessoas.label}</DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <DialogContentText style={{ minWidth: 400 }} id="alert-dialog-description">
            {modalPessoas.pessoas ?
              <>
                {modalPessoas.pessoas.map((item, key) => (
                  <>
                    <div style={{ padding: 5 }}>
                      <Typography variant="subtitle1" color='textPrimary' style={{ lineHeight: 1.5 }}>{item.nome}</Typography>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {item.cargo ? <Typography variant="subtitle2" color="secondary" style={{ lineHeight: 1.2, fontWeight: 400, letterSpacing: '.02em' }}>{item.cargo}&nbsp;</Typography> : null}
                        {item.registro ? <Typography variant="subtitle2" color="secondary" style={{ lineHeight: 1.2, fontWeight: 400, letterSpacing: '.02em' }}>{item.cargo ? ' - ' : ''} {item.registro}</Typography> : null}
                      </div>
                    </div>
                    {modalPessoas.pessoas.length - 1 === key ? null : <Divider />}
                  </>
                ))}
              </> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
