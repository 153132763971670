import React from "react";
import TableClassificacao from "./TableClassificacao";
import { Card, CardContent, Typography } from "@material-ui/core";
import useStyles from "../style";
import { fetchEventoGrupo } from "../hooks/getEventoGrupo";
import { useQuery } from "@tanstack/react-query";
import { Alert } from "@material-ui/lab";

function Classificacao({ evento, esporte }) {
  const classes = useStyles();
  const { data, isLoading, isError } = useQuery(
    ["PartidasGrupo"],
    ({ pageParam = { evento } }) => fetchEventoGrupo(pageParam)
  );

  return (
    <Card>
      <CardContent style={{ paddingBottom: 0 }}>
        <Typography
          className={classes.heading}
          variant="h5"
          color="primary"
          gutterBottom
        >
          Classificação
        </Typography>
        {!isLoading && data.length === 0 ? (
          <Alert severity="error" style={{ marginTop: 20 }}>
            Nenhuma classificação encontrada
          </Alert>
        ) : null}
      </CardContent>
      {esporte ? (
        <TableClassificacao
          nomeEsporte={esporte}
          partidasGrupo={data}
          loading={isLoading}
          error={isError}
        />
      ) : null}
    </Card>
  );
}

export default Classificacao;
