import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import YouTubeApi from '../../../../services/youtube'


const useStyles = makeStyles((theme) => ({
    iframe: {
        width:"100%",
        height: "200px",
        [theme.breakpoints.up('md')]: {
            height:"300px",
        },
        [theme.breakpoints.up('lg')]: {
            height:"200px",
        }
    },
}));


export default function Youtube() {
    const classes = useStyles();
    const [youtubeVideos, setYoutubeVideos] = useState([])

    useEffect(() => {
        async function getYoutubeVideos() {
            await YouTubeApi.get('/playlistItems', {
                params: {
                    playlistId: process.env.REACT_APP_FEEMG_YOUTUBE_UPLOADS_ID,
                    maxResults: 12,
                }
            }).then((response) => {
                // console.log(response) 
                setYoutubeVideos(response.data.items)
            });
        }
        getYoutubeVideos();
    }, [])

    return (
        <Grid container spacing={2}>
            {youtubeVideos.map((item, i) => (
                <Grid item key={i} xs={12} sm={6} md={6} lg={4} style={{ paddingBottom: 3 }}>
                    <iframe title={`video-${i}`} className={classes.iframe} src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </Grid>
            ))}
        </Grid>
    )

}