import { withStyles } from '@material-ui/core/styles';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles((theme) => ({
    root: {
        border: 'none',
        borderRadius: '100px',
        boxShadow: 'none',
        minHeight: 40,
        '&:not(:last-child)': {
            borderRadius: '15px',
        },
        '&:not(:first-child)': {
            borderRadius: '15px',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: '1px solid transparent',
        borderRadius: '50px',
        marginBottom: 0,
        minHeight: 40,
        '& span': {
            color: theme.palette.primary.contrastText,
        },
        '& p': {
            fontWeight: 800,
            fontSize: '15px',
        },
        '&$expanded': {
            minHeight: 40,
            paddingBottom: 0
        },
        '& .MuiIconButton-root': {
            padding: 2
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        }
    },

    content: {
        justifyContent: 'center',
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {

    },
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        border: '1px solid #dedede',
        borderRadius: 10,
        boxShadow: '0px 0px 3px 0px #dddddd',
        marginTop: '-10px',
        paddingTop: '20px',
        textAlign: 'left',

    },

    content: {},
    expanded: {},
}))(MuiAccordionDetails);