import React, { useState, useEffect } from "react";
import Slider from "react-slick";

//css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselNav.css";

//images
// import feemg from '../../../assets/img/carousel/feemg.png';
// import competicoes from '../../../assets/img/carousel/competicoes.png';
// import arbitragem from '../../../assets/img/carousel/arbitragem.png';
// import arrows from '../../../assets/img/arrows.png';

import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { PillButtonPrimary } from '../../../components/Styles';
import { Link } from "react-router-dom";

// - api
import { portalApi } from '../../../services/api';

import { slugNoticia } from '../../../util/formatString';

const useStyles = makeStyles((theme) => ({
    carouselTextWrap: {
        fontSize: 15,
        textAlign: 'center',
        color: '#fff',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        fontFamily: 'Roboto, sans-serif',
        // background:'white',
        paddingBottom: 30,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 65,
            fontSize: 25,
            backgroundColor: 'rgba(0,0,0,0.2)',
        }
    },

    carouselTitle: {
        fontWeight: 600,
        fontSize: '0.9rem',
        lineHeight: '1.2',
        marginTop: 15,
        marginBottom: 15,
        // filter: 'drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.8))',
        fontFamily: 'Montserrat',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
        },
        [theme.breakpoints.up('md')]: {
            fontWeight: 900,
            fontSize: '1.6rem',
            marginTop: 0,
            textShadow: '1px 1px 5px rgba(0, 0, 0, 0.90)',
            filter: 'drop-shadow(0px 0px 0px rgba(0,0,0,0.8))',
        },
        [theme.breakpoints.up('lg')]: {
            fontWeight: 900,
            fontSize: '2rem',
        }
    },
    carouselImg: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'top',
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: 510,
        }
    },
    subtitle1: {
        fontSize: 'calc(1.1rem + .15vw)',
        lineHeight: '1.2',
        fontWeight: 600,
        textShadow: '1px 1px 5px rgba(0, 0, 0, 1)',
        filter: 'drop-shadow(0px 0px 0px rgba(0,0,0,0.8))',
        letterSpacing: '-.8px',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.3rem',
        }
    },
    carouselBG: {
        // background: 'rgb(0,107,218)',
        background: theme.palette.grey[800],
        [theme.breakpoints.up('md')]: {
            background: 'linear-gradient(0deg, rgba(218,37,28,0.65) 90%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%)',
            '& *': {
                outline: 'none',
            }
        }
    },
    containerStyle: {
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    btnContainer: {
        marginTop: '1rem',
        marginBottom: '1rem',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            marginTop: '2rem',
            marginBottom: 0,
        }
    },
    carouselLink: {
        color: 'white',
        textDecoration: 'none',
    }
}));


// function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{ ...style, display: "block", right: '15px', height: 40, width: 40, backgroundPositionX: 'left', backgroundImage: `url(${arrows})` }}
//             onClick={onClick}
//         />
//     );
// }

// function SamplePrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{ ...style, display: "block", left: '15px', zIndex: 15, height: 40, width: 40, backgroundPositionX: 'right', backgroundImage: `url(${arrows})` }}
//             onClick={onClick}
//         />
//     );
// }

export default function CarouselNav() {
    const classes = useStyles();

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        appendDots: dots => (
            <div style={{ margin: 0, padding: 0, bottom: 5 }}>
                <ul className="dots-wrap"> {dots} </ul>
            </div>
        ),
        customPaging: () => (
            <div className="slick-dots-item"></div>
        ),
        responsive: [
            {
                breakpoint: 960,
            },
        ]
    };


    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        async function getNews() {
            await portalApi.get('noticia', {
                params: {
                    flag_del: 0,
                    flag_destaque: 1,
                    flag_publicado: 1,
                    id_estabelecimento: 26,
                    sort: '-created_at',
                    lessField: 'created_at',
                    lessValue: (new Date()).getTime().toString().substr(0, 10),
                    pageSize: 3
                },
            }).then((response) => {
                // console.log(response.data.items)
                setNewsList(response.data.items)
            });
        }
        getNews()
    }, [])


    return (
        <div className={classes.carouselBG}>
            <Container className={classes.containerStyle}>
                <Slider {...settings}>
                    {newsList.map((item, key) => (
                        <div key={key}>
                            <div style={{ backgroundImage: `url(https://sgefeemg.bigmidia.com/_uploads/26/noticias/${item.foto_capa}?time=${(new Date()).getTime()})` }} className={classes.carouselImg}>

                                <div className={classes.carouselTextWrap}>
                                    <Hidden mdUp>
                                        <img src={`https://sgefeemg.bigmidia.com/_uploads/26/noticias/${item.foto_capa}?time=${(new Date()).getTime()}`} alt={item.titulo} />
                                    </Hidden>
                                    <Container maxWidth="md">
                                        <Link to={`/noticias/leitura/${item.id}/${slugNoticia(item.titulo)}`} className={classes.carouselLink} >
                                            <Typography variant="h1" className={classes.carouselTitle} gutterBottom>{item.titulo}</Typography>
                                        </Link>
                                        <Hidden smDown>
                                            <Typography variant="subtitle1" className={classes.subtitle1} component="p" align="center" gutterBottom>
                                                {item.resumo}
                                            </Typography>
                                        </Hidden>

                                        <div className={classes.btnContainer}>
                                            <PillButtonPrimary component={Link} to={`/noticias/leitura/${item.id}/${slugNoticia(item.titulo)}`} variant="contained" color="primary" disableElevation>leia mais</PillButtonPrimary>
                                        </div>
                                    </Container>
                                </div>

                            </div>
                        </div>
                    ))}
                </Slider>
            </Container>
        </div>
    );

}
