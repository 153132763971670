import React, { useState, useEffect } from 'react'
import Flickr from 'flickr-sdk'

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    flickrImgContainer: {
        position: 'relative',
        overflow: 'hidden',
        '& img': {
            width: '100%'
        },
        '& .photosetOverlay': {
            position: 'absolute',
            top: 0,
            left: 0,
            color: 'white',
            height: 'calc(100% - 5px)',
            backgroundColor: 'rgba(0,0,0,0.6)',
            transform: 'translateY(100%)',
            opacity: 0,
            transition: 'all 300ms ease-in-out',
        },
        '&:hover': {
            '& .photosetOverlay': {
                opacity: 1,
                transform: 'translateY(0)'
            }
        },
    },
    photosetOverlayContent: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%'
    },
    photosetName: {
        fontSize: '1rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 3,
        lineHeight: '1.5rem',
        maxHeight: '4.2rem',
        display: '-webkit-box',
        boxOrient: 'vertical',
        padding: '0 10px',
        fontWeight: 500
    }

}));


export default function FlickrAlbums() {
    const classes = useStyles();
    const [flickrPhotosets, setFlickrPhotosets] = useState([]);

    useEffect(() => {
        var flickr = new Flickr(process.env.REACT_APP_FLICKR_KEY);
        flickr.photosets.getList({
            user_id: process.env.REACT_APP_FLICKR_FEEMG_UID,
            per_page: 6
        }).then(function (res) {
            setFlickrPhotosets(res.body.photosets.photoset)
        }).catch(function (err) {
            console.error(err);
        });
    }, [])

    if (flickrPhotosets) {
        return (
            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                {flickrPhotosets.map((item, key) => (
                    <Grid item key={key} xs={12} sm={6} md={4} lg={6}>
                        <a href={`https://www.flickr.com/photos/feemg/albums/${item.id}`} target="_blank" rel="noreferrer">
                            <div className={classes.flickrImgContainer}>
                                <img src={`https://farm${item.farm}.staticflickr.com/${item.server}/${item.primary}_${item.secret}.jpg`} alt={item.description._content} />
                                <div className="photosetOverlay">
                                    <div className={classes.photosetOverlayContent}>
                                        <p className={classes.photosetName}>{item.description._content}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </Grid>
                ))}
            </Grid>
        )
    } else {
        return (<React.Fragment></React.Fragment>)
    }


}