import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link, useParams } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

// import logo from '../../assets/img/logo.png'
// import noticia670 from '../../../assets/img/noticias/670.png';

// - api
import { portalApi } from '../../../services/api';

//material-ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
// import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';

//components
import PageTitle from '../../../components/PageTitle';
import CarouselNoticias from '../../Home/sections/CarouselNoticias';
import SocialLinks from './social-links';

// import Capitalize from '../../../util/Capitalize';

import MetaTags from 'react-meta-tags';

import { DiscussionEmbed } from 'disqus-react';


const useStyles = makeStyles((theme) => ({
    newsTitle: {
        marginTop: 10,
        fontWeight: 500,
        fontSize: '1.5rem',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            marginTop: 30,
            fontSize: '2.5rem',
        }
    },
    newsSubTitle: {
        textAlign: 'center',
        display: 'block',
        color: 'gray',
        fontSize: '1.1rem',
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.3rem',
        }
    },
    imagemNoticia: {
        display: 'block',
        height: 'auto',
        width: '100%',
        objectFit: 'cover',
        maxHeight: 510,
        maxWidth: '100%',
        transition: 'all 250ms ease-in',
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer',
        }
    },
    backLink: {
        textDecoration: 'none',
        color: 'inherit',
        fontWeight: 600,
        '&:active, &:hover ': {
            opacity: 0.6
        }
    },
    noticiaSection: {
        marginBottom: '1rem',
    },
    noticiaContent: {
        fontSize: '1rem',
        margin: '1rem 0 2rem 0',
        textAlign:'justify'
    },
    newsInfo: {
        fontSize: '.95rem',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'inherit',
        color: '#717171',
        '& span': {
            marginRight: '15px'
        },
        '& svg': {
            marginRight: 5
        }
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    shareLinksContainer: {
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        }
    },
    newsInfoContainer: {
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse'
        }
    }
}));

function LeituraNoticia() {
    const classes = useStyles();
    const { id, title } = useParams();
    const [noticia, setNoticia] = useState({});
    // const [imgDestaqueHeight, setImgDestaqueHeight] = useState('auto')
    const [loading, setLoading] = useState(true);
    const pageUrl = `http://feemg.bigmidia.com/noticias/leitura/${id}/${title}`

    useEffect(() => {
        if (id) {
            async function getNoticia() {
                await portalApi.get('noticia', {
                    params: {
                        id: id,
                        expand: 'user,idPessoa',
                    },
                }).then((response) => {
                    // console.log(response.data.items[0])
                    setNoticia(response.data.items[0]);
                    setLoading(false)
                });
            }

            getNoticia();
        }
    }, [id])

    // const imgFallback = (e) => {
    //     e.target.src = noticia670
    // }

    const localDate = (created_at) => {
        var date = new Date(created_at * 1000)
        var time = date.toTimeString().substr(0, 5)
        var formatedDate = date.toLocaleDateString()
        return (formatedDate + ' ' + time)
    }

    return (
        <>
            <PageTitle menuTitle="FEEMG" pageTitle="Notícias" />

            {loading ?
                <div className={classes.spinnerContainer}>
                    <CircularProgress />
                </div>
                :
                <>
                    <MetaTags>
                        <title>{noticia.titulo} - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                        <meta name="description" content={noticia.resumo}></meta>
                        <meta property="og:description" content={noticia.resumo}></meta>
                        <meta property="og:image" content={`https://sgefeemg.bigmidia.com/_uploads/26/noticias/${noticia.foto_capa}?time=${(new Date()).getTime()}`}></meta>
                        <link rel="canonical" href={window.location.href} />
                    </MetaTags>
                    <Paper square>
                        <Container maxWidth="lg">

                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" className={classes.newsTitle} gutterBottom>{noticia.titulo}</Typography>
                                    <Typography variant="subtitle" className={classes.newsSubTitle}>{noticia.resumo}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleReactLightbox>
                                        <SRLWrapper>
                                            <img className={classes.imagemNoticia} src={`https://sgefeemg.bigmidia.com/_uploads/26/noticias/${noticia.foto_capa}?time=${(new Date()).getTime()}`} alt={noticia.titulo} />
                                        </SRLWrapper>
                                    </SimpleReactLightbox>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Grid container>
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <div style={{ marginBottom: 5 }}>
                                                <i>{noticia.legenda_foto_corpo}</i>
                                            </div>
                                            <small>{noticia.fonte_foto_capa ? `fonte: ${noticia.fonte_foto_capa}` : ""}</small>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: 0 }}>
                                    <div className={classes.noticiaSection}>
                                        <Grid container spacing={0} className={classes.newsInfoContainer}>
                                            <Grid item xs={12} md={6}>
                                                <div className={classes.shareLinksContainer} style={{ marginLeft: '-10px' }}>
                                                    <SocialLinks shareUrl={pageUrl} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <div className={classes.newsInfo}>
                                                    <Grid container spacing={2}>
                                                        <Grid item style={{ display: 'flex' }}>
                                                            <TodayOutlinedIcon fontSize="small" />
                                                            <span>{localDate(noticia.created_at)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className={classes.noticiaContent}>
                                            {ReactHtmlParser(noticia.corpo)}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <hr />
                                    <div style={{ textAlign: 'right', marginBottom: '2rem' }}>
                                        <Link to="/noticias" className={classes.backLink}>« Voltar</Link>
                                    </div>
                                </Grid>
                            </Grid>

                            {pageUrl && noticia.id ?
                                <DiscussionEmbed
                                    style={{ paddingBottom: '50px' }}
                                    shortname='feemg'
                                    config={
                                        {
                                            url: pageUrl,
                                            identifier: noticia.id,
                                            title: noticia.titulo,
                                            language: 'pt_BR'
                                        }
                                    }
                                />
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </Container>
                    </Paper>
                    <CarouselNoticias />
                </>
            }

        </>
    )
}

export default LeituraNoticia;
