import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { gestaoApi } from '../../../services/api';

//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';

//components
import PageTitle from '../../../components/PageTitle';
import Capitalize from '../../../util/Capitalize';
import TablePessoa from '../../../components/TablePessoa';

import MetaTags from 'react-meta-tags'
import HistoricoArbitro from '../Arbitro/historico-arbitro';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '40px',
        fontSize: '1rem'
    },
    fontBold: {
        fontWeight: 600
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    backLink: {
        textDecoration: 'none',
        color: 'inherit',
        fontWeight: 600,
        '&:active, &:hover ': {
            opacity: 0.6
        }
    },
    largeAvatar: {
        width: theme.spacing(12),
        height: theme.spacing(15),
        fontSize: theme.spacing(6)
    }


}));

export default function Representante() {
    const classes = useStyles();
    const { pessoaId } = useParams()
    const [representante, setRepresentante] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pessoaId) {
            async function getRepresentante() {
                await gestaoApi.get('pessoa', {
                    params: {
                        id: pessoaId,
                    },
                }).then((response) => {
                    setRepresentante(response.data.items[0])
                    setLoading(false)
                });
            }
            getRepresentante()
        }
    }, [pessoaId])

    const [representantes, setRepresentantes] = useState([])

    useEffect(() => {
        async function getRepresentantes() {
            await gestaoApi.get('pessoa', {
                params: {
                    id_tipo: 31,
                    id_estabelecimento: 26,
                    sort: '-created_at'
                },
            }).then((response) => {
                setRepresentantes(response.data.items)
                setLoading(false)
            });
        }
        getRepresentantes()
    }, [])


    if (loading) {
        return (
            <>
                <MetaTags>
                    <title>Representante - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="FEEMG" pageTitle="Representante" />
                <Paper square elevation={0} className={classes.root}>
                    <div className={classes.spinnerContainer}>
                        <CircularProgress />
                    </div>
                </Paper>
            </>
        )
    } else {
        return (
            <div>
                <MetaTags>
                    <title>{representante.nome_completo ? Capitalize(representante.nome_completo) : ''} - Representante - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="FEEMG" pageTitle={representante.nome_completo ? Capitalize(representante.nome_completo) : ''} />
                <Paper square elevation={0} className={classes.root}>
                    <Container maxWidth="lg">
                        <div style={{ textAlign: 'right' }}>
                            <Link to="/arbitragem/representantes-cadastrados" className={classes.backLink}>« Voltar</Link>
                        </div>
                        <Grid container spacing={2} >
                            <Grid item >
                                <Avatar variant="rounded" src={`https://sgefeemg.bigmidia.com/_uploads/fotoPessoa/${representante.foto}`} alt={representante.nome_completo} className={classes.largeAvatar} />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Typography variant="h4" component="h1" color="primary" className={classes.fontBold} gutterBottom>
                                    {representante.nome_completo ? Capitalize(representante.nome_completo) : ''}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.fontBold} gutterBottom>
                                    Registro: {representante.registro}
                                </Typography>
                            </Grid>
                        </Grid>
                        <HistoricoArbitro pessoaId={pessoaId} />
                        <div style={{ padding: '40px 0' }}>
                            <TablePessoa pessoa={representantes} tipo="representante" hasModalidade={false} />
                        </div>
                    </Container>
                </Paper>
            </div>
        )
    }
}
