import axios from "axios";
import { esporteApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchEventoPartidaScout(props) {
  const { esporte, idPartida } = props;
  const _api = esporteApiEsporte[esporte]

  const res = await axios.get(`${baseURL}/${_api}/api/evento-partida-scout`, {
    params: {
      id_partida: idPartida,
      expand: "atleta",
      sort: "-data",
      pageSize: 200,
    },
  });
  const data = res.data.items;
  return data;
}
