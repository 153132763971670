import React from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";

//components
import PageTitle from "../../components/PageTitle";
import {
  Box,
  Container,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import useStyles from "./style";
import useEvento from "./hooks/Evento";
import Classificacao from "./components/Classificacao";
import Partidas from "./components/Partidas";
import EventoInfo from "./components/EventoInfo";
import Atrilheiros from "./components/Atrilheiros";

export default function EventoPage() {
  const classes = useStyles();
  const theme = useTheme();
  const { esporte, id } = useParams();
  const { evento, loading } = useEvento({ id });
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <MetaTags>
        <title>
          {evento.descricao ? `${evento.descricao} - ` : null} FEEMG - Federação
          de Esportes Estudantis de Minas Gerais
        </title>
      </MetaTags>
      <PageTitle menuTitle="FEEMG" pageTitle={evento.descricao} />

      <Paper square className={classes.wrapper}>
        <Container maxWidth="lg">
          {loading ? (
            <Box style={{ height: "40vh" }} />
          ) : (
            <Grid
              container
              spacing={3}
              direction={!matchesMD ? "column-reverse" : "row"}
            >
              {evento.id_esporte === 1 ? null : (
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Classificacao evento={evento} esporte={esporte} />
                    </Grid>
                    {evento.idEsporte.flag_coletivo ? (
                      <Grid item xs={12}>
                        <Partidas evento={evento} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={evento.id_esporte === 1 ? 12 : 4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <EventoInfo evento={evento} />
                  </Grid>
                  {evento.id_esporte === 1 ||
                  !evento.idEsporte.flag_coletivo ? null : (
                    <Grid item xs={12}>
                      <Atrilheiros evento={evento} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </Paper>
    </>
  );
}
