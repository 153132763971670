import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import useStyles from "../style";

function TableClassificacaoChave({
  eventoClassificacaos,
  esporteEspecificacoes,
}) {
  const classes = useStyles();

  return (
    <Table className={classes.tabelaClassificacao}>
      <TableHead>
        <TableRow>
          <TableCell styele={{ width: "60%" }}>Equipe</TableCell>
          <TableCell align="center">J</TableCell>
          <TableCell align="center">P</TableCell>
          <TableCell align="center">V</TableCell>
          <TableCell align="center">D</TableCell>
          <TableCell align="center">E</TableCell>
          <TableCell align="center">
            {esporteEspecificacoes?.pontuacaoAbrev ?? "G"}P
          </TableCell>
          <TableCell align="center">
            {esporteEspecificacoes?.pontuacaoAbrev ?? "G"}C
          </TableCell>
          <TableCell align="center">
            {esporteEspecificacoes?.pontuacaoAbrev ?? "G"}AV
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {eventoClassificacaos &&
          eventoClassificacaos.length > 0 &&
          _.orderBy(eventoClassificacaos, ["num_classificacao"], ["asc"]).map(
            (item, key) => (
              <TableRow key={key}>
                <TableCell style={{ width: "60%" }}>
                  <div className={classes.columnTime}>
                    <div>
                      <Avatar
                        className={classes.avatarEscola}
                        variant="square"
                        src={item.idEstabelecimento.urlLogo}
                        alt={item.idEstabelecimento.descricao}
                      />
                    </div>
                    <div>
                      <Link
                        className="link-escola"
                        to={`/feemg/escola/${item.idEstabelecimento.id
                          }/${createSlug(item.idEstabelecimento.descricao)}`}
                      >
                        {item.idEstabelecimento.sigla}
                      </Link>
                      <span className="team-meta__place">
                        {item.idEstabelecimento.uf}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell>{item.num_jogos}</TableCell>
                <TableCell>{item.num_pontos}</TableCell>
                <TableCell>{item.num_vitorias}</TableCell>
                <TableCell>{item.num_derrotas}</TableCell>
                <TableCell>{item.num_empates}</TableCell>
                <TableCell>{item.num_ga}</TableCell>
                <TableCell>{item.num_gc}</TableCell>
                <TableCell>
                  {item.num_ga > 0 && item.num_gc > 0 ?
                    (Math.round((item.num_ga / item.num_gc) * 100) / 100) : '-'}
                </TableCell>
              </TableRow>
            )
          )}
      </TableBody>
    </Table>
  );
}

export default TableClassificacaoChave;
