import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Chip, TableCell, TableRow, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
 import moment from "moment";
import { fetchPartidaArbitro } from "../../../Eventos/hooks/getArbitragem";
import { createSlug } from "../../../../util/createSlug";

function TabelaArbitroFutsal({ pessoaId }) {
  const classes = useStyles();

  const { data, isSuccess } = useQuery(
    [`EventoArbitroPartida-${pessoaId}-futsal`],
    ({
      pageParam = {
        apiEsporte: "cbfutsal",
        params: {
          id_pessoa: `${pessoaId}`,
          expand: "idEvento,funcao,partida",
          sort: "-created_at",
          flag_del: 0,
          'idEvento.flag_del': 0,
          forcewhere:"idEvento.flag_del:0",
          forcejoin:"partida.idEvento"
        },
      },
    }) => fetchPartidaArbitro(pageParam)
  );

  if (isSuccess) {
    return (
      <React.Fragment>
        {data.map((item, key) => (
          <TableRow key={key}>
            <TableCell>
              <Link
                className={classes.link}
                to={`/feemg/futsal/${item.partida.id_evento}/${createSlug(item.partida?.idEvento?.descricao) || "partida"
                  }/partida/${item.id_partida}`}
              >
                <Typography>
                  {item.partida?.idEvento?.descricao || "Visualizar partida"}
                </Typography>
              </Link>
            </TableCell>
            <TableCell>
              {item.funcao?.descricao}
            </TableCell>
            <TableCell>
              <Chip label="FUTSAL" className={classes.futsal} />
            </TableCell>
            <TableCell>
              {moment(item.partida.data).format("DD/MM/YYYY")}
            </TableCell>
            <TableCell>
              {item.partida.local}
            </TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}

export default TabelaArbitroFutsal;
