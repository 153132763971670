import moment from "moment";
import React from "react";
import { fetchEventoPartidaPorId } from "../../../hooks/getEventoPartida";
import { useQuery } from "@tanstack/react-query";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";
import { Skeleton } from "@material-ui/lab";
import { fetchEventoPartidaWOScout } from "../../../hooks/getEventoPartidaWOScout";

function LoadingHeader() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid item xs={12} sm={4} md={5}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="circle"
              style={{ height: 50, width: 50, marginRight: 10 }}
            />
            <Skeleton height={40} variant="text" style={{ width: "100%" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Skeleton height={30} variant="text" style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Skeleton
              height={40}
              variant="text"
              style={{ width: "100%", marginRight: 10 }}
            />
            <Skeleton variant="circle" style={{ height: 50, width: 50 }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function HeaderPartida({ evento, idPartida, loading, esporte }) {
  const classes = useStyles();
  const { data: partidaScoutWO } = useQuery({
    queryKey: ["eventoPartidaScoutWO", { esporte, idPartida }],
    queryFn: ({ pageParam = { esporte, idPartida } }) =>
      fetchEventoPartidaWOScout(pageParam),
  });

  const { data, isSuccess, isLoading } = useQuery(
    ["EventoPartidaPorId"],
    ({ pageParam = { idPartida, esporte} }) =>
      fetchEventoPartidaPorId(pageParam)
  );

  // define qual dos times venceu por wo
  const handleWO = React.useMemo(() => {
    if (data && partidaScoutWO && partidaScoutWO.length) {
      if (Number(data.id_equipe_casa) === Number(partidaScoutWO[0].id_equipe)) {
        return {
          casa: false,
          visitante: true,
          message: `${data.visitante.descricao} - VENCEU POR WO`,
        };
      }
      return {
        casa: true,
        visitante: false,
        message: `${data.casa.descricao} - VENCEU POR WO`,
      };
    }
    return { casa: false, visitante: false, message: "" };
  }, [data, partidaScoutWO]);

  if (isLoading || loading) {
    return <LoadingHeader />;
  }

  if (isSuccess && data) {
    const isCasaVencedor = data.placar_final_casa > data.placar_final_visitante;
    return (
      <Box className={classes.root}>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} sm={4} md={5}>
            <Box className={clsx(classes.cardClubePartida, "casa")}>
              <Avatar
                variant="square"
                src={data.casa?.urlLogo}
                alt={data.casa?.descricao}
              />
              <Typography>{data.casa?.descricao}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Box className={classes.cardPlacar}>
              <Typography
                className={`placar-casa ${isCasaVencedor ? "winner" : ""}`}
              >
                {data.placar_final_casa}
              </Typography>
              <Typography className="palacar-divider"> X </Typography>
              <Typography
                className={`placar-visitante ${!isCasaVencedor ? "winner" : ""
                  }`}
              >
                {data.placar_final_visitante}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <Box className={clsx(classes.cardClubePartida, "visitante")}>
              <Typography>{data.visitante?.descricao}</Typography>
              <Avatar
                variant="square"
                src={data.visitante?.urlLogo}
                alt={data.visitante?.descricao}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.eventoInfo}>
              {handleWO.message ? (
                <Typography
                  color="primary"
                  variant="subtitle1"
                  style={{ fontWeight: "bold", marginBottom: "1em" }}
                >
                  {handleWO.message}
                </Typography>
              ) : null}
              <Typography variant="subtitle1">
                {moment(data.data).format("DD/MM/YYYY - HH:mm")}
              </Typography>
              <Typography variant="subtitle2">Local: {data.local}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
  return <LoadingHeader />;
}

export default HeaderPartida;
