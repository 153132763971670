import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

import { fade, withStyles } from "@material-ui/core/styles";

export const PillButtonPrimary = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    fontSize: "13px",
    padding: "8px 16px",
    borderRadius: "30px",
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      outline: "none",
      textDecoration: "none",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
    },
  },
}))(Button);

export const PillButtonInherit = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    fontSize: "13px",
    padding: "8px 16px",
    borderRadius: "30px",
    textDecoration: "none",
    // color: "theme.palette.primary.contrastText",
    // backgroundColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      // color: theme.palette.primary.main,
      // backgroundColor: "theme.palette.primary.contrastText",
      outline: "none",
      textDecoration: "none",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
    },
  },
}))(Button);

export const PillButtonSuccess = withStyles((theme) => ({
  root: {
    fontSize: "13px",
    padding: "8px 16px",
    borderRadius: "30px",
    color: "white",
    backgroundColor: theme.palette.success.main,
    "&:hover, &:focus": {
      color: "white",
      backgroundColor: theme.palette.success.dark,
      outline: "none",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
    },
  },
}))(Button);

export const PillButtonPrimaryInverse = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    fontSize: "13px",
    padding: "8px 16px",
    borderRadius: "30px",
    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      outline: "none",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
    },
  },
}))(Button);

export const PillButtonPrimaryOutlined = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    fontSize: "13px",
    padding: "8px 16px",
    borderRadius: "30px",
    border: `2px solid ${theme.palette.primary.main}`,
    "&:hover, &:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
      border: `2px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
    },
  },
}))(Button);

export const SquareButton = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: "13px",
    padding: "8px 16px",
    borderRadius: 0,
    "&:hover, &:focus": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      outline: "none",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
    },
  },
}))(Button);

export const Accordion = withStyles({
  root: {
    marginTop: "20px !important",
    border: "1px solid transparent",
    borderRadius: "15px",
    boxShadow: "2px 2px 20px rgba(0,0,0,.1)",
    transition: "all .3s",
    "&:not(:last-child)": {
      borderRadius: "15px",
    },
    "&:not(:first-child)": {
      borderRadius: "15px",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      boxShadow: "2px 2px 35px rgba(0,0,0,.3)",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    padding: "10px 25px",
    border: "1px solid transparent",
    borderRadius: "15px",
    marginBottom: 0,
    minHeight: 56,
    transition: "padding-bottom .2s ease-out",
    "& span": {
      color: theme.palette.primary.main,
    },
    "& p": {
      fontWeight: 500,
      fontSize: "1rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "20px",
      },
    },
    "& svg": {
      fontSize: "1.5rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "2.3rem",
      },
    },
    "&$expanded": {
      minHeight: 56,
      paddingBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: "20px 45px",
    },
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "0 20px 20px",
    [theme.breakpoints.up("md")]: {
      padding: "0 45px 25px",
    },
  },
}))(MuiAccordionDetails);

export const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.success.main,
    "&$checked": {
      color: theme.palette.success.main,
    },
  },
}))((props) => <Checkbox color="default" {...props} />);
