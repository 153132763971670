import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { gestaoApi } from "../../../../services/api";
import PageTitle from "../../../../components/PageTitle";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import TableTecnico from "./TableTecnico";
import Capitalize from "../../../../util/Capitalize";
import HistoricoPessoa from "./historico-pessoa";
import { useStyles } from "./styles";

export default function Tecnico() {
  const classes = useStyles();
  const { escolaId, slugEscola, pessoaId } = useParams();
  const [tecnico, setTecnico] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (pessoaId) {
      async function getTecnico() {
        await gestaoApi
          .get("pessoa", {
            params: {
              id: pessoaId,
            },
          })
          .then((response) => {
            setTecnico(response.data.items[0]);
            setLoading(false);
          });
      }
      getTecnico();
    }
  }, [pessoaId]);

  const [estabelecimento, setEstabelecimento] = useState({ descricao: "" });

  useEffect(() => {
    if (escolaId) {
      async function getEstabelecimento() {
        await gestaoApi
          .get("estabelecimento", {
            params: {
              id: escolaId,
              flg_ativo: 1,
              flag_del: 0,
            },
          })
          .then((response) => {
            setEstabelecimento(response.data.items[0]);
          });
      }
      getEstabelecimento();
    }
  }, [escolaId]);

  const [tecnicos, setTecnicos] = useState([]);

  useEffect(() => {
    if (escolaId) {
      async function getTecnicos() {
        await gestaoApi
          .get("pessoa", {
            params: {
              id_estabelecimento: escolaId,
              expand: "modalidades",
              id_tipo: 30,
              flag_del: 0,
              id_departamento: 1,
            },
          })
          .then((response) => {
            setTecnicos(response.data.items);
            setLoading(false);
          });
      }
      getTecnicos();
    }
  }, [escolaId]);

  if (loading) {
    return (
      <>
        <PageTitle menuTitle="FEEMG" pageTitle="Tecnico" />
        <Paper square elevation={0} className={classes.root}>
          <div className={classes.spinnerContainer}>
            <CircularProgress />
          </div>
        </Paper>
      </>
    );
  } else {
    return (
      <div>
        <PageTitle
          menuTitle="FEEMG"
          pageTitle={
            estabelecimento.descricao
              ? Capitalize(estabelecimento.descricao)
              : ""
          }
        />
        <Paper square elevation={0} className={classes.root}>
          <Container maxWidth="lg">
            <div style={{ textAlign: "right" }}>
              <Link
                to={`/feemg/escola/${escolaId}/${slugEscola}`}
                className={classes.backLink}
              >
                « Voltar
              </Link>
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar
                  variant="rounded"
                  src={`https://sgefeemg.bigmidia.com/_uploads/fotoPessoa/${tecnico.foto}`}
                  alt={tecnico.nome_completo}
                  className={classes.largeAvatar}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Typography
                  variant="h4"
                  component="h1"
                  color="primary"
                  className={classes.fontBold}
                  gutterBottom
                >
                  {tecnico.nome_completo
                    ? Capitalize(tecnico.nome_completo)
                    : ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Vinculo:{" "}
                  {estabelecimento.descricao
                    ? Capitalize(estabelecimento.descricao)
                    : ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Registro: {tecnico.id}
                </Typography>
              </Grid>
            </Grid>
            <HistoricoPessoa pessoaId={pessoaId} />
            <div style={{ padding: "40px 0" }}>
              <TableTecnico
                pessoas={tecnicos}
                escolaId={escolaId}
                slugEscola={slugEscola}
              />
            </div>
          </Container>
        </Paper>
      </div>
    );
  }
}
