import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link, useHistory } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  menuTitle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    textShadow: "1px 1px 6px rgba(0,0,0,0.5)",
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
  },
  pageTitle: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: "#fff",
    textShadow: "1px 1px 6px rgba(0,0,0,0.5)",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.55rem",
    },
  },
  BreadcrumbsStyle: {
    color: "#fff",
    "& a": {
      textDecoration: "none",
      color: "#fff",
      "&:hover": {
        color: "#dedede",
      },
    },
    "& .MuiTypography-root": {
      color: "#fff",
    },
    "& a, & .MuiTypography-root": {
      fontSize: "1.3rem",
      fontWeight: "bold",
      textShadow: "1px 1px 6px rgba(0,0,0,0.5)",
    },
  },
}));

function PageTitle(props) {
  const { menuTitle, pageTitle, breadcrumbs, breadcrumbsLinks } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Container className={classes.container}>
        <h1 className={classes.menuTitle}>{menuTitle}</h1>

        {breadcrumbs && breadcrumbsLinks ? (
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.BreadcrumbsStyle}
          >
            {breadcrumbsLinks.map((item, key) => {
              if (breadcrumbsLinks.length - 1 === key) {
                return (
                  <Typography key={key} color="textPrimary">
                    {item.title}
                  </Typography>
                );
              }
              return (
                <Link key={key} color="white" onClick={() => history.goBack()}>
                  {item.title}
                </Link>
              );
            })}
          </Breadcrumbs>
        ) : (
          <h2 className={classes.pageTitle}>{pageTitle}</h2>
        )}
      </Container>
    </div>
  );
}

export default PageTitle;
