import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { temporadasDesde } from '../../../util/temporadas'
import moment from 'moment';
import { Link, useHistory} from "react-router-dom";
import { PillButtonSuccess } from '../../../components/Styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "30px 0px",
    },
    Card: {
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
    },

    paginationContainer: {
        padding: "20px 0",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-end",
        },
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 100,
    },
    inputGroup: {
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.up("lg")]: {
            flexWrap: "unset",
            "& a": {
                marginTop: 0,
            },
        },
    },
    spinnerContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "2rem",
        paddingBottom: "4rem",
    },
    errorContainer: {
        paddingTop: "1rem",
        paddingBottom: "4rem",
    },
    pageTitle: {
        color: theme.palette.primary.main,
        fontSize: "1.5rem",
        fontWeight: 900,
    },
}));

function FiltrosArbitragemCursos(params) {
    const classes = useStyles();
    const page = params.page;
    const queryParam = params.searchTerm;
    const temporada = params.temporada ? params.temporada : moment().format('YYYY');
  
    const [searchTerm, setSearchTerm] = useState(queryParam);
    const [temporadaEvento, setTemporadaEvento] = useState(temporada);
    const [eventoFinalizado, setEventoFinalizado] = useState(false);
    
      const handleChangeStatusEvento = (event) => {
        setEventoFinalizado(event.target.value);
      };
    
      const handleChangeTemporada = (event) => {
        setTemporadaEvento(event.target.value);
        if (queryParam || page) {
          history.push(
            `/arbitragem/cursos${event.target.value ? `?temporada=${event.target.value}&` : "?"}${queryParam ? `&q=${queryParam}` : ""}${
              page ? `&p=${page}` : ""
            }`
          );
        } else {
          history.push(`/arbitragem/cursos${event.target.value ? `?temporada=${event.target.value}` : ""}`);
        }
      };
        
      let history = useHistory();
      const handleSearchKeyDown = (e) => {
        if (e.key === "Enter") {
          if (searchTerm !== "") {
            history.push(`/arbitragem/cursos?q=${searchTerm}`);
          } else {
            history.push("/arbitragem/cursos");
          }
        }
      };

      
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
                    <InputLabel>Temporada:</InputLabel>
                    <Select value={temporadaEvento} onChange={handleChangeTemporada} label="Temporada">
                        <MenuItem value={""}>Todas</MenuItem>
                        {temporadasDesde('2022').map(item => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
                    <InputLabel>Status:</InputLabel>
                    <Select value={eventoFinalizado} onChange={handleChangeStatusEvento} label="Status">
                        <MenuItem value="todos">Todos</MenuItem>
                        <MenuItem value={true}>Finalizados</MenuItem>
                        <MenuItem value={false}>Não Finalizados</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={8}>
                <div className={classes.formControl}>
                    <div className={classes.inputGroup}>
                        <TextField
                            label="Pesquisar"
                            size="small"
                            style={{ marginRight: ".4rem" }}
                            fullWidth={true}
                            value={searchTerm}
                            onKeyDown={(e) => handleSearchKeyDown(e)}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            variant="outlined"
                        />
                        <div>
                            {!searchTerm ? (
                                <Link
                                    component={PillButtonSuccess}
                                    className={classes.searchButton}
                                    to="/competicoes"
                                    variant="contained"
                                    disableElevation
                                >
                                    Pesquisar
                                </Link>
                            ) : (
                                <Link
                                    component={PillButtonSuccess}
                                    className={classes.searchButton}
                                    to={`/arbitragem/cursos${searchTerm !== "" || searchTerm !== null ? `?q=${searchTerm}` : ""}`}
                                    variant="contained"
                                    disableElevation
                                >
                                    Pesquisar
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default FiltrosArbitragemCursos