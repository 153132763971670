import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      lg2: 1600,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: "#e15049",
      main: "#da251c",
      dark: "#981913",
      contrastText: "#fff",
    },
    secondary: {
      light: "#25D9F8",
      main: "#06b6d4",
      dark: "#06A9C6",
      contrastText: "#fff",
    },
    vidaAtleta: {
      light: "#034bc4",
      main: "#0a244e",
      dark: "#0a244f",
      contrastText: "#fff",
    },
    vidaAtletaVerde: {
      light: "#c3ec19",
      main: "#c8f21a",
      dark: "#30515e",
      contrastText: "#fff",
    },
    vidaAtletaRoxo: {
      light: "#9436D3",
      main: "#7024a4",
      dark: "#5C1D86",
      contrastText: "#fff",
    },
    vidaAtletaAzul: {
      light: "#B2D1FB",
      main: "#71aaf7",
      dark: "#5198F5",
      contrastText: "#fff",
    },
    futsal: {
      main: "#2F52E0",
      contrastText: "#fff",
    },
    volei: {
      main: "#ffb400",
      contrastText: "#000",
    },
    basquete: {
      main: "#FF715B",
      contrastText: "#000",
    },
    handebol: {
      main: "#99E000",
      contrastText: "#000",
    },
  },
});

export default theme;
