import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//material-ui
import { Grid, Container } from '@material-ui/core';

//img
import arbitragemImg from '../../../assets/img/acesso-rapido/17.png';
import calendarioImg from '../../../assets/img/acesso-rapido/16.png';
import competicoesImg from '../../../assets/img/acesso-rapido/18.png';
import filiadasImg from '../../../assets/img/acesso-rapido/19.png';

import { Link } from 'react-router-dom';

import { PillButtonPrimary } from '../../../components/Styles';;


const useStyles = makeStyles((theme) => ({

    container: {
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        overflow: 'auto',
        '& p': {
            marginBottom: '1.5rem'
        }
    },
    cardsWraper: {
        marginBottom: 50
    },

    heading: {
        fontSize: '25px',
        fontWeight: 900,
        marginBottom: 0,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 250,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    cardTitle: {
        fontWeight: 900,
        fontSize: '25px',
        marginBottom: '18px'
    },
    overlay: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.3)',
        minHeight: 250,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            transition: 'all 0.45s ease-out',
            '&:hover': {
                paddingBottom: '80px',
                backgroundColor: 'rgba(0,0,0,0.7)',
            }
        }
    },
}));

export default function AcessoRapido() {
    const classes = useStyles();

    return (

        <div className={classes.container}>
            <Container>
                <h3 className={classes.heading} >ACESSO RÁPIDO</h3>
                <p>Estes são alguns dos nossos serviços</p>

                <Grid container spacing={1} className={classes.cardsWraper}>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.card} style={{ backgroundImage: `url(${arbitragemImg})` }}>
                            <div className={classes.overlay}>
                                <span className={classes.cardTitle}>
                                    ARBITRAGEM
                                </span>
                                <div><PillButtonPrimary color="primary" variant="contained" component={Link} to={"arbitragem/36/escalas"}>SAIBA MAIS</PillButtonPrimary></div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.card} style={{ backgroundImage: `url(${calendarioImg})` }}>
                            <div className={classes.overlay}>
                                <span className={classes.cardTitle}>
                                    CALENDÁRIO
                            </span>
                                <div><PillButtonPrimary color="primary" variant="contained" component={Link} to={"/calendario"}>SAIBA MAIS</PillButtonPrimary></div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.card} style={{ backgroundImage: `url(${competicoesImg})` }}>
                            <div className={classes.overlay}>
                                <span className={classes.cardTitle}>
                                    COMPETIÇÃO
                            </span>
                                <div><PillButtonPrimary color="primary" variant="contained" component={Link} to={"/competicoes"}>SAIBA MAIS</PillButtonPrimary></div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.card} style={{ backgroundImage: `url(${filiadasImg})` }}>
                            <div className={classes.overlay}>
                                <span className={classes.cardTitle}>
                                    ESCOLAS FILIADAS
                            </span>
                                <div><PillButtonPrimary color="primary" variant="contained" component={Link} to={"feemg/escolas-filiadas"}>SAIBA MAIS</PillButtonPrimary></div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>


    )

}
