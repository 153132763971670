import React, { useState } from 'react';
import clsx from 'clsx';

// import InputBase from '@material-ui/core/InputBase';
import { makeStyles, fade } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        backgroundColor: fade(theme.palette.common.black, 0.05),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.05),
        },
        [theme.breakpoints.up('lg')]: {
            overflow: 'hidden',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
        },
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        marginLeft: 0,
        width: 'auto',
    },
    searchIcon: {
        color: theme.palette.primary.main,
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        border: '1px solid transparent',
        borderRadius: 8,
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '90px',

    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        paddingBottom: 0,
        paddingTop: 0,
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end'
        },
    },
    menuSearch: {
        '& ::placeholder': {
            fontWeight: 500,
            opacity: 0.8,
        }
    }
}));

export default function SearchInput(params) {
    const classes = useStyles();
    const { mobile, setExpanded, setAnchorSearchEl } = params
    const [searchQuery, setSearchQuery] = useState('')

    const [error, setError] = useState(false)
    let history = useHistory();

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            executeSearch()
            e.preventDefault()
            setAnchorSearchEl(null)
        }
    }

    const executeSearch = () => {
        if (searchQuery !== "") {
            setError(false)
            history.push(`/pesquisa?q=${searchQuery}`);
            if (mobile) {
                setExpanded(false)
            }
        } else {
            setError(true)
        }
        setSearchQuery('')
        
        executeScroll();

    }

    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: 0 });



    if (mobile) {
        return (
            <FormControl className={clsx(classes.margin, classes.textField)} fullWidth variant="outlined">

                <Input
                    id="outlined-adornment-pesquisa"
                    type="text"
                    labelwidth={0}
                    value={searchQuery}
                    fullWidth
                    error={error}
                    color="primary"
                    placeholder="Pesquisar"
                    onChange={e => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => handleSearchKeyDown(e)}
                    className={classes.menuSearch}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                color="primary"
                                aria-label="pesquisa"
                                onClick={executeSearch}

                                edge="end"
                            >
                                <SearchIcon color="primary" />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )
    } else {
        return (
            <div className={classes.container}>
                {/* <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Pesquisar…"
                        value={searchQuery}
                        error={error}
                        onChange={e => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => handleSearchKeyDown(e)}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div> */}
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                        <TextField
                            placeholder="Pesquisar…"
                            value={searchQuery}
                            error={error}
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => handleSearchKeyDown(e)}
                            autoFocus="true"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            aria-label="pesquisa"
                            size="small"
                            onClick={executeSearch}
                        >
                            <SearchIcon />
                        </Button>
                    </Grid>
                </Grid>

            </div>
        )
    }
}
