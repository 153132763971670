import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-h6": {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: ".9rem",
      fontWeight: "bold",
    },
    "& table": {
      "& td, & th": {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: "0.7rem",
      },
      "& thead": {
        "& th": {
          fontWeight: "bold",
          textTransform: "uppercase",
        },
      },
      "& .col-pessoa": {
        display: "flex",
        alignItems: "center",
        "& .MuiAvatar-root": {
          height: 26,
          width: 26,
          marginRight: 14,
        },
      },
    },
  },
  link: {
    fontWeight: 600,
    color: 'inherit',
    textDecoration: 'none',
    "&:hover": {
      color:theme.palette.primary.main,
      textDecoration: 'underline',
    }
  }
}));

export default useStyles;
