import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 500,
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    "& p": {
      fontSize: ".9rem",
      color: "inherit",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
  },
  futsal: {
    backgroundColor: theme.palette.futsal.main,
    color: theme.palette.futsal.contrastText,
  },
  volei: {
    backgroundColor: theme.palette.volei.main,
    color: theme.palette.volei.contrastText,
  },
  basquete: {
    backgroundColor: theme.palette.basquete.main,
    color: theme.palette.basquete.contrastText,
  },
  handebol: {
    backgroundColor: theme.palette.handebol.main,
    color: theme.palette.handebol.contrastText,
  },
}));
