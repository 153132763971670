import React from 'react';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Noticias from './sections/Noticias';
import Documentos from './sections/Documentos';
import Eventos from './sections/Eventos';
import Escolas from './sections/Escolas';
import Alunos from './sections/Alunos';


//components
import PageTitle from '../../components/PageTitle';
import MetaTags from 'react-meta-tags'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        paddingTop: '50px',
        paddingBottom: '50px',
    },
    section: {
        margin: '40px 0 20px 0'
    },
    header: {
        fontWeight: 600,
        
    }
}));


export default function Pesquisa(params) {
    const classes = useStyles();

    const searchTerm = params.searchTerm

    return (
        <>
            <MetaTags>
                <title>{searchTerm} - Pesquisa - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="FEEMG" pageTitle="Pesquisa" />
            <Paper className={classes.root} square elevation={0}>
                <Container maxWidth="lg" className={classes.container}>
                    <Typography className={classes.header} variant="h5" component="h1" color="primary" gutterBottom>Pesquisa: {searchTerm}</Typography>
                    <section className={classes.section}>
                        <Typography variant="h6" component="h2" color="primary" gutterBottom>Notícias:</Typography>
                        <Noticias searchTerm={searchTerm} />
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h6" component="h2" color="primary" gutterBottom>Documentos:</Typography>
                        <Documentos searchTerm={searchTerm} />
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h6" component="h2" color="primary" gutterBottom>Eventos:</Typography>
                        <Eventos searchTerm={searchTerm} />
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h6" component="h2" color="primary" gutterBottom>Escolas:</Typography>
                        <Escolas searchTerm={searchTerm} />
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h6" component="h2" color="primary" gutterBottom>Alunos:</Typography>
                        <Alunos searchTerm={searchTerm} />
                    </section>
                </Container>
            </Paper>
        </>
    )
}