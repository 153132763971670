import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//img
import facebook from '../../assets/img/social-media/facebook.png';
import youtube from '../../assets/img/social-media/youtube.png';
import twitter from '../../assets/img/social-media/twitter.png';
import instagram from '../../assets/img/social-media/instagram.png';
import flickr from '../../assets/img/social-media/flickr.png';
import chess from '../../assets/img/social-media/chess.png';
import twitch from '../../assets/img/social-media/twitch.png';
import linkedin from '../../assets/img/social-media/linkedin.png';


const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 900,
        fontSize: '1.55rem'
    },
    socialIcons: {
        marginTop: '1rem',
        '& img': {
            margin: '5px',
            width: '40px',
            height: '40px',
            [theme.breakpoints.up('md')]: {
                width: '60px',
                height: '60px',
            }
        }
    },
    socialIconsSM: {
        '& img': {
            margin: '5px',
            width: '35px',
            height: '35px'
        }
    }
}));

export default function SocialMidiaLinks(params) {
    const classes = useStyles();
    const title = params.title;
    const align = params.align;
    const iconSize = params.iconSize;

    return (
        <React.Fragment>
            <Typography className={classes.title} variant="h4" component="h4" color="primary">{title}</Typography>

            <div className={iconSize === "small" ? classes.socialIconsSM : classes.socialIcons} style={{ textAlign: align }}>
                <a href="https://www.facebook.com/feemg.oficial/" target="_blank" rel="noreferrer">
                    <img src={facebook} alt="logo facebook" />
                </a>
                <a href="https://twitter.com/FEEMG_oficial" target="_blank" rel="noreferrer">
                    <img src={twitter} alt="logo twitter" />
                </a>
                <a href="https://www.linkedin.com/company/feemg" target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="logo linkedin" />
                </a>
                <a href="https://www.youtube.com/user/FEEMG1/videos" target="_blank" rel="noreferrer">
                    <img src={youtube} alt="logo youtube" />
                </a>
                <a href="https://www.instagram.com/feemg_oficial" target="_blank" rel="noreferrer">
                    <img src={instagram} alt="logo instagram" />
                </a>
                <a href="https://www.flickr.com/photos/feemg/albums" target="_blank" rel="noreferrer">
                    <img src={flickr} alt="logo flickr" />
                </a>
                <a href="https://www.twitch.tv/feemg" target="_blank" rel="noreferrer">
                    <img src={twitch} alt="logo twitch" />
                </a>
                <a href="https://www.chess.com/club/feemg" target="_blank" rel="noreferrer">
                    <img src={chess} alt="logo chess" />
                </a>
            </div>
        </React.Fragment>
    )

}
