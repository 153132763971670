import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { gestaoApi } from "../../../../services/api";

//components
import PageTitle from "../../../../components/PageTitle";

//material-ui
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Capitalize from "../../../../util/Capitalize";
import TabelaHand from "./Resultados/TabelaHand";
import TabelaFutsal from "./Resultados/TabelaFutsal";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TableAlunos from "./TableAlunos";
import TabelaBasquete from "./Resultados/TabelaBasquete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "40px",
    fontSize: "1rem",
  },
  fontBold: {
    fontWeight: 600,
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "2rem",
    paddingBottom: "4rem",
  },
  backLink: {
    textDecoration: "none",
    color: "inherit",
    fontWeight: 600,
    "&:active, &:hover ": {
      opacity: 0.6,
    },
  },
  largeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(15),
    fontSize: theme.spacing(6),
  },
  tableTitle: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    textTransform:"uppercase",
    color: "inherit",
  },
  tableHead: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    "& .MuiTableCell-root": {
      fontSize: "1.1rem",
      fontWeight: 600,
      paddingBottom: ".5rem",
    },
  },
}));

export default function Aluno() {
  const classes = useStyles();
  const { escolaId, slugEscola, alunoId } = useParams();
  const [aluno, setAluno] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (alunoId) {
      async function getAtleta() {
        await gestaoApi
          .get("atleta", {
            params: {
              id: alunoId,
              expand: "estabelecimento",
              flag_del: 0,
            },
          })
          .then((response) => {
            setAluno(response.data.items[0]);
            setLoading(false);
          });
      }
      getAtleta();
    }
  }, [alunoId]);

  const [atletas, setAtletas] = useState([]);

  useEffect(() => {
    if (escolaId) {
      async function getAtleta() {
        await gestaoApi
          .get("atleta", {
            params: {
              expand: "atletaHistoricoAtual",
              "atletaHistoricoAtual.id_estabelecimento": escolaId,
              flag_del: 0,
            },
          })
          .then((response) => {
            setAtletas(response.data.items);
          });
      }
      getAtleta();
    }
  }, [escolaId]);

  if (loading) {
    return (
      <>
        <PageTitle menuTitle="FEEMG" pageTitle="Alteta" />
        <Paper square elevation={0} className={classes.root}>
          <div className={classes.spinnerContainer}>
            <CircularProgress />
          </div>
        </Paper>
      </>
    );
  } else {
    return (
      <div>
        <PageTitle
          menuTitle="FEEMG"
          pageTitle={
            aluno.estabelecimento.descricao
              ? Capitalize(aluno.estabelecimento.descricao)
              : ""
          }
        />
        <Paper square elevation={0} className={classes.root}>
          <Container maxWidth="lg">
            <div style={{ textAlign: "right" }}>
              <Link
                to={`/feemg/escola/${escolaId}/${slugEscola}`}
                className={classes.backLink}
              >
                « Voltar
              </Link>
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar
                  variant="rounded"
                  src={`https://sgefeemg.bigmidia.com/_uploads/fotoAtleta/${aluno.foto}`}
                  alt={aluno.nome_completo}
                  className={classes.largeAvatar}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Typography
                  variant="h4"
                  component="h1"
                  color="primary"
                  className={classes.fontBold}
                  gutterBottom
                >
                  {aluno.nome_completo ? Capitalize(aluno.nome_completo) : ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Escola:{" "}
                  {aluno.estabelecimento.descricao
                    ? Capitalize(aluno.estabelecimento.descricao)
                    : ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Registro: {aluno.registro_confederacao}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.fontBold}
                  gutterBottom
                >
                  Ano nascimento: {aluno.data_nascimento}
                </Typography>
              </Grid>
            </Grid>
            <Box style={{ paddingTop: 40 }}>
              <Typography component="h4" className={classes.tableTitle}>
                Histórico de partidas
              </Typography>
              <TableContainer component={Paper} elevation={1}>
                <Table>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell>EVENTO</TableCell>
                      <TableCell>ESPORTE</TableCell>
                      <TableCell>PARTIDA</TableCell>
                      <TableCell>DATA</TableCell>
                      <TableCell>LOCAL</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TabelaHand alunoId={alunoId} />
                    <TabelaFutsal alunoId={alunoId} />
                    <TabelaBasquete alunoId={alunoId} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <div style={{ padding: "40px 0" }}>
              <TableAlunos
                atletas={atletas}
                escolaId={escolaId}
                slugEscola={slugEscola}
              />
            </div>
          </Container>
        </Paper>
      </div>
    );
  }
}
