import { Avatar, Button, TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { convertDate } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import RoomIcon from "@material-ui/icons/Room";
import useStyles from "../style";
import AnexosPartida from "./AnexosPartida";
import { OpenInNew } from "@material-ui/icons";

function RowPartidas({ partidas, evento }) {
  const classes = useStyles();
  const { esporte, title } = useParams();
  const testWinner = (pts1, pts2) => {
    if (pts1 > pts2) {
      return "score-winner";
    } else {
      return "score-loser";
    }
  };

  return (
    <React.Fragment>
      {partidas.map((item, key) => (
        <React.Fragment key={key}>
          <TableRow>
            <TableCell colSpan="3" className={classes.rowHeader}>
              <time dateTime={convertDate(item.data)}>
                <b>{convertDate(item.data)}</b>
              </time>{" "}
              - {item.fase}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 320 }}>
              <div
                container
                className={clsx(classes.gridEscola, classes.timeCasa)}
              >
                <div className={classes.timeDetalhes}>
                  <Link
                    to={`/feemg/escola/${item.casa.id}/${createSlug(
                      item.casa.descricao
                    )}`}
                  >
                    {item.casa.sigla_evento}
                  </Link>
                  <span className="widget-results__team-info">
                    {item.casa.uf}
                  </span>
                </div>
                <Avatar
                  className={classes.avatarEscola}
                  variant="square"
                  src={item.casa.urlLogo}
                  alt={item.casa.sigla_evento}
                />
              </div>
            </TableCell>
            <TableCell style={{ width: 100 }}>
              <Link
                to={`/feemg/${esporte}/${evento.id}/${title}/partida/${item.id}`}
                className={classes.tableLink}
              >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {item.placar_final_casa || item.placar_final_visitante ? (
                    <div className={classes.resultado}>
                      <span
                        className={testWinner(
                          item.placar_final_casa,
                          item.placar_final_visitante
                        )}
                      >
                        {item.placar_final_casa}
                      </span>
                      -
                      <span
                        className={testWinner(
                          item.placar_final_visitante,
                          item.placar_final_casa
                        )}
                      >
                        {item.placar_final_visitante}
                      </span>
                    </div>
                  ) : (
                    <div className={classes.vstext}>vs</div>
                  )}
                </div>
              </Link>
            </TableCell>
            <TableCell style={{ width: 320 }}>
              <div
                container
                className={clsx(classes.gridEscola, classes.timeVisitante)}
              >
                <Avatar
                  className={classes.avatarEscola}
                  variant="square"
                  src={item.visitante.urlLogo}
                  alt={item.visitante.sigla_evento}
                />
                <div className={classes.timeDetalhes}>
                  <Link
                    to={`/feemg/escola/${item.visitante.id}/${createSlug(
                      item.visitante.descricao
                    )}`}
                  >
                    {item.visitante.sigla_evento
                      ? item.visitante.sigla_evento
                      : item.visitante.sigla}
                  </Link>
                  <span className="widget-results__team-info">
                    {item.visitante.uf}
                  </span>
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} className={classes.rowFooter} style={item.documentos && item.documentos.length > 0 ? {} : { borderWidth: 2 }}>
              <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                <span className="game-result__league">
                  <RoomIcon /> Local: {item.local}
                </span>
                <div style={{ paddingLeft: 6 }}>
                  <Button
                    to={`/feemg/${esporte}/${evento.id}/${title}/partida/${item.id}`}
                    component={Link}
                    size="small" variant="text" color="secondary" style={{ fontSize: 11 }}>
                    <OpenInNew style={{ marginRight: 4 }} />
                    Partida</Button>
                </div>
              </div>
            </TableCell>
          </TableRow>
          {
            item.documentos && item.documentos.length > 0 ?
              <TableRow>
                <TableCell colSpan={3} style={{ paddingLeft: 0, paddingRight: 0, borderWidth: 2 }}>
                  <AnexosPartida documentos={item.documentos} />
                </TableCell>
              </TableRow>
              : null
          }
        </React.Fragment>
      ))
      }
    </React.Fragment >
  );
}

export default RowPartidas;
