const financeiroFornecedor = {
    id_estabelecimento: "26",
    state: "",
    city: "",
    logradouro2: "",
    id_pais: "",
    ramos: [],
    razao_social: "",
    descricao: "",
    inscricao_municipal: "",
    inscricao_estadual: "",
    cnpj: "",
    cpf: "",
    numero_telefone: "",
    email: "",
    data_alteracao: "",
    flag_cliente: "0",
    flag_fornecedor: "1",
    tipo: "Pessoa Jurídica",
    site: "",
    observacao: "",
    id_municipio: "",
    id_aeroporto_preferencial: "",
    bairro: "",
    cep: "",
    complemento: "",
    tipo_logradouro: "",
    logradouro: "",
    numero: "",
    data_update: "",
    data_create: "",
    created_by: "",
    updated_by: "",
    created_at: "",
    updated_at: "",
}

export default financeiroFornecedor;