import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableStyle: {
    "& td, & th": {
      fontFamily: '"Montserrat",sans-serif',
    },
    "& td": {
      fontSize: 11,
    },
    "& th": {
      fontWeight: "bold",
      fontSize: 16,
    },
  },
  tableCell: {
    display: "flex",
    alignItems: "center",
    "&.right": {
      justifyContent: "right",
      "& .icon-svg": {
        marginLeft: 10,
      },
    },
    "&.left": {
      justifyContent: "left",
      "& .icon-svg": {
        marginRight: 10,
      },
    },
    "& .icon-box": {
      fontSize: 10,
      position: "relative",
      "& span": {
        position: "absolute",
        right: "25%",
        top: 8,
        fontSize: "1rem",
        fontWeight: 900,
      },
    },
  },
}));
export default useStyles;
