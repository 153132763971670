import React, { useEffect, useState } from 'react';

import AccordionPage from '../../Generic/AccordionPage';

// - api
import { governancaApi } from '../../../services/api';

function ArbitragemRegimentoInterno() {
    const [orgao, setOrgao] = useState([])

    useEffect(() => {
        async function getOrgao() {
            await governancaApi.get('orgao', {
                params: {
                    id_menu: 39,
                    flag_del: 0,
                },
            }).then((response) => {
                setOrgao(response.data.items)
            });
        }
        getOrgao()
    }, [])


    return (
        <AccordionPage menuTitle="Arbitragem" pageTitle="Regimento Interno" orgao={orgao} />
    )
}

export default ArbitragemRegimentoInterno;