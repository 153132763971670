import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { Alert, Skeleton } from "@material-ui/lab";
import { TemplateTab, TemplateTabs } from "../../../components/TabPanel";
import _ from "lodash";
import RowPartidas from "./RowPartidas";
import { fetchEventoGrupo } from "../hooks/getEventoGrupo";
import { useQuery } from "@tanstack/react-query";
import { fetchEventoPartida } from "../hooks/getEventoPartida";
import moment from "moment";

function Partidas({ evento }) {
  const classes = useStyles();
  const statusEvento = moment().isSameOrAfter(evento.data_fim) ? 1 : 0;
  const [statusPartidas, setStatusPartida] = useState(statusEvento);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["PartidasGrupo"],
    ({ pageParam = { evento } }) => fetchEventoGrupo(pageParam)
  );

  const { data: eventoPartida, isLoading: LoadingEventoPartida } = useQuery(
    ["EventoPartida"],
    ({ pageParam = { evento } }) => fetchEventoPartida(pageParam)

  );

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleChangeStatusPartida = (event) => {
    setStatusPartida(event.target.value);
  };

  useEffect(() => {
    if (!LoadingEventoPartida && eventoPartida && eventoPartida.length === 0) {
      setStatusPartida(0)
    }
  }, [eventoPartida, LoadingEventoPartida])



  return (
    <Card style={{ marginBottom: 20 }}>
      <CardContent style={{ paddingBottom: 0 }}>
        <div style={{ display: "flex" }}>
          <Typography
            className={classes.heading}
            style={{ marginRight: "auto" }}
            variant="h5"
            color="primary"
            gutterBottom
          >
            Partidas
          </Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={statusPartidas}
              style={{ padding: 0 }}
              onChange={handleChangeStatusPartida}
            >
              <MenuItem value={1}>Resultados</MenuItem>
              <MenuItem value={0}>Programadas</MenuItem>
            </Select>
          </FormControl>
        </div>
      </CardContent>
      <div>
        {isError ? (
          <Alert severity="error" style={{ margin: "10px 0" }}>
            Ainda não há partidas cadastradas para este evento.
          </Alert>
        ) : null}

        {isLoading ? (
          <Box>
            <TemplateTabs
              color="primary"
              variant="fullWidth"
              value={tabIndex}
              onChange={handleChange}
            >
              {Array.from(new Array(2)).map((item, key) => (
                <TemplateTab key={key} label={`CHAVE ${key + 1}`} value={key} />
              ))}
            </TemplateTabs>
            <Skeleton variant="rect" style={{ height: 200 }} />
          </Box>
        ) : null}

        {isSuccess && !LoadingEventoPartida ? (
          <CreateTabPanel
            groupPartidas={data}
            statusPartidas={statusPartidas}
            eventoPartida={eventoPartida}
            evento={evento}
          />
        ) : null}
      </div>
    </Card>
  );
}

export default Partidas;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const HandleRowPartida = ({ partidas, statusPartidas, evento }) => {
  let partidasPorStatus = _.filter(partidas, function (o) {
    return o.flg_status === statusPartidas;
  });

  if (partidasPorStatus && partidasPorStatus.length > 0) {
    return <RowPartidas partidas={partidasPorStatus} evento={evento} />;
  } else {
    return (
      <TableRow>
        <TableCell colSpan="3">
          <Alert severity="error">Nenhuma partida encontrada.</Alert>
        </TableCell>
      </TableRow>
    );
  }
};

function CreateTabPanel({
  groupPartidas,
  statusPartidas,
  eventoPartida,
  evento,
}) {
  const _groupPartidas = _.filter(groupPartidas, function (o) {
    return o.descricao !== "Classificação Final";
  });
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const classes = useStyles();

  return (
    <Box>
      <TemplateTabs
        color="primary"
        variant="fullWidth"
        value={tabIndex}
        onChange={handleChange}
      >
        {_groupPartidas.map((item, key) => (
          <TemplateTab key={key} label={item.descricao} value={key} />
        ))}
        <TemplateTab label="Finais" value={_groupPartidas.length} />
      </TemplateTabs>
      {_groupPartidas.map((item, key) => (
        <TabPanel key={key} value={tabIndex} index={key}>
          <TableContainer>
            <Table className={classes.tablePartidas}>
              <TableBody>
                <HandleRowPartida
                  partidas={item.eventoPartidas}
                  statusPartidas={statusPartidas}
                  evento={evento}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      ))}
      <TabPanel value={tabIndex} index={_groupPartidas.length}>
        <div className="table-responsive">
          <Table>
            <TableBody>
              <HandleRowPartida
                partidas={eventoPartida}
                statusPartidas={statusPartidas}
                evento={evento}
              />
            </TableBody>
          </Table>
        </div>
      </TabPanel>
    </Box>
  );
}
