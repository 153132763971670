import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import TabelaStaffFutsal from './Resultados/TabelaFutsal'
import TabelaStaffBasquete from './Resultados/TabelaBasquete'
import TabelaStaffHand from './Resultados/TableHand'
import { useStyles } from './styles'

function HistoricoPessoa({ pessoaId }) {
  const classes = useStyles();
 
  return (
    <Box style={{ paddingTop: 40 }}>
      <Typography component="h4" className={classes.tableTitle}>
        Histórico de partidas
      </Typography>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>EVENTO</TableCell>
              <TableCell>FUNÇÃO</TableCell>
              <TableCell></TableCell>
              <TableCell>DATA</TableCell>
              <TableCell>LOCAL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TabelaStaffFutsal pessoaId={pessoaId} />
            <TabelaStaffBasquete pessoaId={pessoaId} />
            <TabelaStaffHand pessoaId={pessoaId} /> 
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default HistoricoPessoa