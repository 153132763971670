import axios from "axios";
import { ApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchEventoGrupo(props) {
  const { evento } = props;
  const _api = ApiEsporte[evento.id_esporte] || ApiEsporte[1];

  const res = await axios.get(`${baseURL}/${_api}/api/evento-grupo`, {
    params: {
      forcejoin: "eventoGrupoClubes,eventoClassificacaos",
      forcewhere: `evento_grupo.id_evento:${evento.id}`,
      pageSize: "2000",
      expand:
        "documentos,eventoClassificacaos,eventoClassificacaos.idEstabelecimento,eventoPartidas,eventoPartidas.casa,eventoPartidas.visitante,eventoGrupoClubes,eventoGrupoClubes.estabelecimento",
      sort: 'ordem'
    },
  });
  const data = res.data.items;
  return data;
}
