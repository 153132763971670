import React, { useState, useEffect } from "react";

//components
import PageTitle from "../../../components/PageTitle";

//material ui
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
// import Typography from '@material-ui/core/Typography';

//data
// import fundadores from '../../../assets/data/feemg/fundadores';

//img
import representates from "../../../assets/img/icones/associados.png";
import atletas from "../../../assets/img/icones/atletas.png";
import escolas from "../../../assets/img/icones/escolas.png";
import associados from "../../../assets/img/icones/associado.webp";
import arbitros from "../../../assets/img/icones/arbitro.webp";
import professoresetecnicos from "../../../assets/img/icones/professoresetecnicos.png";

import { PillButtonPrimary } from "../../../components/Styles";

// - api
import { governancaApi } from "../../../services/api";

import ReactHtmlParser from "react-html-parser";

import Filiados from "./sections/filiados";

import MetaTags from "react-meta-tags";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: theme.palette.primary.main,
    color: "#fff",
    padding: "40px 5%",
    "& h3": {
      textAlign: "center",
      marginBottom: "1rem",
    },
    "& img": {
      maxWidth: "100%",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: 900,
  },

  textcnpj: {
    color: "gray",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "22px",
    margin: "1rem 0",
  },

  filiarContainer: {
    padding: "40px 5%",
    backgroundColor: "rgba(55,48,50,0.7)",
    color: "#fff",
    textAlign: "center",
    "& h3": {
      textAlign: "center",
    },
  },
  filiarGrid: {
    marginBottom: "40px",
  },
  cardFundadores: {
    "& .MuiCardContent-root": {
      "& .MuiTypography-body1": {
        color: theme.palette.primary.main,
        fontSize: "15px",
        fontWeight: 900,
      },
      "& .MuiGrid-root + p": {
        color: "gray",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "22px",
        margin: "1rem 0",
      },
    },
  },
}));

function EscolasFiliadas(params) {
  const classes = useStyles();
  const page = params.page;
  const queryParam = params.searchTerm;

  const [fundadores, setFundadores] = useState([]);

  useEffect(() => {
    async function getFundadores() {
      await governancaApi
        .get("orgao", {
          params: {
            id_menu: 13,
          },
        })
        .then((response) => {
          setFundadores(response.data.items);
          // console.log(response.data.items)
        });
    }

    getFundadores();
  }, []);

  return (
    <>
      <MetaTags>
        <title>
          Escolas filiadas - FEEMG - Federação de Esportes Estudantis de Minas
          Gerais
        </title>
      </MetaTags>
      <PageTitle menuTitle="FEEMG" pageTitle="Escolas filiadas" />

      <div className={classes.root}>
        <h3 className="heading-white">Fundadores</h3>
        <Grid container className={classes.cardFundadores} spacing={2}>
          {fundadores.map((fundador, i) => (
            <Grid item key={i} xs={12} sm={6} md={6} lg={3}>
              <Card style={{ height: "100%" }}>
                <CardContent>
                  {ReactHtmlParser(fundador.corpo)}
                  {/* <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <img src={fundador.logo} alt={fundador.nome} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography className={classes.title} gutterBottom>
                                                {i + 1} - {fundador.nome}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <p className={classes.textcnpj}>
                                        {fundador.endereco.cidade} | CNPJ: {fundador.cnpj}
                                    </p>
                                    <p>
                                        <b>Responsável:</b><br />
                                        {fundador.responsavel.nome} - {fundador.responsavel.responsabilidade}
                                    </p>
                                    <div>
                                        {fundador.endereco.logradouro}, nº {fundador.endereco.numero}<br />
                                        {fundador.endereco.bairro} -  CEP: {fundador.endereco.CEP} <br />
                                        Tel: {fundador.telefone}<br />
                                        <a href={fundador.site} target="_blank" rel="noreferrer" className="link">{fundador.site}</a>
                                    </div> */}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      <div className={classes.filiarContainer} id="cadastre-se">
        <h3 className="heading-white">Como filiar-se à FEEMG?</h3>

        <Grid container>
          <Grid item xs={12} sm={4} className={classes.filiarGrid}>
            <div>
              <img src={arbitros} alt="Árbitros" />
            </div>
            <span className="heading-white">Árbitros</span>
            <p>Árbitros sem vínculo com escola.</p>
            <PillButtonPrimary
              variant="contained"
              component={Link}
              target="_blank"
              rel="noreferrer"
              href={`https://sge.feemg.com.br/cadastroExterno/pessoa?cod=oiturygtrLV]qTQ--&type=%C3%A1rbitro/oficial`}
              disableElevation
            >
              cadastre-se
            </PillButtonPrimary>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.filiarGrid}>
            <div>
              <img src={representates} alt="Representantes" />
            </div>
            <span className="heading-white">Representantes</span>
            <p>Representantes sem vínculo com escola.</p>
            <PillButtonPrimary
              variant="contained"
              component={Link}
              target="_blank"
              rel="noreferrer"
              href={`https://sge.feemg.com.br/cadastroExterno/pessoa?cod=oiturygtrLV]qTQ--&type=representante`}
              disableElevation
            >
              cadastre-se
            </PillButtonPrimary>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.filiarGrid}>
            <div>
              <img src={associados} alt="Associados" />
            </div>
            <span className="heading-white">Associados</span>
            <p>Cadastro para clube de vantagens.</p>
            <PillButtonPrimary
              variant="contained"
              component={Link}
              target="_blank"
              rel="noreferrer"
              href={`https://sge.feemg.com.br/cadastroExterno/pessoa?cod=oiturygtrLV]qTQ--&type=associado`}
              disableElevation
            >
              cadastre-se
            </PillButtonPrimary>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.filiarGrid}>
            <div>
              <img src={escolas} alt="Escolas" />
            </div>
            <span className="heading-white">Escolas</span>
            <p>Válido para Instituições de ensino.</p>
            <PillButtonPrimary
              variant="contained"
              component={Link}
              target="_blank"
              rel="noreferrer"
              href={`https://sge.feemg.com.br/cadastroExterno/entidade`}
              disableElevation
            >
              cadastre-se
            </PillButtonPrimary>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.filiarGrid}>
            <div>
              <img src={atletas} alt="Atletas" />
            </div>
            <span className="heading-white">Estudantes-Atletas</span>
            <p>Solicite seu cadastro à sua escola, caso ela seja filiada.</p>
            {/* <p>Cadastro de atletas</p>
                        <PillButtonPrimary color="primary" variant="contained" component={Link} target="_blank" rel="noreferrer" href={`https://sgefeemg.bigmidia.com/cadastroExterno/pessoa?cod=oiturygtrLV]qTQ--&type=pessoa`} disableElevation>cadastre-se</PillButtonPrimary> */}
          </Grid>
          <Grid item xs={12} sm={4} className={classes.filiarGrid}>
            <div>
              <img src={professoresetecnicos} alt="Professores e Técnicos" />
            </div>
            <span className="heading-white">Professores e Técnicos</span>
            <p>Solicite seu cadastro à sua escola, caso ela seja filiada.</p>
          </Grid>
        </Grid>
      </div>

      <div className={classes.root}>
        <Filiados page={page} queryParam={queryParam} />
      </div>
    </>
  );
}

export default EscolasFiliadas;
