import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { useQuery } from "@tanstack/react-query";
import { fetchEventoPartidaScout } from "../../../hooks/getEventoPartidaScout";
import { fetchTipoScouts } from "../../../hooks/GetTipoScouts";
import { useMemo } from "react";
import TableScouts from "./TableScouts";
import { fetchEventoPartidaPorId } from "../../../hooks/getEventoPartida";

function PlaybyPlay(props) {
  const { evento, idPartida, esporte } = props;
  const { isSuccess: tipoScoutsSuccess, data: tipoScouts } = useQuery(
    ["TipoScouts", { evento }],
    ({ pageParam = { esporte, formatMap: true } }) => fetchTipoScouts(pageParam)
  );
  const { isSuccess, data } = useQuery({
    queryKey: ["eventoPartidaScout", { esporte, idPartida }],
    queryFn: ({ pageParam = { esporte, idPartida } }) =>
      fetchEventoPartidaScout(pageParam),
  });
  const { data: eventoPartidaData, isSuccess: isSuccessPartida } = useQuery(
    ["EventoPartidaPorId"],
    ({ pageParam = { idPartida, esporte } }) =>
      fetchEventoPartidaPorId(pageParam)
  );

  const eventoPartidaScouts = useMemo(() => {
    if (tipoScoutsSuccess && isSuccess) {
      const scouts = data.map((item) => ({
        ...item,
        scout: tipoScouts.get(item.id_scout),
      }));
      return scouts;
    }
    return [];
  }, [data, isSuccess, tipoScouts, tipoScoutsSuccess]);

  return (
    <Box>
      <Container>
        <Paper>
          {isSuccess && isSuccessPartida && eventoPartidaScouts ? (
            <TableScouts
              eventoPartida={eventoPartidaData}
              eventoPartidaScouts={eventoPartidaScouts}
            />
          ) : null}
        </Paper>
      </Container>
    </Box>
  );
}

export default PlaybyPlay;
