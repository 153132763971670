import React, { useState, useEffect } from 'react'; 

// - api
import YouTubeApi from '../../../services/youtube'

//material-ui
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination'; 
// import TextField from '@material-ui/core/TextField';

//components
import PageTitle from '../../../components/PageTitle';

import MetaTags from 'react-meta-tags'

//util
import scrollToTopFunction from '../../../util/scrollToTopFunction';

//components
// import { PillButtonSuccess } from '../../../components/Styles'; 

import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    marginContainer: {
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    formControl: {
        marginBottom: theme.spacing(3),
        minWidth: 200,
        maxWidth: 500
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            '& a': {
                marginTop: '1rem'
            }
        },
        [theme.breakpoints.up('lg')]: {
            '& a': {
                marginTop: 0
            }
        }
    },
}));

function LeituraNoticia() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true); 
    const [page, setPage] = useState(1);
    const [youtubeVideos, setYoutubeVideos] = useState([]);
    const [youtubeVideosChunk, setYoutubeVideosChunk] = useState([]) 

    useEffect(() => {
        async function getYoutube() {
            await YouTubeApi.get('/playlistItems', {
                params: {
                    playlistId: process.env.REACT_APP_FEEMG_YOUTUBE_UPLOADS_ID,
                    maxResults: 50,
                }
            }).then((response) => {
                scrollToTopFunction();
                // setTotalPages(response.pageInfo.totalResults)
                // setNextPageToken(response.nextPageToken)
                // setPrevPageToken(response.prevPageToken)
                setYoutubeVideos(response.data.items)
                setLoading(false)
                // console.log(response.data.items)
            });
        }
        getYoutube();
    }, [])


    useEffect(() => {
        var chunks = []
        if (youtubeVideos) {
            chunks = _.chunk(youtubeVideos, 12)
            // console.log(chunks)
            setYoutubeVideosChunk(chunks)
        } 
    }, [youtubeVideos])

    useEffect(() => {
        scrollToTopFunction();
    }, [page]) 

    
    const handleChange = (event, value) => {
        setPage(value);
    };




    return (
        <>
            <PageTitle menuTitle="Notícias" pageTitle="Vídeos" />
            <Paper square className={classes.marginContainer}>
                {loading ?
                    <div className={classes.spinnerContainer}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <MetaTags>
                            <title>Vídeos / Notícias - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                        </MetaTags>
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                {youtubeVideosChunk[page - 1].map((item, i) => (
                                    <Grid item key={i} xs={12} sm={6} md={6} lg={4}>
                                        <iframe title={`video-${i}`} width="100%" height="200" src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                                    </Grid>
                                ))}
                            </Grid>
                            <div className={classes.paginationContainer}>
                                <Pagination
                                    page={page}
                                    count={5}
                                    onChange={handleChange}
                                />
                            </div>
                        </Container>
                    </>
                }
            </Paper>
        </>
    )
}

export default LeituraNoticia;
