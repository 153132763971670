import {
  Avatar,
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { fetchEventoStaff } from "../../../hooks/getEventoStaff";
import { useQuery } from "@tanstack/react-query";
import useStyles from "./styles";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../../util/createSlug";

function TableStaff({ evento, idPartida, idEquipe }) {
  const classes = useStyles();
  const url = "https://sgefeemg.bigmidia.com";
  const { data, isSuccess, isLoading } = useQuery(
    [`EventoStaffPessoa-${idEquipe}`],
    ({ pageParam = { evento, idPartida, idEquipe } }) =>
      fetchEventoStaff(pageParam)
  );

  if (isSuccess) {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell colSpan="100%">
            <Typography className={classes.tableDividerText}>STAFF</Typography>
          </TableCell>
        </TableRow>
        {isLoading
          ? Array.from(new Array(2)).map((item, key) => (
              <TableRow key={key}>
                <TableCell colSpan="100%">
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))
          : null}
        {data.map((item, key) => (
          <TableRow key={key}>
            <TableCell>{item.id}</TableCell>
            <TableCell>
              <Link
                className={classes.links}
                to={`/feemg/escola/${item.id_estabelecimento}/${createSlug(
                  item.pessoa.estabelecimento.descricao
                )}/tecnico/${item.pessoa.id}/${createSlug(
                  item.pessoa.nome_completo
                )}`}
              >
                <Box className="card-atleta">
                  <Avatar
                    src={`${url}/_uploads/fotoPessoa/${item.pessoa.foto}`}
                    alt={item.pessoa.nome_completo}
                  />
                  {item.pessoa.nome_completo}
                </Box>
              </Link>
            </TableCell>
            <TableCell colSpan="100%">{item.funcao.descricao}</TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    );
  }

  return <></>;
}

export default TableStaff;
