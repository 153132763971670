import { Card, CardContent, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import useStyles from "../style";
import { useQuery } from "@tanstack/react-query";
import { fetchAtletasDestaque } from "../hooks/getAtletasDestaque";
import AtletasDestaque from "../sections/AtletasDestaque";

function Atrilheiros({ evento }) {
  const classes = useStyles();
  const { data, isSuccess, isError } = useQuery(
    ["AtletasDestaque"],
    ({ pageParam = { evento } }) => fetchAtletasDestaque(pageParam)
  );
  return (
    <Card>
      <CardContent style={{ paddingBottom: 0 }}>
        <Typography
          className={classes.heading}
          variant="h6"
          color="primary"
          gutterBottom
        >
          {evento.id_esporte === 5 ? "Cestinhas" : "Artilheiros"}
        </Typography>
        {isError ? (
          <Alert severity="error" style={{ margin: "10px 0" }}>
            {`Não foram encontrados ${
              evento.id_esporte === 5 ? "cestinhas" : "artilheiros"
            } para este evento.`}
          </Alert>
        ) : null}
      </CardContent>
      {isSuccess ? (
        <AtletasDestaque artilheiros={data} id_esporte={evento.id_esporte} />
      ) : null}
    </Card>
  );
}

export default Atrilheiros;
