import React, { useEffect, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';

//material-ui
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { PillButtonSuccess } from '../../../components/Styles';
import TablePessoa from '../../../components/TablePessoa';

//api
import { gestaoApi } from '../../../services/api';

//components
import MetaTags from 'react-meta-tags';
import PageTitle from '../../../components/PageTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '40px',
        paddingBottom: '40px',
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 100,
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.up('lg')]: {
            flexWrap: 'unset',
            '& a': {
                marginTop: 0
            }
        }
    },
    errorContainer: {
        paddingTop: '1rem',
        paddingBottom: '4rem',
    },
}));


export default function Arbitros(params) {
    const classes = useStyles();
    const page = params.page;
    const queryParam = params.searchTerm;
    const [searchTerm, setSearchTerm] = useState(queryParam);
    const [pessoa, setPessoa] = useState([])
    const [totalPages, setTotalPages] = useState([])
    const [totalResults, setTotalResults] = useState(1)

    useEffect(() => {
        async function getPessoa() {
            await gestaoApi.get('pessoa', {
                params: {
                    id_tipo: 31,
                    id_estabelecimento: 26,
                    nome_completo: queryParam,
                    sort: 'nome_completo',
                    flag_del: 0,
                    page: page
                },
            }).then((response) => {
                setPessoa(response.data.items);
                setTotalPages(response.data._meta.pageCount);
                setTotalResults(response.data._meta.totalCount)
                executeScroll()
            });
        }
        getPessoa()
    }, [page, queryParam])


    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: 0 });

    let history = useHistory();
    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (searchTerm !== "") {
                history.push(`/arbitragem/representantes-cadastrados?q=${searchTerm}`);
            } else {
                history.push("/arbitragem/representantes-cadastrados");
            }
            executeScroll()
        }
    }

    return (
        <div>
            <MetaTags>
                <title>Representantes Cadastrados - Arbitragem - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="Arbitragem" pageTitle="Representantes Cadastrados" />
            <Paper className={classes.root} square>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.formControl}>
                                <div className={classes.inputGroup}>
                                    <TextField label="Pesquisar" size="small" style={{ marginRight: '.4rem' }} fullWidth={true} value={searchTerm} onKeyDown={(e) => handleSearchKeyDown(e)} onChange={(e) => setSearchTerm(e.target.value)} variant="outlined" />
                                    <div>
                                        {!searchTerm ?
                                            <Link component={PillButtonSuccess} className={classes.searchButton} to="/arbitragem/representantes-cadastrados" variant="contained" disableElevation>Pesquisar</Link>
                                            :
                                            <Link component={PillButtonSuccess} className={classes.searchButton} to={`/arbitragem/representantes-cadastrados${searchTerm !== '' || searchTerm !== null ? `?q=${searchTerm}` : ''}`} variant="contained" disableElevation>Pesquisar</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    {totalResults < 1 ?
                        <div className={classes.errorContainer}>
                            <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
                        </div>
                        :
                        <TablePessoa pessoa={pessoa} tipo="representante" hasModalidade={false} />
                    }
                    {totalPages > 1 ?
                        <div className={classes.paginationContainer}>
                            <Route>
                                {({ location }) => {
                                    const query = new URLSearchParams(location.search);
                                    const page = parseInt(query.get('p') || '1', 10);
                                    return (
                                        <Pagination
                                            page={page}
                                            count={totalPages}
                                            renderItem={(item) => {
                                                if (!queryParam) {
                                                    return (
                                                        <PaginationItem
                                                            component={Link}
                                                            to={`/arbitragem/representantes-cadastrados${item.page === 1 ? '' : `?p=${item.page}`}`}
                                                            {...item}
                                                        />
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <PaginationItem
                                                            component={Link}
                                                            to={`/arbitragem/representantes-cadastrados?q=${queryParam}${item.page === 1 ? '' : `&p=${item.page}`}`}
                                                            {...item}
                                                        />
                                                    )
                                                }
                                            }
                                            }
                                        />
                                    );
                                }}
                            </Route>
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                    }
                </Container>
            </Paper>
        </div>
    )
}