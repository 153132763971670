//material ui
import {
    Menu,
    MenuItem,
    Accordion,
    AccordionSummary,
} from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';

export const StyledMenu = withStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0
    },
}))((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        autoFocus={false}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'start'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'start'
        }}
        {...props}
    />
));

export const StyledMenuItem = withStyles((theme) => ({
    root: {
        color: '#fff',
        // textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '27px',
        fontFamily: "'Nunito Sans', sans-serif",
        '&:hover, &:focus': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            outline: 'none'
        },
    },
}))(MenuItem);

export const NavAccordion = withStyles((theme) => ({
    root: {
        borderRadius: '0 !important',
        width: '100%',
        // background: 'transparent',
        // color: theme.palette.primary.contrastText
    },
}))(Accordion);

export const NavAccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 50,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        margin: '8px 0',
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(AccordionSummary);

export const useStyles = makeStyles((theme) => ({
    appbar: {
        background: 'white',
        color: 'black',
    }, 
    appbarScrolled: {
        background: 'white',
        color: 'black',
        marginBottom: 60
    },
    toolbar: {
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        transition: 'all 0.3s ease-out',
        transform: 'translateY(0)',
    },
    topHeader: {
        background: theme.palette.primary.main,
        color: '#fff',
    },

    headerText: {
        textAlign: 'right',
        padding: '5px 0',
        fontFamily: '"Montserrat","Roboto","Nunito Sans", sans-serif',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& span': {
            display: 'none'
        },
        '& svg': {
            marginRight: 5,
            verticalAlign: 'bottom',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            '& span': {
                display: 'inline-block'
            },
        },
    },
    textFederacao: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            fontSize: 14,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
        }
    },
    headerLink: {
        display: 'inline-block',
        margin: '10px 6px',
        fontSize: '12px',
        color: '#fff',
        textDecoration: 'none',
        '& :hover': {
            textDecoration: 'none'
        },
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        }
    },
    root: {
        flexGrow: 1,
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },

    logo: {
        maxWidth: '100%',
        maxHeight: 80,
        [theme.breakpoints.up('sm')]: {
            maxHeight: 110,
        },
    },
    logoWraper: {
        padding: 0,
        '& img': {
            transition: 'all 250ms ease-in-out',
            maxHeight: 65,
            [theme.breakpoints.up('sm')]: {
                maxHeight: 80
            },
            [theme.breakpoints.up('lg')]: {
                maxHeight: 100
            },

        }
    },
    logoWraperScrolled: {
        padding: 0,
        '& img': {
            transition: 'all 250ms ease-in-out',
            maxHeight: 65,
        }
    },
    headerWraper: {
        width: '100%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
    },
    navbar: {
        padding: 0,
        color: '#000',
        boxShadow: '0px -4px 70px 10px rgba(0, 0, 0, 0.15)',
        minHeight: 40,
        [theme.breakpoints.down('md')]: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        },
    },
    navWraper: {
        width: '100%',
        margin: 'auto',
        display: 'flex',
        // textTransform: 'uppercase',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '90%',
        },
    },
    navItem: {
        padding: '15px 0 15px 13px',
        textTransform: 'uppercase',
        color: '#000',
        cursor: 'pointer'
    },
    navMenu: {
        boxShadow: 'none'
    },
    headerActions: {
        position: 'relative',
    },
    headerActionsCollapsed: {
        height: 0,
        display: 'none'
    },
    headerLinkContainer: {
        textAlign: 'right',
        [theme.breakpoints.up('lg')]: {
            textAlign: 'left',
            marginBottom: 15,
            marginTop: 15,
        }
    },

    heading: {
        fontWeight: 500,
    },
    listButton: {
        background: '#f1f1f1',
        padding: 0,
    },
    listItem: {
        color: '#000',
        textTransform: 'uppercase',
        borderTop: '1px solid #c9c9c9',
        '& span': {
            color: '#000 !important',
            fontWeight: 500,
        },

    },
    listItemNested: {
        background: "#e2e2e2",
        paddingLeft: 40,
        borderTop: '1px solid #c9c9c9',
        '& span': {
            color: '#000 !important'
        },
    },
    navLink: {
        color: "#2d2d2d",
        cursor: 'pointer',
        textDecoration: 'none',
        fontWeight: 800,
        fontSize: '.9rem',
        padding: '10px 5px',
        fontFamily: "'Nunito Sans', sans-serif",
        textTransform: 'none',
        minWidth: 'unset',
        '&:hover, &:active ': {
            textDecoration: 'none',

        }
    }
}));