import React from 'react'

import { Link } from 'react-router-dom'

//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';


//components
import slugNormalize from '../../util/slugNormalize';
import Capitalize from '../../util/Capitalize';
// import { getAno } from '../../util/formatString';

const useStyles = makeStyles((theme) => ({
    tableHead: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        '& .MuiTableCell-root': {
            fontSize: '1.1rem',
            fontWeight: 600,
            paddingBottom: '.5rem'
        }
    },
    imgContainer: {
        borderRadius: '50%',
        overflow: 'hidden',
        height: '50px',
        width: '50px',
        textAlign: 'center',
        boxShadow: '0px 0px 4px 1px #ddd',
        border: '2px solid white',
        '& img': {
            height: '50px'
        }
    },
    linkPessoa: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover ': {
            color: theme.palette.primary.main
        }
    },
    avatarContainer: {
        display: 'flex',
        flexFlow: 'row'
    },
    modalidadesTd: {
        maxWidth: '250px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }

}));

export default function TablePessoa(params) {
    const classes = useStyles();
    const { pessoa, tipo, hasModalidade } = params;


    const returnModalidade = (modalidades) => {

        if (modalidades) {
            var arrModalidade = modalidades.map((item, key) => {
                return (Capitalize(item.modalidade.descricao))
            })

            return arrModalidade.join(', ')
        } else {
            return ''
        }
    }

    return (
        <TableContainer component={Paper} elevation={1}>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell style={{ width: '50px' }}>Registro</TableCell>
                        <TableCell style={{ minWidth: '250px' }}>Nome</TableCell>
                        {hasModalidade ?
                            <TableCell align="center" style={{ maxWidth: '350px' }}>Modalidade</TableCell> :
                            <React.Fragment></React.Fragment>
                        }

                        <TableCell align="center">Gênero</TableCell>
                        {/* <TableCell align="center">Nasc.</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pessoa.map((item, key) => (
                        <TableRow key={key}>
                            <TableCell component="th" scope="row">
                                {item.registro}
                            </TableCell>
                            <TableCell component="td">
                                <Grid container alignItems="center" spacing={2} className={classes.avatarContainer}>
                                    <Grid item>
                                        <Avatar src={`https://sgefeemg.bigmidia.com/_uploads/fotoPessoa/${item.foto}`} alt={item.nome_completo} />
                                    </Grid>
                                    <Grid item>
                                        {tipo === "arbitro" ?
                                            <Link to={`/arbitragem/arbitro/${item.id}/${slugNormalize(item.nome_completo)}`} className={classes.linkPessoa}>
                                                <Typography variant="subtitle2" gutterBottom>{item.nome_completo}</Typography>
                                            </Link>
                                            :
                                            <Link to={`/arbitragem/representante/${item.id}/${slugNormalize(item.nome_completo)}`} className={classes.linkPessoa}>
                                                <Typography variant="subtitle2" gutterBottom>{item.nome_completo}</Typography>
                                            </Link>
                                        }

                                    </Grid>
                                </Grid>
                            </TableCell>
                            {hasModalidade ?
                                <TableCell component="td" align="center" className={classes.modalidadesTd}>
                                    {returnModalidade(item.modalidades)}
                                </TableCell>
                                :
                                <React.Fragment></React.Fragment>
                            }
                            <TableCell component="td" align="center">
                                {item.sexo === 'M' ? 'Masculino' : 'Feminino'}
                            </TableCell>
                            {/* <TableCell component="td" align="center">
                                {getAno(item.data_nascimento)}
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
