import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& table": {
      "& td, & th": {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: "0.7rem",
      },
      "& thead": {
        "& th": {
          fontWeight: "bold",
          textTransform: "uppercase",
        },
      },
    },
    "& .atletasCard ": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    "& .atletasInfo ": {
      marginLeft: "5px",
      display: "flex",
      flexDirection: "column",
    },
    "& .atletaClube ": {
      fontSize: "0.8em",
      padding: 0,
      margin: 0,
      color: "gray",
    },
    "& .atletaNome ": {
      padding: 0,
      margin: 0,
    },
    "& .tdPontos ": {
      textAlign: "center",
    },
  },
  fotoAtleta: {
    marginRight: theme.spacing(1),
    height: 26,
    width: 26,
  },
  // "& table ": {
  //   borderCollapse: "collapse",
  // },
  // "& th": {
  //   textTransform: "uppercase",
  //   width: " 100%",
  //   padding: "10px",
  // },
  //     "& thead th": {
  //     borderBottom:
  // },
  // "& table": {
  //   width: "100%",
  // },
  // "& .thAtleta ": {
  //   textAlign: "left",
  // },

  // "& .atletaTr ": {
  //   borderBottom: "1px solid #e3e3e3",
  // },
}));

export default useStyles;
