import React, { useEffect, useState } from 'react';
 
// - api
import { governancaApi } from '../../../services/api';

//Accordion
import AccordionPage from '../../Generic/AccordionPage';

 
function CodigoEtica() { 
    const [orgao, setOrgao] = useState([])

    useEffect(() => {
        async function getOrgao() {
            await governancaApi.get('orgao', {
                params: {
                    id_menu: 3
                },
            }).then((response) => {
                setOrgao(response.data.items)
            });
        }
        getOrgao()
    }, []) 

    return (
        <AccordionPage menuTitle="FEEMG" pageTitle="Código de Ética" orgao={orgao} />
    )
}

export default CodigoEtica;