import LabelRender from "./LabelRender"
import { itemWidth, organogramaItem } from "./styles"

export const containerWidth = 1280

export function OrganogramaNodes({ handleClick, handleClickOpenDialog }) {

  return [
    {
      id: "presidente",
      type: "input",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              cargo: "Presidente",
              nome: "Éverson Ciccarini",
              foto: "/images/organograma/fotos/everson.png",
              registroProfissional: "",
              email: "feemg@feemg.com.br",
            }} />
        ),
      },
      position: {
        x: containerWidth / 2,
        y: 5
      },
    },
    {
      id: "vice-1",
      style: { ...organogramaItem, width: itemWidth + 90 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              cargo: "Primeiro Vice-presidente",
              nome: "Prof. Luiz Henrique Taveira Cabral",
              foto: "/images/organograma/fotos/luiztaveira.jpg",
              registroProfissional: "CREF6 Nº 000.362-G/MG",
              email: "vicepresidente@feemg.com.br"
            }}
            style={{ width: itemWidth + 90 }}
          />
        ),
      },
      position: {
        x: containerWidth / 4,
        y: 100
      }
    },
    {
      id: "vice-2",
      style: { ...organogramaItem, width: itemWidth + 90 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              cargo: "Segundo Vice-presidente",
              nome: "Prof. João Batista da Silva Coutinho",
              foto: "/images/organograma/fotos/joaobatista.jpg",
              registroProfissional: "",
              email: "vicepresidente1@feemg.com.br"
            }}
            style={{ width: itemWidth + 90 }}
          />
        ),
      },
      position: {
        x: containerWidth * 2 / 3,
        y: 100
      },
    },
    {
      id: "secretaria",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Secretaria" />
        ),
      },
      position: {
        x: 20,
        y: 200
      },
    },
    {
      id: "eduardo-cicarini",
      sourcePosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              cargo: "Auxiliar Técnico",
              nome: "Eduardo Yankous Cicarini",
              foto: "/images/organograma/fotos/eduardo.jpg",
              registroProfissional: "",
              email: "carteira@feemg.com.br"
            }} />
        ),
      },
      position: { x: 20, y: 270 },
    },
    {
      id: "cicero-romao",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              cargo: "Auxiliar de Escritório",
              nome: "Cícero Romão Batista de Souza",
              foto: "/images/organograma/fotos/cicero.jpeg",
              registroProfissional: "",
              email: "",
            }} />
        ),
      },
      position: { x: 20, y: 330 },
    },
    {
      id: "miguel-victor",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Miguel Victor Nascimento Gonçalves",
              cargo: "Auxiliar Administrativo III",
              foto: "/images/organograma/fotos/Miguel.jpeg",
              email: "midia@feemg.com.br",
              registroProfissional: "",
            }} />
        ),
      },
      position: { x: 20, y: 400 },
    },
    {
      id: "amanda-carla",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Amanda Carla Ramos dos Santos",
              cargo: "Auxiliar Administrativo IV",
              foto: "/images/organograma/fotos/Amanda.jpeg",
              email: "protocolo@feemg.com.br",
              registroProfissional: "",
            }} />
        ),
      },
      position: { x: 20, y: 480 },
    },
    {
      id: "diretoria-financeira",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label={"Diretoria Financeira"} />
        ),
      },
      position: {
        x: (itemWidth) * 1.35,
        y: 200
      },
    },
    {
      id: "luiz-henrique-antunes",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Luiz Henrique Antunes",
              cargo: "Auxiliar Técnico I ",
              foto: "/images/organograma/fotos/luizHenrique.jpeg",
              email: "registro@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 135, y: 270 },
    },
    {
      id: "alexandre-gabriel-goncalves",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Alexandre Gabriel Gonçalves ",
              cargo: "Analista Administrativo Financeiro",
              foto: "/images/organograma/fotos/alexandre.jpeg",
              email: "financeiro@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 135, y: 330 },
    },
    {
      id: "fernanda-julia-moreira",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "contas@feemg.com.br",
              cargo: "Assistente Financeira ",
              nome: "Fernanda Júlia Moreira Antunes ",
              foto: "/images/organograma/fotos/fernanda.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 135, y: 420 },
    },
    {
      id: "geraldo-eustaquio-pereira",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "",
              cargo: "Auxiliar Financeiro ",
              nome: "Geraldo Eustáquio Pereira",
              foto: "/images/organograma/fotos/geraldo.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 135, y: 490 },
    },
    {
      id: "lucas-rafael",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Lucas Rafael Gonçalves ",
              cargo: "Auxiliar Administrativo III",
              foto: "/images/organograma/fotos/lucas.jpeg",
              email: "secretaria@feemg.com.br",
              registroProfissional: ""
            }} />
        ),
      },
      position: { x: 135, y: 560 },
    },
    {
      id: "camila-maria",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Camila Maria Luciano Alves",
              cargo: "Assistente de Serviço de Contabilidade",
              foto: "/images/organograma/fotos/camila.jpeg",
              email: "contabilidade@feemg.com.br",
              registroProfissional: ""
            }} />
        ),
      },
      position: { x: 135, y: 630 },
    },
    {
      id: "diretoria-tecnica",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Diretoria Técnica" />
        ),
      },
      position: {
        x: (itemWidth) * 3.65,
        y: 200
      },
    },
    {
      id: "prof-antonio-geraldo",
      style: { ...organogramaItem, width: 150 },
      data: {
        label: (
          <LabelRender style={{ width: 150 }}
            handleClick={handleClick}
            pessoa={{
              nome: "Prof. Antônio Geraldo de Campos Junior",
              cargo: "Coordenador-Geral",
              foto: "/images/organograma/fotos/antonioGeraldo.jpeg",
              email: "coordenacaogeral@feemg.com.br",
              registroProfissional: "CREF6 Nº 022.433-G/MG"
            }} />),
      },
      position: { x: 340, y: 270 },
    },
    {
      id: "prof-welington",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Prof. Welington Cattete de Athayde",
              cargo: "Coordenador Técnico",
              foto: "/images/organograma/fotos/wellingtonCattete.jpeg",
              email: "coordenacaotecnica@feemg.com.br",
              registroProfissional: "CREF6 Nº 006.349-G/MG"
            }} />),
      },
      position: { x: 250, y: 360 },
    },
    {
      id: "prof-guilherme-yankous",
      style: organogramaItem,
      sourcePosition: "left",
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Prof. Guilherme Yankous Cicarini",
              cargo: "Coordenador Técnico-Geral",
              foto: "/images/organograma/fotos/guilherme.jpeg",
              email: "coordenacaotecnicageral@feemg.com.br",
              registroProfissional: "CREF6 Nº 018.269-G/MG"
            }} />),
      },
      position: { x: 365, y: 360 },
    },
    {
      id: "priscila-silva",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Priscila Silva",
              cargo: "Assistente Técnica",
              foto: "/images/organograma/fotos/priscila.jpeg",
              email: "assistentetecnico.jemg@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 475, y: 405 },
    },
    {
      id: "prof-hugo",
      targetPosition: "left",
      sourcePosition: "right",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Prof. Hugo Rainner de Lima Santos",
              cargo: "Agente Técnico",
              foto: "/images/organograma/fotos/Hugo.jpeg",
              email: "coordenacaocentro.jemg@feemg.com.br",
              registroProfissional: "CREF6 Nº 021.865-G/MG"
            }} />),
      },
      position: { x: 365, y: 460 },
    },
    {
      id: "prof-paloma",
      targetPosition: "left",
      sourcePosition: "right",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Profª. Paloma Francine Gino dos Reis",
              cargo: "Agente Técnica",
              foto: "/images/organograma/fotos/paloma.jpeg",
              email: "coordenacaozonadamata.jemg@feemg.com.br",
              registroProfissional: "CREF6 Nº 043.898-G/MG"
            }} />),
      },
      position: { x: 365, y: 530 },
    },
    {
      id: "prof-albert",
      targetPosition: "left",
      sourcePosition: "right",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Prof. Albert Fernando Damasceno",
              cargo: "Agente Técnico",
              foto: "/images/organograma/fotos/albert.jpeg",
              email: "coordenacaonorte.jemg@feemg.com.br",
              registroProfissional: "CREF6 Nº 015.821-G/MG"
            }} />),
      },
      position: { x: 365, y: 600 },
    },
    {
      id: "prof-mirciara",
      targetPosition: "left",
      sourcePosition: "right",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Profª. Mirciara Cristina Santos",
              cargo: "Agente Técnica",
              foto: "/images/organograma/fotos/Mirciara.jpeg",
              email: "coordenacaosul.jemg@feemg.com.br",
              registroProfissional: "CREF6 Nº 014.437-G/MG"
            }} />),
      },
      position: { x: 365, y: 670 },
    },
    {
      id: "prof-giovanna",
      targetPosition: "left",
      sourcePosition: "right",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Profª. Giovanna Rodrigues Silva",
              cargo: "Agente Técnica",
              foto: "/images/organograma/fotos/giovanna.jpeg",
              email: "coordenacaovaledoaco.jemg@feemg.com.br",
              registroProfissional: "CREF6 Nº 038.356-G/MG"
            }} />),
      },
      position: { x: 365, y: 730 },
    },
    {
      id: "prof-vania",
      targetPosition: "left",
      sourcePosition: "right",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "coordenacaotriangulo.jemg@feemg.com.br",
              cargo: "Agente Técnica",
              nome: "Profª. Vânia Monteiro Alvim",
              foto: "/images/organograma/fotos/vania.jpeg",
              registroProfissional: "CREF6 Nº 015.820-G/MG"
            }} />),
      },
      position: { x: 365, y: 790 },
    },
    {
      id: "larissa-oliveira-ferreira",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Larissa Oliveira Ferreira",
              cargo: "Assistente Técnica",
              foto: "/images/organograma/fotos/larissa-oliveira.jpeg",
              email: "assistentecz.jemg@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 490, y: 495 },
    },
    {
      id: "anderson-tadeu",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Anderson Tadeu Máximo dos Santos",
              cargo: "Assistente Técnico",
              foto: "/images/organograma/fotos/Anderson.jpeg",
              email: "assistentens.jemg@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 490, y: 625 },
    },
    {
      id: "gean-morais",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Gean Morais dos Santos",
              cargo: "Assistente Técnico",
              foto: "/images/organograma/fotos/Gean.jpeg",
              email: "assistentevt.jemg@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 490, y: 755 },
    },
    {
      id: "prof-anne-carolina",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "departamentotecnico@feemg.com.br",
              cargo: "Supervisora Técnica",
              nome: "Profª. Anne Carolina dos Santos Vieira ",
              foto: "/images/organograma/fotos/anne.jpeg",
              registroProfissional: "CREF6 Nº 015.108-G/MG"
            }} />),
      },
      position: { x: 590, y: 360 },
    },
    {
      id: "prof-tamara",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "dptotecnico@feemg.com.br",
              cargo: "Agente Técnica I",
              nome: "Profª. Tamara Menezes Lelles",
              foto: "/images/organograma/fotos/tamara.jpeg",
              registroProfissional: "CREF6 Nº 044.326-G/MG"
            }} />),
      },
      position: { x: 650, y: 520 },
    },
    {
      id: "joao-victor",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "tecnico@feemg.com.br",
              cargo: "Assistente Técnico",
              nome: "João Victor Arrudas Albuquerque dos Santos",
              foto: "/images/organograma/fotos/joaoVictor.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 650, y: 580 },
    },
    {
      id: "rosana-cassia-martins",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              label: "Rosana de Cássia Martins Souza",
              email: "relacoespublicas@feemg.com.br",
              cargo: "Relações Institucionais",
              nome: "Rosana de Cássia Martins Souza",
              foto: "/images/organograma/fotos/rosana.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 560, y: 270 },
    },
    {
      id: "dr-paulo-sergio",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "juridico@feemg.com.br",
              cargo: "Assessoria Jurídica",
              nome: "Dr. Paulo Sérgio de Oliveira",
              foto: "/images/organograma/fotos/Paulo Sérgio.jpeg",
              registroProfissional: "OAB/MG 52.540"
            }} />),
      },
      position: { x: 700, y: 270 },
    },
    {
      id: "daniel-toscanini",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Daniel Toscanini Borja Godinho",
              cargo: "Auxiliar Jurídico",
              foto: "/images/organograma/fotos/Daniel Toscanini.png",
              email: "secretaria.tjd@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 700, y: 350 },
    },
    {
      id: "marcus-vinicius",

      sourcePosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "comunicacao@feemg.com.br",
              cargo: "Coordenador de Comunicação",
              nome: "Marcus Vinícius Cicarini",
              foto: "/images/organograma/fotos/Marcus Vinicius Cicarini.jpeg",
              registroProfissional: "MTB 5106/MG"
            }} />),
      },
      position: { x: 810, y: 270 },
    },
    {
      id: "marcos-vinicius",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "midias.jemg@feemg.com.br",
              cargo: "Analista de Midias Sociais",
              nome: "Marcos Vinícius Silva Botelho",
              foto: "/images/organograma/fotos/Marcus Vinicius Botelho.jpeg",
              registroProfissional: "MTB 21219/MG"
            }} />),
      },
      position: { x: 810, y: 350 },
    },
    {
      id: "rafaela-rodrigues",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Rafaela Rodrigues da Costa e Silva",
              cargo: "Assessora de Mídias Sociais",
              foto: "/images/organograma/fotos/Rafaela.jpeg",
              email: "midia@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 810, y: 430 },
    },
    {
      id: "prof-gustavo",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionalbh@feemg.com.br",
              cargo: "Belo Horizonte",
              nome: "Prof. Gustavo Lara Barbosa",
              foto: "/images/organograma/fotos/Gustavo Lara.jpg",
              registroProfissional: "CREF6 Nº 004.732-G/MG"
            }} />),
      },
      position: { x: 935, y: 270 },
    },
    {
      id: "prof-claudio",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionalmetropolitano@feemg.com.br",
              cargo: "Metropolitano",
              nome: "Prof. Cláudio Olívio Vilela Lima",
              foto: "/images/organograma/fotos/Claudio Olivio.jpeg",
              registroProfissional: "CREF6 Nº 004.971-G/MG"
            }} />),
      },
      position: { x: 935, y: 350 },
    },
    {
      id: "prof-pedro-emilio",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionalcentro@feemg.com.br",
              cargo: "Centro",
              nome: "Prof. Pedro Emílio Drumond Moreira",
              foto: "/images/organograma/fotos/Pedro.jpeg",
              registroProfissional: "CREF6 Nº 031.924-G/MG"
            }} />),
      },
      position: { x: 935, y: 410 },
    },
    {
      id: "nilson-silva",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionalnorte@feemg.com.br",
              cargo: "Norte",
              nome: "Nilson Silva Filho",
              foto: "/images/organograma/fotos/Nilson.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 935, y: 470 },
    },
    {
      id: "prof-marcio-aurelio",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionalsul@feemg.com.br",
              cargo: "Sul",
              nome: "Prof. Márcio Aurélio Messias Franco",
              foto: "/images/organograma/fotos/Marcio Aurelio.jpg",
              registroProfissional: "CREF6 Nº 000.057-G/MG"
            }} />),
      },
      position: { x: 935, y: 520 },
    },
    {
      id: "prof-polidoro",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionaltriangulo@feemg.com.br",
              cargo: "Triângulo",
              nome: "Prof. Polidoro Camilo de Sousa",
              foto: "/images/organograma/fotos/Polidoro.jpg",
              registroProfissional: "CREF6 Nº 033.670-G/MG"
            }} />),
      },
      position: { x: 935, y: 590 },
    },
    {
      id: "prof-nestor",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionalvaledoaco@feemg.com.br",
              cargo: "Vale do Aço",
              nome: "Prof. Nestor Moreira Fontes",
              foto: "/images/organograma/fotos/Nestor.jpg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 935, y: 650 },
    },
    {
      id: "adilson-jose",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "regionalzonadamata@feemg.com.br",
              cargo: "Zona da Mata",
              nome: "Adilson José Mattos",
              foto: "/images/organograma/fotos/Adilson José.jpg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 935, y: 710 },
    },
    {
      id: "rosana-cassia-martins-ouvidoria",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "",
              cargo: "Ouvidoria",
              nome: "Rosana de Cássia Martins Souza",
              foto: "/images/organograma/fotos/rosana.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 1060, y: 270 },
    },
    {
      id: "prof-glenda",
      sourcePosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "gestaodeprojetos@feemg.com.br",
              cargo: "Gestora de Projetos ",
              nome: "Profª. Glenda Porto Alves",
              foto: "/images/organograma/fotos/Glenda.jpeg",
              registroProfissional: "CREF6 Nº 018.942-G/MG"
            }} />),
      },
      position: { x: 1170, y: 290 },
    },
    {
      id: "prof-wallace",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "compras@feemg.com.br",
              cargo: "Coordenador-Geral de Logística",
              nome: "Prof. Wallace Cattete de Atayde",
              foto: "/images/organograma/fotos/Wallace.jpeg",
              registroProfissional: "CREF6 Nº 001.375-G/MG"
            }} />),
      },
      position: { x: 1170, y: 360 },
    },
    {
      id: "prof-gladston",
      targetPosition: "left",
      sourcePosition: "bottom",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Prof. Gladston Renan de Miranda",
              cargo: "Supervisor-Geral",
              foto: "/images/organograma/fotos/Gladston.jpeg",
              email: "reservas@feemg.com.br",
              registroProfissional: "CREF6 Nº 013.459-G/MG"
            }} />),
      },
      position: { x: 1170, y: 430 },
    },
    {
      id: "adriano-cesar",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "Logistica@feemg.com.br",
              cargo: "Logística",
              nome: "Adriano Cesar Rita",
              foto: "/images/organograma/fotos/Adriano.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 1170, y: 570 },
    },
    {
      id: "arles-leal",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "Logistica@feemg.com.br",
              cargo: "Logística",
              nome: "Arles Leal Souza",
              foto: "/images/organograma/fotos/Arles.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 1170, y: 630 },
    },
    {
      id: "fagner-pablo",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "Logistica@feemg.com.br",
              cargo: "Logística",
              nome: "Fagner Pablo de Souza",
              foto: "/images/organograma/fotos/Fagner.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: 1170, y: 680 },
    },
    {
      id: "sergio-eduardo",
      type: "output",
      targetPosition: "left",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "logistica@feemg.com.br",
              cargo: "Logística",
              nome: "Sérgio Eduardo Lobo",
              foto: "/images/organograma/fotos/Sérgio Eduardo Lobo.png",
              registroProfissional: ""
            }} />),
      },
      position: { x: 1170, y: 740 },
    },
    {
      id: "leonardo-frank",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              nome: "Leonardo Frank Costa Benedito",
              cargo: "Assistente Técnico",
              foto: "/images/organograma/fotos/leonardo.jpeg",
              email: "alimentacao@feemg.com.br",
              registroProfissional: ""
            }} />),
      },
      position: { x: 1170, y: 490 },
    },
    {
      id: "rel-institucionais",
      style: { ...organogramaItem, width: itemWidth + 20 },
      data: {
        label: (
          <LabelRender label="Relações Institucionais" style={{ width: itemWidth + 20 }} />
        ),
      },
      position: {
        x: (itemWidth - 10 + itemWidth / 1.2 + 10) * 3,
        y: 200
      },
    },
    {
      id: "assesoria-juridica",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Assessoria Jurídica" />
        ),
      },
      position: {
        x: containerWidth - (itemWidth + 10) * 5 - 30,
        y: 200
      },
    },
    {
      id: "comunicacao",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Comunicação" />
        ),
      },
      position: {
        x: containerWidth - (itemWidth + 10) * 4 - 30,
        y: 200
      },
    },
    {
      id: "superintendencia",

      sourcePosition: "left",
      style: { ...organogramaItem, width: itemWidth + 30 },
      data: {
        label: (
          <LabelRender label="Superintendentes Regionais" style={{ width: itemWidth + 30 }} />
        ),
      },
      position: {
        x: containerWidth - 25 - (itemWidth + 10) * 3,
        y: 200
      },
    },
    {
      id: "ouvidoria",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Ouvidoria" />
        ),
      },
      position: {
        x: containerWidth - (itemWidth + 10) * 2,
        y: 200
      },
    },
    {
      id: "gestao-projetos",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Gestão de Projetos | Logística | Suprimentos" />
        ),
      },
      position: {
        x: containerWidth - (itemWidth + 10),
        y: 200
      },
    },
    { id: "presidente->vice-1", source: "presidente", target: "vice-1", type: "smoothstep" },
    { id: "presidente->vice-2", source: "presidente", target: "vice-2", type: "smoothstep" },
    { id: "vice-1->secretaria", source: "vice-1", target: "secretaria", type: "smoothstep" },
    { id: "vice-1->diretoria-tecnica", source: "vice-1", target: "diretoria-tecnica", type: "smoothstep" },
    { id: "vice-1->diretoria-financeira", source: "vice-1", target: "diretoria-financeira", type: "smoothstep" },
    { id: "vice-1->rel-institucionais", source: "vice-1", target: "rel-institucionais", type: "smoothstep" },
    { id: "vice-2->assesoria-juridica", source: "vice-2", target: "assesoria-juridica", type: "smoothstep" },
    { id: "vice-2->comunicacao", source: "vice-2", target: "comunicacao", type: "smoothstep" },
    { id: "vice-2->superintendencia", source: "vice-2", target: "superintendencia", type: "smoothstep" },
    { id: "vice-2->ouvidoria", source: "vice-2", target: "ouvidoria", type: "smoothstep" },
    { id: "vice-2->gestao-projetos", source: "vice-2", target: "gestao-projetos", type: "smoothstep" },
    { id: "secretaria->eduardo-cicarini", source: "secretaria", target: "eduardo-cicarini", type: "smoothstep" },
    { id: "eduardo-cicarini->cicero-romao", source: "eduardo-cicarini", target: "cicero-romao", type: "smoothstep" },
    { id: "eduardo-cicarini->miguel-victor", source: "eduardo-cicarini", target: "miguel-victor", type: "smoothstep" },
    { id: "eduardo-cicarini->miguel-victor", source: "eduardo-cicarini", target: "amanda-carla", type: "smoothstep" },
    { id: "diretoria-financeira->luiz-henrique-antunes", source: "diretoria-financeira", target: "luiz-henrique-antunes", type: "smoothstep" },
    { id: "luiz-henrique-antunes->alexandre-gabriel-goncalves", source: "luiz-henrique-antunes", target: "alexandre-gabriel-goncalves", type: "smoothstep" },
    { id: "alexandre-gabriel-goncalves->fernanda-julia-moreira", source: "alexandre-gabriel-goncalves", target: "fernanda-julia-moreira", type: "smoothstep" },
    { id: "fernanda-julia-moreira->geraldo-eustaquio-pereira", source: "fernanda-julia-moreira", target: "geraldo-eustaquio-pereira", type: "smoothstep" },
    { id: "geraldo-eustaquio-pereira->lucas-rafael", source: "geraldo-eustaquio-pereira", target: "lucas-rafael", type: "smoothstep" },
    { id: "lucas-rafael->camila-maria", source: "lucas-rafael", target: "camila-maria", type: "smoothstep" },
    { id: "diretoria-tecnica->prof-antonio-geraldo", source: "diretoria-tecnica", target: "prof-antonio-geraldo", type: "smoothstep" },
    { id: "prof-antonio-geraldo->prof-welington", source: "prof-antonio-geraldo", target: "prof-welington", type: "smoothstep" },
    { id: "prof-antonio-geraldo->prof-guilherme-yankous", source: "prof-antonio-geraldo", target: "prof-guilherme-yankous", type: "smoothstep" },
    { id: "prof-antonio-geraldo->prof-anne-carolina", source: "prof-antonio-geraldo", target: "prof-anne-carolina", type: "smoothstep" },
    { id: "prof-antonio-geraldo->priscila-silva", source: "prof-antonio-geraldo", target: "priscila-silva", type: "smoothstep" },
    { id: "guilherme-yankous-cicarini->prof-hugo", source: "prof-guilherme-yankous", target: "prof-hugo", type: "smoothstep" },
    { id: "guilherme-yankous-cicarini->prof-paloma", source: "prof-guilherme-yankous", target: "prof-paloma", type: "smoothstep" },
    { id: "guilherme-yankous-cicarini->prof-albert", source: "prof-guilherme-yankous", target: "prof-albert", type: "smoothstep" },
    { id: "guilherme-yankous-cicarini->prof-mirciara", source: "prof-guilherme-yankous", target: "prof-mirciara", type: "smoothstep" },
    { id: "guilherme-yankous-cicarini->prof-giovanna", source: "prof-guilherme-yankous", target: "prof-giovanna", type: "smoothstep" },
    { id: "guilherme-yankous-cicarini->prof-vania", source: "prof-guilherme-yankous", target: "prof-vania", type: "smoothstep" },
    { id: "prof-hugo->larissa-oliveira-ferreira", source: "prof-hugo", target: "larissa-oliveira-ferreira", type: "smoothstep" },
    { id: "prof-paloma->larissa-oliveira-ferreira", source: "prof-paloma", target: "larissa-oliveira-ferreira", type: "smoothstep" },
    { id: "prof-albert->anderson-tadeu", source: "prof-albert", target: "anderson-tadeu", type: "smoothstep" },
    { id: "prof-mirciara->anderson-tadeu", source: "prof-mirciara", target: "anderson-tadeu", type: "smoothstep" },
    { id: "prof-giovanna->gean-morais", source: "prof-giovanna", target: "gean-morais", type: "smoothstep" },
    { id: "prof-vania->gean-morais", source: "prof-vania", target: "gean-morais", type: "smoothstep" },
    { id: "prof-anne-carolina->prof-tamara", source: "prof-anne-carolina", target: "prof-tamara", type: "smoothstep" },
    { id: "prof-anne-carolina->joao-victor", source: "prof-anne-carolina", target: "joao-victor", type: "smoothstep" },
    { id: "rel-institucionais->rosana-cassia-martins", source: "rel-institucionais", target: "rosana-cassia-martins", type: "smoothstep" },
    { id: "assesoria-juridica->dr-paulo-sergio", source: "assesoria-juridica", target: "dr-paulo-sergio", type: "smoothstep" },
    { id: "dr-paulo-sergio->daniel-toscanini", source: "dr-paulo-sergio", target: "daniel-toscanini", type: "smoothstep" },
    { id: "comunicacao->marcus-vinicius", source: "comunicacao", target: "marcus-vinicius", type: "smoothstep" },
    { id: "marcus-vinicius->marcos-vinicius", source: "marcus-vinicius", target: "marcos-vinicius", type: "smoothstep" },
    { id: "marcus-vinicius->rafaela-rodrigues", source: "marcus-vinicius", target: "rafaela-rodrigues", type: "smoothstep" },
    { id: "superintendencia->prof-gustavo", source: "superintendencia", target: "prof-gustavo", type: "smoothstep" },
    { id: "superintendencia->prof-claudio", source: "superintendencia", target: "prof-claudio", type: "smoothstep" },
    { id: "superintendencia->prof-pedro-emilio", source: "superintendencia", target: "prof-pedro-emilio", type: "smoothstep" },
    { id: "superintendencia->nilson-silva", source: "superintendencia", target: "nilson-silva", type: "smoothstep" },
    { id: "superintendencia->prof-marcio-aurelio", source: "superintendencia", target: "prof-marcio-aurelio", type: "smoothstep" },
    { id: "superintendencia->prof-polidoro", source: "superintendencia", target: "prof-polidoro", type: "smoothstep" },
    { id: "superintendencia->prof-nestor", source: "superintendencia", target: "prof-nestor", type: "smoothstep" },
    { id: "superintendencia->adilson-jose", source: "superintendencia", target: "adilson-jose", type: "smoothstep" },
    { id: "ouvidoria->rosana-cassia-martins-ouvidoria", source: "ouvidoria", target: "rosana-cassia-martins-ouvidoria", type: "smoothstep" },
    { id: "gestao-projetos->prof-glenda", source: "gestao-projetos", target: "prof-glenda", type: "smoothstep" },
    { id: "prof-glenda->prof-wallace", source: "prof-glenda", target: "prof-wallace", type: "smoothstep" },
    { id: "prof-glenda->prof-gladston", source: "prof-glenda", target: "prof-gladston", type: "smoothstep" },
    { id: "prof-glenda->adriano-cesar", source: "prof-glenda", target: "adriano-cesar", type: "smoothstep" },
    { id: "prof-glenda->arles-leal", source: "prof-glenda", target: "arles-leal", type: "smoothstep" },
    { id: "prof-glenda->fagner-pablo", source: "prof-glenda", target: "fagner-pablo", type: "smoothstep" },
    { id: "prof-glenda->sergio-eduardo", source: "prof-glenda", target: "sergio-eduardo", type: "smoothstep" },
    { id: "prof-gladston->leonardo-frank", source: "prof-gladston", target: "leonardo-frank", type: "smoothstep" },


    //arbitragem
    {
      id: "coordenadoria-arbitragem",
      type: "input",
      style: { ...organogramaItem, width: itemWidth + 50 },
      data: {
        label: (
          <LabelRender label="Coordenadoria - Geral de Arbitragem" style={{ width: itemWidth + 50 }} />
        ),
      },
      position: {
        x: containerWidth / 2,
        y: 900
      },
    },
    {
      id: "rodrigo-tavares",
      style: { ...organogramaItem, width: itemWidth + 50 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            style={{ width: itemWidth + 50 }}
            pessoa={{
              email: "arbitragem@feemg.com.br",
              cargo: "Coordenador-Geral de Arbitragem",
              nome: "Prof. Rodrigo Tavares Moreira",
              foto: "/images/organograma/fotos/Rodrigo.jpeg",
              registroProfissional: "CREF6 Nº 036.086-G/MG"
            }} />),
      },
      position: { x: containerWidth / 2, y: 965 },
    },
    {
      id: "valeria-marcia",
      style: { ...organogramaItem, width: itemWidth + 50 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            style={{ width: itemWidth + 50 }}
            pessoa={{
              email: "departamentodearbitragem@feemg.com.br",
              cargo: "Assistente de arbitragem",
              nome: "Valéria Márcia de Oliveira ",
              foto: "/images/organograma/fotos/Valéria.jpeg",
              registroProfissional: ""
            }} />),
      },
      position: { x: containerWidth / 2, y: 1050 },
    },
    {
      id: "atletismo",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Atletismo" />
        ),
      },
      position: {
        x: 20,
        y: 1150
      },
    },
    {
      id: "coordenador-atletismo",
      type: "output",
      style: { ...organogramaItem, width: itemWidth + 32 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            style={{ width: itemWidth + 32 }}
            pessoa={{
              email: "atletismo@feemg.com.br",
              cargo: "Coordenador de arbitragem",
              nome: "Prof. João Batista da Silva Coutinho",
              foto: "/images/organograma/fotos/joaobatista.jpg",
              registroProfissional: ""
            }} />),
      },
      position: {
        x: 4,
        y: 1210
      },
    },
    {
      id: "basquete",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Basquete" />
        ),
      },
      position: {
        x: containerWidth / 7,
        y: 1150
      },
    },
    {
      id: "coordenador-basquete",
      type: "output",
      style: { ...organogramaItem, width: itemWidth + 32 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            style={{ width: itemWidth + 32 }}
            pessoa={{
              email: "basquete@feemg.com.br",
              cargo: "Coordenador de arbitragem",
              nome: "Prof. Alexandre Tadeu Máximo dos Santos",
              foto: "/images/organograma/fotos/Alexandre Tadeu.jpg",
              registroProfissional: "CREF6 Nº 037.279-G/MG"
            }} />),
      },
      position: {
        x: (containerWidth - 112) / 7,
        y: 1210
      },
    },
    {
      id: "futsal",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Futsal" />
        ),
      },
      position: {
        x: containerWidth * 2 / 7,
        y: 1150
      },
    },
    {
      id: "coordenador-futsal",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "futsal@feemg.com.br",
              cargo: "Coordenador de arbitragem",
              nome: "Prof. Frederico Gil Rocha",
              foto: "/images/organograma/fotos/Frederico Gil.jpg",
              registroProfissional: ""
            }} />),
      },
      position: {
        x: containerWidth * 2 / 7,
        y: 1210
      },
    },
    {
      id: "handebol",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Handebol" />
        ),
      },
      position: {
        x: containerWidth * 3 / 7,
        y: 1150
      },
    },
    {
      id: "coordenador-handebol",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "handebol@feemg.com.br",
              cargo: "Coordenador de arbitragem",
              nome: "Paulo Roberto Nestor Silva",
              foto: "/images/organograma/fotos/Paulo Roberto Nestor.png",
              registroProfissional: ""
            }} />),
      },
      position: {
        x: containerWidth * 3 / 7,
        y: 1210
      },
    },
    {
      id: "judo",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Judô" />
        ),
      },
      position: {
        x: containerWidth * 4 / 7,
        y: 1150
      },
    },
    {
      id: "coordenador-judo",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            pessoa={{
              email: "judo@feemg.com.br",
              cargo: "Coordenador de arbitragem",
              nome: "Prof. Douglas Correa de Oliveira",
              foto: "/images/organograma/fotos/Douglas Correa.jpg",
              registroProfissional: "CREF6 Nº 009.564-P/MG"
            }} />),
      },
      position: {
        x: containerWidth * 4 / 7,
        y: 1210
      },
    },
    // {
    //   id: "tenis-mesa",
    //   type: "output",
    //   style: organogramaItem,
    //   data: {
    //     label: (
    //       <LabelRender label="Tênis de Mesa" />
    //     ),
    //   },
    //   position: {
    //     x: containerWidth * 6 / 9,
    //     y: 1150
    //   },
    // },
    {
      id: "volei",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Vôlei" />
        ),
      },
      position: {
        x: containerWidth * 5 / 7,
        y: 1150
      },
    },
    {
      id: "coordenador-volei",
      type: "output",
      style: { ...organogramaItem, width: itemWidth + 32 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            style={{ width: itemWidth + 32 }}
            pessoa={{
              email: "volei@feemg.com.br",
              cargo: "Coordenadora de arbitragem",
              nome: "Thais de Freitas Cardoso",
              foto: "/images/organograma/fotos/Thais Freitas Cardoso.jpg",
              //registroProfissional: "CREF6 Nº 036086-G/MG"
            }} />),
      },
      position: {
        x: (containerWidth - 21) * 5 / 7,
        y: 1210
      },
    },
    {
      id: "xadrez",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender label="Xadrez" />
        ),
      },
      position: {
        x: containerWidth * 6 / 7,
        y: 1150
      },
    },
    {
      id: "coordenador-xadrez",
      type: "output",
      style: { ...organogramaItem, width: itemWidth + 12 },
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            style={{ width: itemWidth + 12 }}
            pessoa={{
              email: "xadrez@feemg.com.br",
              cargo: "Coordenador de arbitragem",
              nome: "Prof. Wagner Terenzi de Rezende",
              foto: "/images/organograma/fotos/Wagner Terenzi.jpeg",
              registroProfissional: "CREF6 Nº009605-P/MG"
            }} />),
      },
      position: {
        x: (containerWidth - 7) * 6 / 7,
        y: 1210
      },
    },
    { id: "coordenadoria-arbitragem->rodrigo-tavares", source: "coordenadoria-arbitragem", target: "rodrigo-tavares", type: "smoothstep" },
    { id: "rodrigo-tavares->valeria-marcia", source: "rodrigo-tavares", target: "valeria-marcia", type: "smoothstep" },
    { id: "valeria-marcia->atletismo", source: "valeria-marcia", target: "atletismo", type: "smoothstep" },
    { id: "valeria-marcia->basquete", source: "valeria-marcia", target: "basquete", type: "smoothstep" },
    { id: "valeria-marcia->futsal", source: "valeria-marcia", target: "futsal", type: "smoothstep" },
    { id: "valeria-marcia->handebol", source: "valeria-marcia", target: "handebol", type: "smoothstep" },
    { id: "valeria-marcia->judo", source: "valeria-marcia", target: "judo", type: "smoothstep" },
    { id: "valeria-marcia->tenis-mesa", source: "valeria-marcia", target: "tenis-mesa", type: "smoothstep" },
    { id: "valeria-marcia->volei", source: "valeria-marcia", target: "volei", type: "smoothstep" },
    { id: "valeria-marcia->xadrez", source: "valeria-marcia", target: "xadrez", type: "smoothstep" },
    { id: "atletismo->coordenador-atletismo", source: "atletismo", target: "coordenador-atletismo", type: "smoothstep" },
    { id: "basquete->coordenador-basquete", source: "basquete", target: "coordenador-basquete", type: "smoothstep" },
    { id: "futsal->coordenador-futsal", source: "futsal", target: "coordenador-futsal", type: "smoothstep" },
    { id: "handebol->coordenador-handebol", source: "handebol", target: "coordenador-handebol", type: "smoothstep" },
    { id: "judo->coordenador-judo", source: "judo", target: "coordenador-judo", type: "smoothstep" },
    { id: "volei->coordenador-volei", source: "volei", target: "coordenador-volei", type: "smoothstep" },
    { id: "xadrez->coordenador-xadrez", source: "xadrez", target: "coordenador-xadrez", type: "smoothstep" },

    // conselhos
    {
      id: "conselho-fiscal",
      type: "input",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            label="Conselho Fiscal"
            modalPessoas={{
              label: "Conselho Fiscal",
              pessoas: [
                {
                  nome: "Ionara Thompson Ferreira",
                  cargo: "Titular",
                },
                {
                  nome: "Dr. Orestes Mineiro de Souza Junior",
                  cargo: "Titular",
                }, {
                  nome: "Wellington Ferreira de Oliveira",
                  cargo: "Titular",
                },
                {
                  nome: "Prof. Gustavo Aluísio Santos e Campos",
                  cargo: "Suplente",
                },
                {
                  nome: "Prof. Ivan Cançado e Salles",
                  cargo: "Suplente",
                },
                {
                  nome: "Madson Pianco Alves Pinto",
                  cargo: "Suplente",
                }
              ]
            }}
            openDialog={handleClickOpenDialog} />
        ),
      },
      position: {
        x: containerWidth / 7,
        y: 0
      },
    },
    {
      id: "tribunal-justica",
      type: "input",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            label="Tribunal de Justiça Desportiva"
            modalPessoas={{
              label: "Tribunal de Justiça Desportiva",
              pessoas: [
                { cargo: "Auditor presidente", nome: "Dr. Paulo Roberto Agostini Filho", registro: "OAB / MG 71.949." },
                { cargo: "Auditor vice-presidente", nome: "Dr. Luiz Gustavo Motta", registro: "OAB /MG 58.484." },
                { cargo: "Procurador", nome: "Dr. Murilo Cláudio Coelho", registro: "OAB / MG 39.432" },
                { cargo: "Auditora", nome: "Drª. Vera Lúcia Figueiredo", registro: "OAB / MG 61.735" },
                { cargo: "Auditor", nome: "Dr. Antônio Gomes Lisboa Neto", registro: "OAB /MG 104.049." },
                { cargo: "Auditor", nome: "Dr. Marcílio Cassini da Silva", registro: "OAB /MG 90.195." },
                { cargo: "Auditor", nome: "Dr. Max Wellington Torres Matheus Dias", registro: "OAB /MG 99.120." },
                { cargo: "Auditor", nome: "Dr. Ovimar dos Reis Souto", registro: "OAB /MG 42.833." },
                { cargo: "Auditor", nome: "Dr Reginaldo Luiz Garcia", registro: "OAB / MG 173.336" },
                { cargo: "Auditor", nome: "Dr. Thomas Erick Diniz Kentish", registro: "OAB /MG 42.846." },
                // { cargo: "Secretário", nome: "Alexandre Gabriel Gonçalves", registro: "" },
                { cargo: "Secretário", nome: "Daniel Toscanini Borja Godinho", registro: "" },
              ]
            }}
            openDialog={handleClickOpenDialog} />
        ),
      },
      position: {
        x: containerWidth * 6 / 7,
        y: 0
      },
    },
    {
      id: "comissao-disciplinar",
      type: "output",
      style: organogramaItem,
      data: {
        label: (
          <LabelRender
            handleClick={handleClick}
            label="Comissão Disciplinar"
            modalPessoas={{
              label: "Comissão Disciplinar",
              pessoas: [
                { cargo: "Auditor presidente", nome: "Dr. Paulo Sérgio de Oliveira", registro: "OAB /MG 52.540." },
                { cargo: "Procurador", nome: "Dr. Wesley Márcio de Campos", registro: "OAB /MG 93.842." },
                { cargo: "Auditora", nome: "Drª. Andréia Aparecida de Almeida Brandão", registro: "OAB /MG 158.978." },
                { cargo: "Auditora", nome: "Drª. Tayara Souza Vitor", registro: "OAB /MG 198.123." },
                { cargo: "Auditor", nome: "Dr. Alessandro Mello Pincer", registro: "OAB /MG 129.325." },
                { cargo: "Auditor", nome: "Dr. Victor Leon da Rocha Junior", registro: "OAB /MG 140.831." },
                { cargo: "Secretário", nome: "Daniel Toscani Borja Godinho" }
              ]
            }}
            openDialog={handleClickOpenDialog} />
        ),
      },
      position: {
        x: containerWidth * 6 / 7,
        y: 80
      },
    },
    { id: "tribunal-justica->comissao-disciplinar", source: "tribunal-justica", target: "comissao-disciplinar", type: "smoothstep" },
  ]
}
// export default organogramaNovo
