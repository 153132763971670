import axios from "axios";
import { ApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchAtletasDestaque(props) {
  const { evento } = props;
  const _api = ApiEsporte[evento.id_esporte] || ApiEsporte[1];

  const res = await axios.get(
    `${baseURL}/${_api}/api/evento/estatisticas-atleta-destaque`,
    {
      params: {
        idEvento: evento.id,
        sort: "colocacao",
        pageSize: 10,
      },
    }
  );
  const data = res.data.items;
  return data;
}
