import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react' 
import { useStyles } from './styles'
import TabelaArbitroFutsal from './Resultados/TabelaFutsal'
import TabelaArbitroBasquete from './Resultados/TabelaBasquete'
import TabelaArbitroHand from './Resultados/TableHand'

function HistoricoArbitro({ pessoaId }) {
  const classes = useStyles();
 
  return (
    <Box style={{ paddingTop: 40 }}>
      <Typography component="h4" className={classes.tableTitle}>
        Histórico de partidas
      </Typography>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>EVENTO</TableCell>
              <TableCell>FUNÇÃO</TableCell>
              <TableCell></TableCell>
              <TableCell>DATA</TableCell>
              <TableCell>LOCAL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TabelaArbitroFutsal pessoaId={pessoaId} />
            <TabelaArbitroBasquete pessoaId={pessoaId} />
            <TabelaArbitroHand pessoaId={pessoaId} /> 
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default HistoricoArbitro