export const ApiEsporte = {
  3: "cbfutsal",
  4: "cbhb",
  5: "cbb",
  7: "cbtkd",
  8: "natacao",
  6: "cbv",
  1: "cbfutsal",
};

export const esporteApiEsporte = {
  futsal: "cbfutsal",
  handebol: "cbhb",
  basquete: "cbb",
  taekwondo: "cbtkd",
  natacao: "natacao",
  volei: "cbv", 
  voleibol: "cbv", 
};
