import React, { useEffect, useMemo, useState } from "react";
import { fetchEventoPartidaAtleta } from "../../../../../lib/fetchEventoPartidaAtleta";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Chip, TableCell, TableRow, Typography } from "@material-ui/core";
import moment from "moment";
import { useStyles } from "./styles";
import { gestaoApi } from "../../../../../services/api";
import { filter } from "lodash";

function TabelaBasquete({ alunoId }) {
  const classes = useStyles();
  const [tableItems, setTableItems] = useState([]);
  const { isSuccess, data } = useQuery({
    queryKey: ["eventoPartidaAtleta-cbb", { alunoId }],
    queryFn: ({
      pageParam = {
        apiEsporte: "cbb",
        params: {
          
          expand: "idEvento,partida,casa,visitante,casaEquipe,visitanteEquipe",
          "atleta.id_atleta": alunoId,
          sort: "-created_at",
          forcewhere:"idEvento.flag_del:0",
          forcejoin: "atleta,partida.idEvento"
        },
      },
    }) => fetchEventoPartidaAtleta(pageParam),
  });

  const eventosId = useMemo(() => {
    if (data && data.length) {
      return data.map((item) => item.id_evento);
    }
  }, [data]);

  useEffect(() => {
    if (eventosId && eventosId.length) {
      gestaoApi
        .get("evento", {
          params: {
            arrayIn: `id:${eventosId.join("|")}`,
          },
        })
        .then((response) => {
          if (response.data.items) {
            const eventos = response.data.items;

            const teste = data.map((item) => {
              const _evento = filter(eventos, (o) => o.id === item.id_evento);

              return { ...item, evento: _evento[0] };
            });
            setTableItems(teste);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data, eventosId]);

  if (isSuccess) {
    return (
      <React.Fragment>
        {tableItems && tableItems.length
          ? tableItems.map((item, key) => (
              <TableRow key={key}>
                <TableCell>
                  <Link
                    className={classes.link}
                    to={`/feemg/basquete/${item.id_evento}/evento/partida/${item.id_partida}`}
                  >
                    <Typography>
                      {item.partida.idEvento.descricao || "Visualizar partida"}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Chip label="BASQUETEBOL" className={classes.basquete} />
                </TableCell>
                <TableCell>
                  {item.id_estabelecimento === item.partida.casaEquipe.id_estabelecimento ? <p><strong>{item.partida.casa.sigla}</strong> x {item.partida.visitante.sigla}</p> 
                  : <p>{item.partida.casa.sigla} x <strong>{item.partida.visitante.sigla} </strong></p>}
                </TableCell>
                <TableCell>
                  {moment(item.partida?.data|| item.partida.created_at*1000).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>{item.partida?.local || item.idEvento?.local}</TableCell>
              </TableRow>
            ))
          : null}
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}

export default TabelaBasquete;
