import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchEventoPartidaAtleta(props) {
  const { apiEsporte, params } = props;

  const res = await axios.get(
    `${baseURL}/${apiEsporte}/api/evento-partida-atleta`,
    {
      params: { ...params },
    }
  );
  const data = res.data.items;
  return data;
}
