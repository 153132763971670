import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { gestaoApi } from '../../../services/api';

//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';

//components
import PageTitle from '../../../components/PageTitle';
import Capitalize from '../../../util/Capitalize';
import TablePessoa from '../../../components/TablePessoa';
// import { getAno } from '../../../util/formatString';

import MetaTags from 'react-meta-tags'
import HistoricoArbitro from './historico-arbitro';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '40px',
        fontSize: '1rem'
    },
    fontBold: {
        fontWeight: 600
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    backLink: {
        textDecoration: 'none',
        color: 'inherit',
        fontWeight: 600,
        '&:active, &:hover ': {
            opacity: 0.6
        }
    },
    largeAvatar: {
        width: theme.spacing(12),
        height: theme.spacing(15),
        fontSize: theme.spacing(6)
    }


}));

export default function Arbitro() {
    const classes = useStyles();
    const { pessoaId } = useParams()
    const [arbitro, setArbitro] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pessoaId) {
            async function getArbitro() {
                await gestaoApi.get('pessoa', {
                    params: {
                        id: pessoaId,
                        expand: 'modalidades',
                        id_estabelecimento: 26,
                    },
                }).then((response) => {
                    setArbitro(response.data.items[0])
                    setLoading(false)
                });
            }
            getArbitro()
        }
    }, [pessoaId])

    const [arbitros, setArbitros] = useState([])

    useEffect(() => {
        async function getArbitros() {
            await gestaoApi.get('pessoa', {
                params: {
                    id_tipo: 29,
                    id_estabelecimento: 26,
                    flag_del: 0,
                    expand: 'modalidades',
                    sort: '-created_at'
                },
            }).then((response) => {
                setArbitros(response.data.items)
                setLoading(false)
            });
        }
        getArbitros()
    }, [])

    const returnModalidade = (modalidades) => {

        if (modalidades) {
            var arrModalidade = modalidades.map((item, key) => {
                return (Capitalize(item.modalidade.descricao))
            })

            return arrModalidade.join(', ')
        } else {
            return ''
        }

    }

    if (loading) {
        return (
            <>
                <MetaTags>
                    <title>Arbitro - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="FEEMG" pageTitle="Arbitro" />
                <Paper square elevation={0} className={classes.root}>
                    <div className={classes.spinnerContainer}>
                        <CircularProgress />
                    </div>
                </Paper>
            </>
        )
    } else {
        return (
            <div>
                <MetaTags>
                    <title>{arbitro.nome_completo ? Capitalize(arbitro.nome_completo) : ''} - Arbitro - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                </MetaTags>
                <PageTitle menuTitle="FEEMG" pageTitle={arbitro.nome_completo ? Capitalize(arbitro.nome_completo) : ''} />
                <Paper square elevation={0} className={classes.root}>
                    <Container maxWidth="lg">
                        <div style={{ textAlign: 'right' }}>
                            <Link to="/arbitragem/arbitros-cadastrados" className={classes.backLink}>« Voltar</Link>
                        </div>
                        <Grid container spacing={2} >
                            <Grid item >
                                <Avatar variant="rounded" src={`https://sgefeemg.bigmidia.com/_uploads/fotoPessoa/${arbitro.foto}`} alt={arbitro.nome_completo} className={classes.largeAvatar} />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Typography variant="h4" component="h1" color="primary" className={classes.fontBold} gutterBottom>
                                    {arbitro.nome_completo ? Capitalize(arbitro.nome_completo) : ''}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.fontBold} gutterBottom>
                                    Registro: {arbitro.registro}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.fontBold} gutterBottom>
                                    Modalidades: {returnModalidade(arbitro.modalidades)}
                                </Typography>
                                {/* <Typography variant="subtitle2" className={classes.fontBold} gutterBottom>
                                    Ano nascimento: {getAno(arbitro.data_nascimento)}
                                </Typography> */}
                            </Grid>
                        </Grid>
                        <HistoricoArbitro pessoaId={pessoaId} />
                        <div style={{ padding: '40px 0' }}>
                            <TablePessoa pessoa={arbitros} tipo="arbitro" hasModalidade={true} />
                        </div>
                    </Container>
                </Paper>
            </div>
        )
    }
}
