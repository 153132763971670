import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { fetchArbitragem } from "../../../hooks/getArbitragem";
import { useQuery } from "@tanstack/react-query";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../../util/createSlug";

function TableArbitros({ esporte, idPartida }) {
  const classes = useStyles();
  const { data, isSuccess, isLoading } = useQuery(
    ["Arbitragem"],
    ({ pageParam = { esporte, idPartida } }) => fetchArbitragem(pageParam)

  );

  if (isLoading && !isSuccess) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h6">ÁRBITROS/OFICIAIS</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>OFICIAL</TableCell>
              <TableCell>FUNÇÃO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, key) => (
              <TableRow key={key}>
                <TableCell>
                  <Link className={classes.link} to={`/arbitragem/arbitro/${item.id_pessoa}/${createSlug(item.tb_pessoa.nome_completo)}`}>
                    <Box className="col-pessoa">
                      <Avatar
                        className={classes.fotoAtleta}
                        src={`${process.env.REACT_APP_SGE_URL}/_uploads/fotoPessoa/${item.tb_pessoa.foto}`}
                        alt={item.nome_atleta_evento}
                      />
                      {item.tb_pessoa.nome_completo}
                    </Box>
                  </Link>
                </TableCell>
                <TableCell>{item.funcao.descricao}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default TableArbitros;
