import React from "react";

// import { makeStyles } from '@material-ui/core/styles';

//sections
import CarouselNav from "./sections/CarouselNav";
import Noticias from "./sections/Noticias";
import ClubeVantagens from "./sections/ClubeVantagens";
import AcessoRapido from "./sections/AcessoRapido";
import SocialMidia from "./sections/SocialMidia";
import LinksRedesSociais from "./sections/LinksRedesSociais";

import MetaTags from "react-meta-tags";
// import QuemSomos from "./sections/QuemSomos";
function Home() {
  return (
    <>
      <MetaTags>
        <title>FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
      </MetaTags>
      <CarouselNav />
      <Noticias />
      <ClubeVantagens />
      {/* <QuemSomos /> */}
      <AcessoRapido />
      <SocialMidia />
      <LinksRedesSociais />
    </>
  );
}

export default Home;
