import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import esportesEspecificacoes from "../../../assets/data/esporte/esportesEspecificacoes";
import {
  TabPanel,
  TemplateTab,
  TemplateTabs,
} from "../../../components/TabPanel";
import TableClassificacaoChave from "./TableClassificacaoChave";
import TableClassificacaoFinal from "./TableClassificacaoFinal";
import TableGrupoClubes from "./TableGrupoClubes";

function TableClassificacao({ nomeEsporte, partidasGrupo, loading }) {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const esporteEspecificacoes = esportesEspecificacoes.find(
    (esporte) => esporte.nome === nomeEsporte
  );

  return (
    <div>
      <TemplateTabs
        color="primary"
        variant="fullWidth"
        value={tabIndex}
        onChange={handleChange}
      >
        {loading &&
          Array.from(new Array(2)).map((item, key) => (
            <TemplateTab label={`Chave ${key + 1}`} key={key} />
          ))}
        {!loading &&
          partidasGrupo.map((item, key) => (
            <TemplateTab label={item.descricao} key={key} />
          ))}
      </TemplateTabs>
      {loading &&
        Array.from(new Array(2)).map((item, key) => (
          <TabPanel key={key} value={tabIndex} index={key}>
            <Skeleton height={120} variant="rect" />
          </TabPanel>
        ))}
      {!loading &&
        partidasGrupo.map((item, key) => {
          if (item.descricao === "Classificação Final") {
            return (
              <TabPanel key={key} value={tabIndex} index={key}>
                <TableClassificacaoFinal
                  eventoGrupoClubes={item.eventoGrupoClubes}
                />
              </TabPanel>
            );
          }
          return (
            <TabPanel key={key} value={tabIndex} index={key}>
              <div className="table-responsive">
                {item.eventoClassificacaos &&
                  item.eventoClassificacaos.length > 0 ? (
                  <TableClassificacaoChave
                    eventoClassificacaos={item.eventoClassificacaos}
                    esporteEspecificacoes={esporteEspecificacoes}
                  />
                ) : (
                  <TableGrupoClubes
                    eventoGrupoClubes={item.eventoGrupoClubes}
                    esporteEspecificacoes={esporteEspecificacoes}
                  />
                )}
              </div>
            </TabPanel>
          );
        })}
    </div>
  );
}

export default TableClassificacao;
