import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import MetaTags from "react-meta-tags";
import PageTitle from "../../../components/PageTitle";
import { useParams } from "react-router-dom";
import useEvento from "../hooks/Evento";
import useStyles from "../style";
import AtletasSection from "./sections/Atletas";
import EventoInfo from "../components/EventoInfo";
import HeaderPartida from "./sections/HeaderPartida";
import { TabPanel, a11yProps } from "../../../components/TabPanel";
import PlaybyPlay from "./sections/PlaybyPlay";
import AnexosPartida from "../components/AnexosPartida";
import { useQuery } from "@tanstack/react-query";
import { fetchEventoPartidaPorId } from "../hooks/getEventoPartida";
import { Skeleton } from "@material-ui/lab";

function PartidasPage() {
  const { esporte } = useParams(); 
  const classes = useStyles();
  const theme = useTheme();
  const { idEvento, idPartida } = useParams();
  const { evento, loading } = useEvento({ id: idEvento });
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  const { data, isSuccess, isLoading } = useQuery(
    ["EventoPartidaPorId"],
    ({ pageParam = { idPartida, esporte } }) =>
      fetchEventoPartidaPorId(pageParam)
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <Box>
      <MetaTags>
        <title>
          {evento.descricao ? `${evento.descricao} - ` : null} FEEMG - Federação
          de Esportes Estudantis de Minas Gerais
        </title>
      </MetaTags>
      <PageTitle
        menuTitle="FEEMG"
        pageTitle={evento.descricao}
        breadcrumbs={true}
        breadcrumbsLinks={[
          { url: "", title: evento.descricao, tipe: "goback" },
          { url: "", title: "Partidas", tipe: "text" },
        ]}
      />
      <Paper square className={classes.wrapper}>
        <Box component="section" className={classes.topSection}>
          <Container>
            {evento && esporte && idPartida ? (
              <HeaderPartida
                loading={loading}
                evento={evento}
                esporte={esporte}
                idPartida={idPartida}
              />
            ) : null}
          </Container>
        </Box>

        <Box component="section" className={classes.tabsSection}>
          <Container>
            <Tabs
              orientation="horizontal"
              indicatorColor="primary"
              textColor="primary"
              centered
              value={value}
              onChange={handleChange}
              className={classes.tabs}
            >
              <Tab label="Informações" {...a11yProps(0)} />
              <Tab label="Lance a lance" {...a11yProps(1)} />
            </Tabs>
          </Container>
        </Box>

        <TabPanel value={value} index={0}>
          <Container>
            <Grid
              container
              spacing={3}
              direction={!matchesMD ? "column-reverse" : "row"}
            >
              <Grid item xs={12} md={8}>
                {Boolean(esporte) && evento && idPartida ? (
                  <AtletasSection
                    evento={evento}
                    esporte={esporte}
                    idPartida={idPartida}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} md={4}>
                {isLoading ?
                  <Card style={{ marginBottom: 16 }}>
                    <CardContent style={{ padding: 6 }}>
                      <Skeleton height={140} variant="rect" />
                    </CardContent>
                  </Card>
                  : null}
                {isSuccess && data && data.documentos.length > 0 ?
                  <Card style={{ marginBottom: 16 }}>
                    <AnexosPartida documentos={data.documentos} expanded />
                  </Card>
                  : null}
                {Boolean(esporte) && evento && idPartida ? (
                  <EventoInfo
                    evento={evento}
                    esporte={esporte}
                    idPartida={idPartida}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!loading && esporte ? (
            <PlaybyPlay
              evento={evento}
              idPartida={idPartida}
              esporte={esporte}
            />
          ) : null}
        </TabPanel>
      </Paper>
    </Box>
  );
}

export default PartidasPage;
