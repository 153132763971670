import axios from "axios";
import { ApiEsporte, esporteApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchEventoPartida(props) {
  const { evento } = props;
  const _api = ApiEsporte[evento.id_esporte] || ApiEsporte[1];

  const res = await axios.get(`${baseURL}/${_api}/api/evento-partida`, {
    params: {
      id_evento: evento.id,
      pageSize: "100",
      expand: "casa,visitante,documentos",
      notField: "fase",
      notValue: "grupo",
      // 'idEvento.flag_del': 0
    },
  });
  const data = res.data.items;
  return data;
}

export async function fetchEventoPartidaPorId(props) {
  const { idPartida, esporte } = props;
 

  const _api = esporteApiEsporte[esporte]

  const res = await axios.get(
    `${baseURL}/${_api}/api/evento-partida/${idPartida}`,
    {
      params: {
        expand: "casa,visitante,idEvento,documentos",
        notField: "fase",
        notValue: "grupo",
      },
    }
  );
  const data = res.data;
  return data;
}
