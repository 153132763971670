import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  // Redirect
} from "react-router-dom";

import MetaTags from "react-meta-tags";

//material-ui
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

//components
import Header from "./components/HeaderSlim";
import Footer from "./components/Footer";
import PageNotFound from "./components/PageNotFound";
import ScrollToTop from "./util/ScrollToTop";
//pages
import Home from "./pages/Home";

//FEEMG
import CodigoEtica from "./pages/FEEMG/CodigoEtica";
import EscolasFiliadas from "./pages/FEEMG/EscolasFiliadas";
import QuemSomos from "./pages/FEEMG/QuemSomos";
import Estabelecimento from "./pages/FEEMG/EscolasFiliadas/Estabelecimento";
import Aluno from "./pages/FEEMG/EscolasFiliadas/Aluno";
import Tecnico from "./pages/FEEMG/EscolasFiliadas/Tecnico";
import Coordenador from "./pages/FEEMG/EscolasFiliadas/Coordenador";
import Organograma from "./pages/FEEMG/Organograma";
import Regionais from "./pages/FEEMG/Regionais";

//Calendario / Eventos
import Calendario from "./pages/Calendario";
import Eventos from "./pages/Eventos";
import Competicoes from "./pages/Competicoes";

//Noticias
import Noticias from "./pages/Noticias";
import NoticiaLeitura from "./pages/Noticias/NoticiaLeitura";
import Videos from "./pages/Noticias/Videos";

//Arbitragem
import ArbitragemRegimentoInterno from "./pages/Arbitragem/RegimentoInterno";
import FeemgDimensoes from "./pages/FeemgDimensoes";
import Arbitragem from "./pages/Arbitragem/ArbitrosCadastrados";
import Representantes from "./pages/Arbitragem/RepresentantesCadastrados";
import Arbitro from "./pages/Arbitragem/Arbitro";
import Representante from "./pages/Arbitragem/Representante";

//Fornecedores
import FornecedoresCadastrados from "./pages/Fornecedores/FornecedoresCadastrados";
import CadastroFornecedores from "./pages/Fornecedores/Cadastro";

//Generico
import FilesPage from "./pages/Generic/FilesPage";
import OrgaoPage from "./pages/Generic/OrgaoPage";

import Contato from "./pages/Contato";
import Pesquisa from "./pages/Pesquisa";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";

//tema
import theme from "./services/theme";

//img
import mainBg from "./assets/img/main-bg.jpg";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PartidasPage from "./pages/Eventos/Partida";
import ArbitragemCursos from "./pages/Arbitragem/Cursos";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#e7e7e7",
    backgroundImage: `url(${mainBg})`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const classes = useStyles();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <MetaTags>
          <title>
            FEEMG - Federação de Esportes Estudantis de Minas Gerais
          </title>
          <meta
            name="description"
            content="Site oficial da Federação de Esportes Estudantis de Minas Gerais - FEEMG"
          />
          <meta
            property="og:description"
            content="Site oficial da Federação de Esportes Estudantis de Minas Gerais - FEEMG"
          />
          <meta property="og:image" content="/logo192.png" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <link rel="canonical" href={window.location.href} />
        </MetaTags>
        <div className={classes.root}>
          <CssBaseline />
          <Router>
            <ScrollToTop />
            <Header />

            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>

              <Route path="/feemg/codigo-de-etica" exact>
                <CodigoEtica />
              </Route>
              <Route path="/feemg/escolas-filiadas" exact>
                <EscolasFiliadasParams />
              </Route>
              <Route path="/feemg/quem-somos" exact>
                <QuemSomos />
              </Route>

              <Route path="/feemg/escola/:escolaId/:slugEscola" exact>
                <EscolaEstabelecimentoParams />
              </Route>
              <Route
                path="/feemg/escola/:escolaId/:slugEscola/aluno/:alunoId/:slugAluno"
                exact
              >
                <Aluno />
              </Route>
              <Route
                path="/feemg/escola/:escolaId/:slugEscola/tecnico/:pessoaId/:slugPessoa"
                exact
              >
                <Tecnico />
              </Route>
              <Route
                path="/feemg/escola/:escolaId/:slugEscola/coordenador/:pessoaId/:slugPessoa"
                exact
              >
                <Coordenador />
              </Route>
              <Route path="/feemg/regionais" exact>
                <Regionais />
              </Route>
              <Route path="/feemg/organograma" exact>
                <Organograma />
              </Route>

              <Route path="/calendario" exact>
                <Calendario arbitragem={false} />
              </Route>
              <Route path="/arbitragem/calendario" exact>
                <Calendario arbitragem={true} />
              </Route>
              <Route path="/arbitragem/cursos" exact>
                <CursosArbitragemParams />
              </Route>

              <Route path="/feemg/:esporte/evento/:id/:title" exact>
                <Eventos />
              </Route>
              <Route
                path="/feemg/:esporte/:idEvento/:slug/partida/:idPartida"
                exact
              >
                <PartidasPage />
              </Route>

              <Route path="/arbitragem/regimento-interno" exact>
                <ArbitragemRegimentoInterno />
              </Route>
              <Route path="/arbitragem/arbitros-cadastrados" exact>
                <ArbitrosParams />
              </Route>
              <Route path="/arbitragem/arbitro/:pessoaId/:slugPessoa" exact>
                <Arbitro />
              </Route>
              <Route
                path="/arbitragem/representante/:pessoaId/:slugPessoa"
                exact
              >
                <Representante />
              </Route>
              <Route path="/arbitragem/representantes-cadastrados" exact>
                <RepresentantesParams />
              </Route>

              <Route path="/noticias" exact>
                <NoticiasParams />
              </Route>
              <Route path="/noticias/leitura/:id/:title">
                <NoticiaLeitura />
              </Route>
              <Route path="/noticias/videos" exact>
                <Videos />
              </Route>

              <Route path="/competicoes" exact>
                <CompeticoesParams />
              </Route>

              <Route path="/fornecedores/cadastro-de-pessoa-juridica" exact>
                <CadastroFornecedores />
              </Route>
              <Route path="/fornecedores/fornecedores-cadastrados" exact>
                <FornecedoresCadastradosParams />
              </Route>

              <Route path="/ouvidoria" exact>
                <Contato
                  menuTitle="FEEMG"
                  pageTitle="Ouvidoria"
                  form="ouvidoria"
                />
              </Route>

              <Route path="/fale-conosco" exact>
                <Contato
                  menuTitle="FEEMG"
                  pageTitle="Fale conosco"
                  form="faleconosco"
                />
              </Route>

              <Route path="/feemg-7d/:idMenu/:titulo" exact>
                <FeemgDimensoesParams />
              </Route>

              {/* orgao */}

              <Route path="/arbitragem/:id/:titulo" exact>
                <OrgaoPage />
              </Route>
              <Route path="/fornecedores/:id/:titulo" exact>
                <OrgaoPage />
              </Route>
              <Route path="/transparencia/:id/:titulo" exact>
                <OrgaoPage />
              </Route>
              <Route path="/tjd/:id/:titulo" exact>
                <OrgaoPage />
              </Route>

              {/* orgao */}

              {/* documentos */}
              <Route path="/documentos/:idMenu/:titulo" exact>
                <FilesPage menuTitle="FEEMG" />
              </Route>
              <Route path="/feemg/documentos/:idMenu/:titulo" exact>
                <FilesPage menuTitle="FEEMG" />
              </Route>
              <Route path="/arbitragem/documentos/:idMenu/:titulo" exact>
                <FilesPage menuTitle="Arbitragem" />
              </Route>
              <Route path="/fornecedores/documentos/:idMenu/:titulo" exact>
                <FilesPage menuTitle="Fornecedores" />
              </Route>
              <Route path="/transparencia/documentos/:idMenu/:titulo" exact>
                <FilesPage menuTitle="Transparência" />
              </Route>
              <Route path="/tjd/documentos/:idMenu/:titulo" exact>
                <FilesPage menuTitle="TJD" />
              </Route>
              {/* documentos */}

              <Route path="/politica-de-privacidade" exact>
                <PoliticaPrivacidade />
              </Route>

              <Route path="/pesquisa" exact>
                <PesquisaParams />
              </Route>

              <Route>
                <PageNotFound />
              </Route>
            </Switch>

            <Footer />
          </Router>
        </div>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
}

export default App;

function NoticiasParams() {
  let query = useQuery();
  return <Noticias searchTerm={query.get("q")} page={query.get("p")} />;
}

function CompeticoesParams() {
  let query = useQuery();
  return (
    <Competicoes
      searchTerm={query.get("q")}
      page={query.get("p")}
      temporada={query.get("temporada")}
      escopo={query.get("escopo")}
      esporte={query.get("esporte")}
    />
  );
}

function CursosArbitragemParams() {
  let query = useQuery();
  return (
    <ArbitragemCursos
      searchTerm={query.get("q")}
      page={query.get("p")}
      temporada={query.get("temporada")}
      escopo={query.get("escopo")}
    />
  );
}

function FornecedoresCadastradosParams() {
  let query = useQuery();
  return (
    <FornecedoresCadastrados
      searchTerm={query.get("q")}
      ramoQuery={query.get("ramo")}
      page={query.get("p")}
    />
  );
}

function EscolasFiliadasParams() {
  let query = useQuery();
  return <EscolasFiliadas searchTerm={query.get("q")} page={query.get("p")} />;
}

function EscolaEstabelecimentoParams() {
  let query = useQuery();
  return (
    <Estabelecimento
      searchTerm={query.get("q")}
      page={query.get("p")}
      t_searchTerm={query.get("t_q")}
      t_page={query.get("t_p")}
    />
  );
}

function PesquisaParams() {
  let query = useQuery();
  return <Pesquisa searchTerm={query.get("q")} />;
}

function ArbitrosParams() {
  let query = useQuery();
  return (
    <Arbitragem
      searchTerm={query.get("q")}
      modalidadeQuery={query.get("mod")}
      page={query.get("p")}
    />
  );
}

function RepresentantesParams() {
  let query = useQuery();
  return <Representantes searchTerm={query.get("q")} page={query.get("p")} />;
}

function FeemgDimensoesParams() {
  let query = useQuery();
  return <FeemgDimensoes page={query.get("p")} />;
}
