import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SocialMidiaLinks from '../../../components/SocialMidiaLinks';

const useStyles = makeStyles((theme) => ({

    container: {
        backgroundColor: '#fff',
        padding: '60px 0',
        textAlign: 'center',

    },
}));

export default function LinksRedesSociais() {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SocialMidiaLinks  title="SIGA-NOS NAS REDES SOCIAIS" align="center" iconSize="large"/>
        </div>
    )

}