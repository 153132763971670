import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RowHandler from "./RowHandler";
import moment from "moment";
import TableScoutStyles from "../../../../../components/TableScouts/styles";
import clsx from "clsx";
import useStyles from "./styles";
import { chain } from "lodash";
import { useMemo } from "react";
import { Alert } from "@material-ui/lab";

export default function TableScouts(props) {
  const { eventoPartida, eventoPartidaScouts } = props;
  // console.log(eventoPartida, eventoPartidaScouts)
  const TBScoutClasses = TableScoutStyles();
  const classes = useStyles();

  const timeline = useMemo(() => {
    if (eventoPartidaScouts.length) {
      const scouts = eventoPartidaScouts;

      let timeline = scouts.map((item) => {
        let timeCasa = Boolean(item.id_equipe === eventoPartida.id_equipe_casa);
        return {
          ...item,
          timeCasa: timeCasa,
        };
      });

      const organizedTimeline = chain(timeline)
        .sortBy("tempo")
        .groupBy("quarto")
        .toArray()
        .flatten()
        .reverse()
        .value();

      let fullTimeline = {
        inicio: moment(organizedTimeline[0].data).format("HH:mm:ss"),
        fim: moment(organizedTimeline.pop().data).format("HH:mm:ss"),
        payload: organizedTimeline,
      };
      return fullTimeline;
    }
    return null;
  }, [eventoPartida, eventoPartidaScouts]);

  console.log(timeline)
  return (
    <TableContainer>
      <Table className={clsx(TBScoutClasses.tableScouts, classes.tableStyle)}>
        <TableHead>
          <TableRow>
            <TableCell>{eventoPartida.casa.sigla_evento}</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              {eventoPartida.visitante.sigla_evento}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeline ? (
            <React.Fragment>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <b>
                    {" "}
                    INÍCIO ({moment(eventoPartida.data).format("HH:mm:ss")})
                  </b>
                </TableCell>
              </TableRow>
              {timeline && timeline.payload.length
                ? timeline.payload.map((item, key) => (
                    <RowHandler
                      key={key}
                      scout={item}
                      eventoPartida={eventoPartida}
                    />
                  ))
                : null}
            </React.Fragment>
          ) : (
            <TableRow>
              <TableCell colSpan="3">
                <Alert color="warning">Não há registros</Alert>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
