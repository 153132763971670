import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import noticia670 from '../../../assets/img/noticias/670.png';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { slugNoticia } from '../../../util/formatString';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';


const useStyles = makeStyles((theme) => ({
    cardNoticias: {
        borderBottom: '1px solid rgba(0,0,0,0.3)',
        padding: '20px 0',
        [theme.breakpoints.up('md')]: {
            padding: '20px',
        }
    },
    cardNoticiasSM: {
        borderBottom: '1px solid rgba(0,0,0,0.3)',
        padding: '15px 0',
        [theme.breakpoints.up('md')]: {
            padding: '15px',
        }
    },
    cardTitle: {
        marginTop: 0,
    },
    linkNoticia: {
        fontSize: '1.1rem',
        fontWeight: 900,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        transition: 'all 200ms ease-in',
        '&:hover': {
            color: 'gray'
        }
    },
    cardImgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px',
        overflow: 'hidden',
        marginBottom: '20px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '0',
            height: 'auto',
        }
    },
    cardImgContainerSM: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px',
        overflow: 'hidden',
        marginBottom: '10px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '0',
            height: '150px',
        }
    },
    cardImg: {
        width: '100%',
        height: 'auto',

    },
    cardContent: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20px',
        },
    },
    date: {
        fontSize: '.85rem',
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[700]
    },
    resumo: {
        fontSize: '0.9rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        }
    },
    verMaisLink: {
        display: 'block',
        marginTop: '1rem',
        fontSize: '0.9rem',
        textDecoration: 'none',
        color: '#1f221f',
        fontWeight: 500,
        '& svg': {
            height: '1rem',
            marginRight: '.2rem',
            verticalAlign: 'text-bottom',
            color: theme.palette.primary.main
        },
        '&:hover': {
            opacity: 0.7,
        }
    },
}));

export default function ListNoticias(params) {
    const classes = useStyles();
    const smList = params.smList;
    const newsList = params.newsList
    const [isSM, setisSM] = useState(false)

    const imgFallback = (e) => {
        e.target.src = noticia670
    }

    useEffect(() => {
        if (smList) {
            setisSM(true)
        }
    }, [smList])


    const localDate = (created_at) => {
        var date = new Date(created_at*1000)
        var formatedDate = date.toLocaleDateString()
        return formatedDate
    }


    return (
        <>
            {newsList.map((noticia, i) => (
                <div key={i}>
                    <Grid container className={isSM ? classes.cardNoticiasSM : classes.cardNoticias}>

                        <Grid item xs={12} md={isSM ? 2 : 4}>
                            <div className={isSM ? classes.cardImgContainerSM : classes.cardImgContainer}>
                                <img src={`https://sgefeemg.bigmidia.com/_uploads/26/noticias/${noticia.foto_capa}?time=${(new Date()).getTime()}`} loading="lazy" onError={(e) => imgFallback(e)} className={classes.cardImg} alt={noticia.titulo} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={isSM ? 10 : 8}>
                            <div className={classes.cardContent}>
                                <Link className={classes.linkNoticia} to={`/noticias/leitura/${noticia.id}/${slugNoticia(noticia.titulo)}`}>
                                    <h3 className={classes.cardTitle}>{noticia.titulo}</h3>
                                </Link>
                                <div className={classes.date}>
                                    <TodayOutlinedIcon fontSize="small" />
                                    {localDate(noticia.created_at)}
                                </div>
                                <div className={classes.resumo}>
                                    {noticia.resumo}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Link className={classes.verMaisLink} to={`/noticias/leitura/${noticia.id}/${slugNoticia(noticia.titulo)}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus-circle" role="img" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
                                </svg>
                                Ver conteúdo completo
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            ))
            }
        </>
    )
}