import { useEffect, useState } from "react";
import { gestaoApi } from "../../../services/api";

const useEvento = (props) => {
  const { id } = props;

  const [evento, setEvento] = useState({
    error: false,
    loading: true,
    payload: {},
    pageCount: 0,
    currentPage: 0,
  });

  useEffect(() => {
    setEvento({
      loading: true,
      error: false,
      payload: {},
    });
    async function getEvento() {
      gestaoApi
        .get(`evento/${id}?expand=idEsporte`)
        .then((response) => {
          setEvento({
            loading: false,
            error: false,
            payload: response.data,
          });
        })
        .catch((err) => {
          setEvento({
            loading: false,
            error: true,
            payload: {},
          });
        });
    }
    getEvento();
  }, [id]);

  return {
    error: evento.error,
    loading: evento.loading,
    evento: evento.payload,
    currentPage: evento.currentPage,
    pageCount: evento.pageCount,
  };
};

export default useEvento;
