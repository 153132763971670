import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gestaoApi } from '../../../../services/api';

//components
import PageTitle from '../../../../components/PageTitle';

//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Alunos from './Alunos'
import Representantes from './Representantes';
import Tecnicos from './Tecnicos';

import { formataCNPJ } from '../../../../util/formatString';

import Capitalize from '../../../../util/Capitalize';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '40px',
        fontSize: '1rem'
    },
    heading: {
        fontWeight: 600
    },
    tableHead: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        '& .MuiTableCell-root': {
            fontSize: '1.1rem',
            fontWeight: 600,
            paddingBottom: '.5rem'
        }
    },
    imgContainer: {
        borderRadius: '50%',
        overflow: 'hidden',
        height: '50px',
        width: '50px',
        textAlign: 'center',
        boxShadow: '0px 0px 4px 1px #ddd',
        border: '2px solid white',
        '& img': {
            height: '50px'
        }
    },
    nameCell: {
        display: 'flex',
        flexDirection: 'row'
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    formControl: {
        marginBottom: theme.spacing(3),
        minWidth: 200,
        maxWidth: 500
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'unset',
        '& a': {
            marginTop: 0
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
        },
    },
    linkAluno: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover ': {
            color: theme.palette.primary.main
        }
    }
}));

export default function Estabelecimento(params) {
    const classes = useStyles();
    const { escolaId, slugEscola } = useParams()
    const [estabelecimento, setEstabelecimento] = useState([])
    const [listRepresentantes, setListRepresentantes] = useState([])

    const page = params.page;
    const queryParam = params.searchTerm;
    const t_page = params.t_page;
    const t_queryParam = params.t_searchTerm;


    useEffect(() => {
        if (escolaId) {
            async function getEscolas() {
                await gestaoApi.get('estabelecimento', {
                    params: {
                        id_estabelecimento_tipo: 8,
                        expand: 'representante',
                        flg_ativo: 1,
                        flag_del: 0,
                        id: escolaId
                    },
                }).then((response) => {
                    setEstabelecimento(response.data.items[0])
                });
            }
            getEscolas()
        }
    }, [escolaId])

    useEffect(() => {
        if (escolaId) {
            async function getRepresentantes() {
                await gestaoApi.get('pessoa', {
                    params: {
                        flag_del: 0,
                        id_tipo: 33,
                        id_estabelecimento: escolaId
                    },
                }).then((response) => {
                    setListRepresentantes(response.data.items)
                });
            }
            getRepresentantes()
        }
    }, [escolaId])


    var logoClube = "https://sge.cbhb.org.br/_uploads/estabelecimento/logo/noLogo.png"
    const imgFallback = (e) => {
        e.target.src = logoClube
    }

    return (
        <div>
            <PageTitle menuTitle="FEEMG" pageTitle={estabelecimento.descricao ? Capitalize(estabelecimento.descricao) : ''} />
            <Paper square elevation={0} className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container spacing={2} alignItems="center" style={{ paddingBottom: 30 }}>
                        <Grid item style={{ maxWidth: '200px' }}>
                            <img src={`https://sgefeemg.bigmidia.com/_uploads/estabelecimento/logo/${estabelecimento.url_logo}`} onError={(e) => imgFallback(e)} alt={estabelecimento.sigla} style={{ maxHeight: '150px', maxWidth: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sm={9} >
                            <Typography className={classes.heading} variant="h5" component="h1" color="primary" gutterBottom>
                                {estabelecimento.descricao ? Capitalize(estabelecimento.descricao) : ''}
                            </Typography>

                            <Typography className={classes.heading} variant="subtitle2" gutterBottom>
                                {estabelecimento.cnpj ?
                                    <> CNPJ: {formataCNPJ(estabelecimento.cnpj)}</>
                                    :
                                    <></>
                                }

                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{ paddingBottom: 50 }}>
                        <Typography variant="h6" component="h2" color="primary" gutterBottom>Coordenador de Esportes:</Typography>
                        <Representantes listRepresentantes={listRepresentantes} escolaId={escolaId} slugEscola={slugEscola} />
                    </div>

                    <Typography variant="h6" component="h2" color="primary" gutterBottom>Técnicos:</Typography> 
                    <Tecnicos estabelecimento={estabelecimento} escolaId={escolaId} slugEscola={slugEscola} page={page} queryParam={queryParam} t_page={t_page} t_queryParam={t_queryParam} />

                    <Typography variant="h6" component="h2" color="primary" gutterBottom>Estudantes-Atletas:</Typography>
                    <Alunos page={page} queryParam={queryParam} t_page={t_page} t_queryParam={t_queryParam} escolaId={escolaId} slugEscola={slugEscola} />

                </Container>
            </Paper>
        </div>
    )
}
