import React from 'react'
// import userInstagram from 'user-instagram'

import { makeStyles } from '@material-ui/core/styles';
// import { Grid } from '@material-ui/core';

// import instagramData from '../../../../assets/data/feemg_instagram.json';

const useStyles = makeStyles((theme) => ({
    imgContainer: {
        overflow: 'hidden',
        maxHeight: '250px',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            width: '100%',
            // maxHeight:'250px'
        }
    },
    iframeContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        maxWidth: 1200,
        height: '75vw',
        [theme.breakpoints.up('lg')]: {
            height: '43vw',
        }
    }

}))

export default function Instagram() {
    const classes = useStyles();
    // const [posts, setPosts] = useState([])
    // const [error, setError] = useState(false)
    // const [instagramPosts, setInstagramPosts] = useState([])

    // useEffect(() => {
    //     setInstagramPosts(instagramData.graphql.user.edge_owner_to_timeline_media.edges)
    //     // console.log(instagramData.graphql.user.edge_owner_to_timeline_media.edges)

    //     userInstagram('feemg_oficial')
    //         .then(function (res) {
    //             // console.log(res.posts)
    //             setPosts(res.posts)
    //         })
    //         .catch(function (err) {
    //             console.error(err);
    //             setError(true)
    //         });
    // }, [])

    return (
        <div className={classes.iframeContainer}>
            <script src="https://snapwidget.com/js/snapwidget.js"></script>
            <iframe title="instagram-iframe" src="https://snapwidget.com/embed/934116" className="snapwidget-widget" allowTransparency="true" frameBorder="0" scrolling="no" style={{ border: 'none', overflow: 'hidden', width: '100%', height: '100%' }}></iframe>
        </div>
        // <Grid container spacing={2}>
        //     {error ?
        //         <>
        //             {instagramPosts.map((item, key) => (
        //                 <Grid item key={key} xs={12} sm={6} mg={4} lg={3}>
        //                     <a href={`https://www.instagram.com/p/${item.node.shortcode}`} target="_blank" rel="noreferrer">
        //                         <div className={classes.imgContainer}>
        //                             <img src={item.node.thumbnail_src} alt={item.node.accessibility_caption} />
        //                         </div>
        //                     </a>
        //                  </Grid>
        //             ))}
        //         </>
        //         :
        //         <>
        //             {posts.map((item, key) => (
        //                 <Grid item key={key} xs={12} sm={6} mg={4} lg={3}>
        //                     <a href={item.url} target="_blank" rel="noreferrer">
        //                         <div className={classes.imgContainer}>
        //                             <img src={item.imageUrl} alt={item.caption} />
        //                         </div>
        //                     </a>
        //                 </Grid>
        //             ))}
        //         </>
        //     }
        // </Grid>
    )

}