import React, { useState, useEffect } from 'react';

//components
import PageTitle from '../../../components/PageTitle';
import ListagemDocumentos from '../../../components/ListagemDocumentos';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Alert from '@material-ui/lab/Alert';

//router
import { useParams } from "react-router-dom";

// - api
import { governancaApi } from '../../../services/api';


import MetaTags from 'react-meta-tags'


const useStyles = makeStyles({
    wrapper: {
        padding: '30px 0'
    },
});

function FilesPage(props) {
    const classes = useStyles();
    const menuTitle = props.menuTitle;
    const { idMenu } = useParams();
    const [orgao, setOrgao] = useState([])
    const [orgaoLength, setOrgaoLength] = useState(0)

    useEffect(() => {

        async function getOrgao() {
            await governancaApi.get('orgao', {
                params: {
                    id_menu: idMenu,
                    expand: 'orgaoAnexos',
                    sort: 'created_at'
                },
            }).then((response) => {
                setOrgao(response.data.items)
                setOrgaoLength(response.data._meta.totalCount)
                // console.log(response.data.items[0].orgaoAnexos)
            });
        }
        getOrgao()

    }, [idMenu])


    return (
        <React.Fragment>
            {orgaoLength > 0 ?
                <React.Fragment>
                    <MetaTags>
                        <title>{orgao[0].titulo} - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                    </MetaTags>
                    <PageTitle menuTitle={menuTitle} pageTitle={orgao[0].titulo} />
                    <Paper className={classes.wrapper} square>
                        <Container maxWidth="lg" square>
                            {orgao[0].orgaoAnexos.length > 0 ?
                                <ListagemDocumentos documentos={orgao[0].orgaoAnexos} hasFilter={idMenu === '55' ? true : false} />
                                :
                                <Alert severity="info">Não há registros.</Alert>
                            }
                        </Container>
                    </Paper>
                </React.Fragment>
                :
                <React.Fragment>
                    <MetaTags>
                        <title>{menuTitle} - Documentos - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
                    </MetaTags>
                    <PageTitle menuTitle={menuTitle} pageTitle="Documentos" />
                    <Paper className={classes.wrapper} square>
                        <Container maxWidth="lg" square>
                            <Alert severity="info">Não há registros.</Alert>
                        </Container>
                    </Paper>
                </React.Fragment>
            }

        </React.Fragment>
    )
}

export default FilesPage;