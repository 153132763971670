import React, { useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

//material ui
import {
    AppBar,
    Toolbar,
    Hidden,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Link,
    Container,
    IconButton,
    Menu,
    Button,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { useTheme, withStyles } from '@material-ui/core/styles';

import { StyledMenu, StyledMenuItem, NavAccordion, NavAccordionSummary, useStyles } from './styles';

//icon
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';


//img
import logo from '../../assets/img/logo.png'

import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PhoneIcon from '@material-ui/icons/Phone';
//data
import slugNormalize from '../../util/slugNormalize';

import { governancaApi } from '../../services/api';

import SearchInput from '../../components/SearchInput';

import _ from 'lodash';

export default function HeaderSlim(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClick = (target, index) => {
        setAnchorEl(target);
        setOpenMenu({ [index]: !openMenu[index] })
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false)
    };

    const [pageScrolled, setPageScrolled] = useState(false);

    const trigger = useScrollTrigger({
        disableHysteresis: true,
    });

    useEffect(() => {
        setPageScrolled(Boolean(trigger))
    }, [trigger])

    const theme = useTheme();
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
    const [menuGovernanca, setMenuGovernanca] = useState([]);

    useEffect(() => {
        async function getMenu() {
            await governancaApi.get('menu-governanca', {
                params: {
                    expand: 'filho',
                    isNullField: 'id_pai',
                    sort: 'ordem'
                },
            }).then((response) => {

                var menuSortOrdem = _.sortBy(response.data.items, 'ordem', function (n) {
                    return Math.sin(n);
                })

                var menuSortOrdemFilho = menuSortOrdem.map((item, key) => {
                    let filho = []
                    if (item.filho) {
                        filho = _.sortBy(item.filho, 'ordem', function (n) {
                            return Math.sin(n);
                        })
                        return ({
                            created_at: item.created_at,
                            created_by: item.created_by,
                            estado: item.estado,
                            filho: filho,
                            flag_governanca: item.flag_governanca,
                            hierarquia: item.hierarquia,
                            id: item.id,
                            id_entidade: item.id_entidade,
                            id_estabelecimento: item.id_estabelecimento,
                            id_pai: item.id_pai,
                            ordem: item.ordem,
                            tipo_entidade: item.tipo_entidade,
                            titulo: item.titulo,
                            updated_at: item.updated_at,
                            updated_by: item.updated_by,
                            url: item.url,
                        })
                    }

                    return (item)
                })
                setMenuGovernanca(menuSortOrdemFilho)
            });
        }
        getMenu()
    }, [])

    const [anchorSearchEl, setAnchorSearchEl] = useState(null);

    const handleSearchClick = (event) => {
        setAnchorSearchEl(event.currentTarget);
    };

    const handleSearchClose = () => {
        setAnchorSearchEl(null);
    };

    const SearchMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
        },
    })((props) => (
        <Menu
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ));

    return (
        <React.Fragment>
            <AppBar position='sticky' className={pageScrolled ? classes.appbarScrolled : classes.appbar} elevation={pageScrolled ? 4 : 0}>
                <div className={classes.topHeader}>
                    <Container>
                        <div className={classes.headerText}>
                            <div className={classes.textFederacao}>Federação de Esportes Estudantis de Minas Gerais</div>
                            <div className={classes.flexContainer}>
                                <a className={classes.headerLink} href="/feemg/escolas-filiadas#cadastre-se">
                                    <PersonAddIcon fontSize="small" />
                                    <Hidden xsDown>Cadastre-se</Hidden>
                                </a>
                                <a className={classes.headerLink} href='https://sge.feemg.com.br/' target="_blank" rel="noreferrer">
                                    <LockOpenIcon fontSize="small" />
                                    <Hidden xsDown>Acesso Usuário</Hidden>
                                </a>
                                <span className={classes.headerLink} style={{ marginRight: '12px' }}>
                                    <PhoneIcon fontSize="small" />
                                    (31) 2512-7300
                                </span>

                                <a className={classes.headerLink} style={{ marginLeft: 'auto' }} href="https://www.facebook.com/feemg.oficial" target="_blank" rel="noreferrer">
                                    <FacebookIcon fontSize="small" />
                                </a>
                                <a className={classes.headerLink} href="https://twitter.com/FEEMG_oficial" target="_blank" rel="noreferrer">
                                    <TwitterIcon fontSize="small" />
                                </a>
                                <a className={classes.headerLink} href="https://www.instagram.com/feemg_oficial" target="_blank" rel="noreferrer">
                                    <InstagramIcon fontSize="small" />
                                </a>
                                <a className={classes.headerLink} href="https://www.youtube.com/channel/UCDAsJPadqeLn3vppqSnT_5w" target="_blank" rel="noreferrer">
                                    <YouTubeIcon fontSize="small" />
                                </a>

                            </div>
                        </div>
                    </Container>
                </div>

                <Hidden mdDown>
                    <Toolbar className={classes.toolbar}>
                        <Container className={classes.headerWraper}>
                            <RouterLink to='/' className={pageScrolled ? classes.logoWraperScrolled : classes.logoWraper}>
                                <img src={logo} alt="logo FEEMG" />
                            </RouterLink>
                            <div>
                                {menuGovernanca.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {item.url ?
                                                <React.Fragment>
                                                    {item.url.includes('documentos') ?
                                                        <RouterLink to={`/documentos/${item.id}/${slugNormalize(item.titulo)}`} className={classes.navLink} component={Button} onClick={e => handleClick(e.currentTarget, i)}>{item.titulo}</RouterLink>
                                                        :
                                                        <RouterLink to={item.url} className={classes.navLink} component={Button} onClick={e => handleClick(e.currentTarget, i)}>{item.titulo}</RouterLink>
                                                    }
                                                </React.Fragment>
                                                :
                                                <Link className={classes.navLink} component={Button} onClick={e => handleClick(e.currentTarget, i)}>{item.titulo}</Link>
                                            }
                                            {item.filho.length > 1 ?
                                                <StyledMenu
                                                    anchorEl={anchorEl}
                                                    open={openMenu[i]}
                                                    onClose={handleClose}
                                                >
                                                    {item.filho.map((filho, i) => {

                                                        if (filho.url) {
                                                            if (filho.url.includes('documentos')) {
                                                                return (
                                                                    <StyledMenuItem key={i} onClick={handleClose} component={RouterLink} to={`/${slugNormalize(item.titulo)}/documentos/${filho.id}/${slugNormalize(filho.titulo)}`} >{filho.titulo}</StyledMenuItem>
                                                                )
                                                            } else if (filho.url.includes('http')) {
                                                                return (
                                                                    <StyledMenuItem key={i} onClick={handleClose} component={Link} href={filho.url} target="_blank" rel="noreferrer">{filho.titulo}</StyledMenuItem>
                                                                )
                                                            } else {
                                                                return (
                                                                    <StyledMenuItem key={i} onClick={handleClose} component={RouterLink} to={filho.url}>{filho.titulo}</StyledMenuItem>
                                                                )
                                                            }
                                                        } else {
                                                            return (
                                                                <StyledMenuItem key={i} onClick={handleClose} component={RouterLink} to={`/${slugNormalize(item.titulo)}/${filho.id}/${slugNormalize(filho.titulo)}`} >{filho.titulo}</StyledMenuItem>
                                                            )
                                                        }

                                                    })}
                                                </StyledMenu>
                                                :
                                                <React.Fragment></React.Fragment>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                                <IconButton aria-label="search" color="primary"
                                    aria-controls="search-menu"
                                    aria-haspopup="true"
                                    onClick={handleSearchClick}>
                                    <SearchIcon />
                                </IconButton>
                                <SearchMenu
                                    id="search-menu"
                                    anchorEl={anchorSearchEl}
                                    open={Boolean(anchorSearchEl)}
                                    onClose={handleSearchClose}
                                >
                                    <SearchInput mobile={false} setAnchorSearchEl={setAnchorSearchEl} setExpanded={setExpanded} />
                                </SearchMenu>
                            </div>
                        </Container>
                    </Toolbar>
                </Hidden>
                <Hidden lgUp>
                    <Toolbar className={classes.navbar}>
                        <div className={classes.navWraper}>
                            <NavAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}>
                                <NavAccordionSummary
                                    expandIcon={expanded === 'panel1' ? <CloseIcon /> : <MenuIcon />}
                                    elevation={0}
                                    aria-controls="accordion-content"
                                    style={{ margin: 0 }}
                                    id="accordion-header"
                                >
                                    <RouterLink to='/' onClick={e => e.stopPropagation()} style={{ marginRight: 'auto' }}>
                                        <img src={logo} alt="logo FEEMG" style={{ maxHeight: 50 }} />
                                    </RouterLink>
                                </NavAccordionSummary>
                                <AccordionDetails elevation={0} style={{ flexDirection: 'column', padding: 0, maxHeight: '65vh', overflow: 'auto' }}>
                                    <List dense={true} component="nav" className={classes.listButton}>
                                        <ListItem className={classes.listItem} style={!matchesLG ? { display: 'flex', paddingTop: 12, paddingBottom: 12 } : { display: 'none' }}>
                                            <SearchInput mobile={true} setExpanded={setExpanded} />
                                        </ListItem>
                                        {menuGovernanca.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {item.url ?
                                                        <React.Fragment>
                                                            {item.url.includes('documentos') ?
                                                                <ListItem button component={RouterLink} to={`/documentos/${item.id}/${slugNormalize(item.titulo)}`} className={classes.listItem} onClick={handleChange(null)}>
                                                                    <ListItemText primary={item.titulo} />
                                                                </ListItem>
                                                                :
                                                                <ListItem button component={RouterLink} to={item.url} className={classes.listItem} onClick={handleChange(null)}>
                                                                    <ListItemText primary={item.titulo} />
                                                                </ListItem>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <ListItem button className={classes.listItem} onClick={e => handleClick(e.currentTarget, i)}>
                                                            <ListItemText primary={item.titulo} />
                                                            {item.filho.length > 1 ? openMenu[i] ? <ExpandLess /> : <ExpandMore /> : <React.Fragment></React.Fragment>}
                                                        </ListItem>
                                                    }
                                                    {item.filho.length > 1 ?
                                                        <Collapse in={openMenu[i]} timeout="auto" unmountOnExit>
                                                            <List dense={true} component="div" disablePadding>
                                                                {item.filho.map((filho, i) => {

                                                                    if (filho.url) {
                                                                        if (filho.url.includes('documentos')) {
                                                                            return (
                                                                                <ListItem key={i} button onClick={handleChange(null)} component={RouterLink} to={`/${slugNormalize(item.titulo)}/documentos/${filho.id}/${slugNormalize(filho.titulo)}`} className={classes.listItemNested}>
                                                                                    <ListItemText primary={filho.titulo} />
                                                                                </ListItem>
                                                                            )
                                                                        } else if (filho.url.includes('http')) {
                                                                            return (
                                                                                <ListItem key={i} button onClick={handleChange(null)} component={Link} href={filho.url} target="_blank" rel="noreferrer" className={classes.listItemNested}>
                                                                                    <ListItemText primary={filho.titulo} />
                                                                                </ListItem>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <ListItem key={i} button onClick={handleChange(null)} component={Link} href={filho.url} className={classes.listItemNested}>
                                                                                    <ListItemText primary={filho.titulo} />
                                                                                </ListItem>
                                                                            )
                                                                        }

                                                                    } else {
                                                                        return (
                                                                            <ListItem key={i} button onClick={handleChange(null)} component={RouterLink} to={`/${slugNormalize(item.titulo)}/${filho.id}/${slugNormalize(filho.titulo)}`} className={classes.listItemNested}>
                                                                                <ListItemText primary={filho.titulo} />
                                                                            </ListItem>
                                                                        )
                                                                    }

                                                                })}
                                                            </List>
                                                        </Collapse>
                                                        :
                                                        <React.Fragment></React.Fragment>
                                                    }
                                                </React.Fragment>
                                            )
                                        })}
                                    </List>
                                </AccordionDetails>
                            </NavAccordion>
                        </div>
                    </Toolbar>
                </Hidden>
            </AppBar>
        </React.Fragment>
    );
}
