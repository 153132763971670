import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

import axios from "axios";

import { gestaoApi } from "../../../../services/api";
import cepApi from "../../../../services/fetchCEP";
import { GreenCheckbox } from "../../../../components/Styles";

import InputMask from "react-input-mask";
import swal from "sweetalert";

import financeiroFornecedor from "../../../../assets/data/models/financeiro-fornecedor";
import { Checkbox, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "1rem",
  },
  title: {
    fontWeight: 900,
    fontSize: "1.55rem",
  },
  contentContainer: {
    padding: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    "& .MuiInputBase-root": {
      borderRadius: 0,
      backgroundColor: "rgba(255,255,255,0.8)",
      transition: "all 300ms ease-in-out",
      "&:focus": {
        backgroundColor: "rgba(255,255,255,1)",
      },
    },
  },
  btnEnviar: {
    backgroundColor: theme.palette.success.main,
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
    color: "white",
    fontWeight: "bold",
    fontSize: "1rem",
    borderRadius: "30px",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  linkPolitica: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      color: "gray",
    },
  },
}));

function FormCadastroFornecedor() {
  const classes = useStyles();
  const [ramo, setRamo] = useState([]);
  const [termo, setTermo] = useState(false);

  const handleRamo = (e) => {
    setRamo(e.target.value);
    // setFormValues({ ...formValues, ramos: ramo });
  };

  const handleChangeCheckbox = (e) => {
    setTermo(e.target.checked);
  };

  const [municipio, setMunicipio] = useState({
    id: "",
    descricao: "",
    uf: "",
    codigo_ibge: "",
  });

  const [formValues, setFormValues] = useState(financeiroFornecedor);
  const [cepError, setCepError] = useState(false);
  const [ramos, setRamos] = useState([]);

  const handleChangeValues = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  function fetchCep() {
    if (formValues.cep !== "") {
      cepApi
        .get(`${formValues.cep}/json/`)
        .then((res) => {
          var logradouro = res.data.logradouro;
          var string = logradouro.split(" ");
          var tipo_logradouro = string[0].toString();

          var logradouroSemTipo = logradouro.replace(tipo_logradouro + " ", "");

          setFormValues({
            ...formValues,
            bairro: res.data.bairro,
            id_municipio: res.data.ibge.slice(0, -1),
            tipo_logradouro: tipo_logradouro,
            logradouro: logradouroSemTipo,
          });

          setMunicipio({
            ...municipio,
            id: res.data.ibge.slice(0, -1),
            descricao: res.data.localidade,
            uf: res.data.uf,
          });
          setCepError(false);
        })
        .catch((err) => {
          console.error(err);
          setCepError(true);
          swal({
            title: "CEP não encontrado",
            text: "Por favor, digite novamente.",
            icon: "error",
          });
        });
    } else {
      swal({
        title: "Informe o CEP para pesquisar.",
        text: "Por favor, digite o CEP.",
        icon: "info",
      });
    }
  }

  function validateForm(event) {
    event.preventDefault();
    if (termo) {
      var datenow = Math.round(new Date() / 1000);
      setFormValues({
        ...formValues,
        updated_at: datenow,
        created_at: datenow,
      });
    } else {
      swal({
        title: "Aceite nossa política de privacidade",
        text: "Você precisa marcar que leu e aceita nossa política de privacidade para realizar o cadastro.",
        icon: "info",
      });
    }
  }

  useEffect(() => {
    async function getRamos() {
      await gestaoApi
        .get("financeiro-ramo", {
          params: {
            sort: "descricao",
            flag_del: 0,
            pageSize: 200,
          },
        })
        .then((response) => {
          // console.log(response.data.items)
          setRamos(response.data.items);
        });
    }
    getRamos();
  }, []);

  const [phone, setPhone] = useState("");

  const changePhone = (e) => {
    var _phone = e.target.value;
    setPhone(_phone);
    if (_phone) {
      setFormValues({
        ...formValues,
        numero_telefone: _phone
          .replace(/ /g, "")
          .replace(/-/g, "")
          .replace(/\(|\)/g, ""),
      });
    }
  };

  const [cnpj, setCNPJ] = useState("");

  const changeCNPJ = (e) => {
    var _cnpj = e.target.value;
    setCNPJ(_cnpj);
    if (_cnpj) {
      setFormValues({
        ...formValues,
        cnpj: _cnpj.replace(/\./g, "").replace("/", "").replace(/-/g, ""),
      });
    }
  };

  const [cep, setCEP] = useState("");

  const changeCEP = (e) => {
    var _cep = e.target.value;
    setCEP(_cep);
    if (_cep) {
      setFormValues({ ...formValues, cep: _cep.replace(/-/g, "") });
    }
  };

  useEffect(() => {
    if (formValues.created_at !== "") {
      async function postFornecedor() {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/gestao/api/financeiro-fornecedor`,
            formValues
          )
          .then((res) => {
            // console.log(res);
            // console.log(res.data);
            setTermo(false);
            setFormValues({
              ...formValues,
              razao_social: "",
              cnpj: "",
              numero_telefone: "",
              email: "",
              site: "",
              cep: "",
              tipo_logradouro: "",
              logradouro: "",
              numero: "",
              complemento: "",
              bairro: "",
              created_at: "",
              updated_at: "",
            });
            setMunicipio({
              ...municipio,
              id: "",
              descricao: "",
              uf: "",
              codigo_ibge: "",
            });
            setPhone("");
            setCNPJ("");
            setCEP("");
            setRamo([]);
            swal({
              title: "Fornecedor foi cadastrado!",
              text: "O cadastro pode ser conferido na página de fornecedores cadastrados.",
              icon: "success",
            });
          })
          .catch((err) => {
            console.error(err);
            setFormValues({
              ...formValues,
              created_at: "",
              updated_at: "",
            });
            swal({
              title: "Erro ao cadastrar",
              text: "Houve um erro ao realizar o cadastro, tente novamente.",
              icon: "error",
            });
          });
      }
      postFornecedor();
    }
  }, [formValues.created_at, formValues, municipio]);

  return (
    <form onSubmit={validateForm}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {" "}
            Dados da Empresa:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Razão Social"
            name="razao_social"
            value={formValues.razao_social}
            onChange={handleChangeValues}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            mask="99.999.999/9999-99"
            value={cnpj}
            onChange={changeCNPJ}
            name="cnpj"
            maskChar=" "
          >
            {() => (
              <TextField
                label="CNPJ"
                variant="outlined"
                className={classes.formControl}
                fullWidth
                required
              />
            )}
          </InputMask>
          {/* <TextField label="CNPJ" name="cnpj" value={formValues.cnpj} onChange={handleChangeValues} required fullWidth={true} variant="outlined" className={classes.formControl} /> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            mask="(99) 99999-9999"
            value={phone}
            onChange={changePhone}
            name="numero_telefone"
            maskChar=" "
          >
            {() => (
              <TextField
                label="Telefone"
                variant="outlined"
                className={classes.formControl}
                fullWidth
                required
              />
            )}
          </InputMask>
          {/* <TextField label="Telefone" name="numero_telefone" value={formValues.numero_telefone} onChange={handleChangeValues} required fullWidth={true} variant="outlined" className={classes.formControl} /> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formValues.email}
            onChange={handleChangeValues}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Site"
            name="site"
            value={formValues.site}
            onChange={handleChangeValues}
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            variant="outlined"
            fullWidth={true}
            className={classes.formControl}
          >
            <InputLabel id="ramoSelect">Ramo de atividade:</InputLabel>
            <Select
              labelId="ramoSelect"
              required
              value={ramo}
              fullWidth={true}
              onChange={handleRamo}
              label="Ramo de atividade"
              multiple
              renderValue={(selected) =>
                selected.map((id) => ramos.find((item) => item.id === id)?.descricao).join(', ')
              }
              onClose={() => setFormValues({ ...formValues, ramos: ramo })}
            >
              {ramos.map((item, key) => (
                <MenuItem key={key} value={item.id}>
                  <Checkbox checked={ramo.indexOf(item.id) > -1} />
                  <ListItemText primary={item.descricao} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Endereço:
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
                    <Button onClick={fetchCep} variant="contained" color="primary" style={{ marginBottom: '1rem' }}>Procurar CEP</Button>
                </Grid> */}
        <Grid item xs={12} sm={6} lg={4}>
          <InputMask
            mask="99999-999"
            value={cep}
            onChange={changeCEP}
            onBlur={fetchCep}
            name="cep"
            maskChar=" "
          >
            {() => (
              <TextField
                label="CEP"
                variant="outlined"
                className={classes.formControl}
                error={cepError}
                fullWidth
                required
              />
            )}
          </InputMask>
          {/* <TextField label="CEP" name="cep" value={formValues.cep} error={cepError} onChange={handleChangeValues} required fullWidth={true} variant="outlined" inputProps={{ maxLength: 9 }} className={classes.formControl} /> */}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Tipo Logradouro"
            name="tipo_logradouro"
            value={formValues.tipo_logradouro}
            onChange={handleChangeValues}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Logradouro"
            name="logradouro"
            value={formValues.logradouro}
            onChange={handleChangeValues}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Número"
            name="numero"
            value={formValues.numero}
            onChange={handleChangeValues}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Complemento"
            name="complemento"
            value={formValues.complemento}
            onChange={handleChangeValues}
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Bairro"
            name="bairro"
            value={formValues.bairro}
            onChange={handleChangeValues}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="Município"
            disabled
            value={municipio.descricao}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            label="UF"
            disabled
            value={municipio.uf}
            required
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <GreenCheckbox checked={termo} onChange={handleChangeCheckbox} />
            }
            label={
              <>
                Eu li e aceito a{" "}
                <Link
                  to="/politica-de-privacidade"
                  className={classes.linkPolitica}
                >
                  política de privacidade
                </Link>
              </>
            }
          />
          <div>
            <Button
              type="submit"
              className={classes.btnEnviar}
              variant="contained"
            >
              Enviar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormCadastroFornecedor;
