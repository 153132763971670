import React, { useMemo } from "react";
import TableAtleta from "../components/TableAtleta";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { fetchEventoPartidaAtleta } from "../../hooks/getEventoPartidaAtleta";
import { fetchEventoPartidaPorId } from "../../hooks/getEventoPartida";
import { fetchTipoScouts } from "../../hooks/GetTipoScouts";
import _ from "lodash";

function LoadingTable() {
  return (
    <Box>
      <Skeleton variant="text" style={{ height: 70 }} />
      <Skeleton variant="rect" style={{ height: 450 }} />
    </Box>
  );
}

function AtletasSection({ evento, idPartida, esporte }) {
  const { data, isSuccess } = useQuery({
    queryKey: ["EventoPartidaPorId"],
    queryFn: ({ pageParam = { idPartida, esporte } }) =>
      fetchEventoPartidaPorId(pageParam),
  });
  const { data: tiposScouts, isSuccess: isSuccessScouts } = useQuery({
    queryKey: ["TipoScouts"],
    queryFn: ({ pageParam = { esporte } }) => fetchTipoScouts(pageParam),
  });

  return (
    <Box style={{ marginBottom: "5rem" }}>
      {isSuccess && isSuccessScouts ? (
        <HandleAtletas
          esporte={esporte}
          evento={evento}
          partida={data}
          tiposScouts={tiposScouts}
        />
      ) : null}
    </Box>
  );
}

export default AtletasSection;

function HandleAtletas({ evento, partida, tiposScouts, esporte }) {
  const {
    data: atletasCasa,
    isSuccess: isSuccessCasa,
    isLoading: isLoadingCasa,
  } = useQuery(
    ["EventoPartidaAtleta-casa", partida.id_equipe_casa],
    ({
      pageParam = {
        evento,
        idPartida: partida.id,
        idEquipe: partida.id_equipe_casa,
        esporte,
      },
    }) => fetchEventoPartidaAtleta(pageParam)
  );

  const {
    data: atletasVisitante,
    isSuccess: isSuccessVisitante,
    isLoading: isLoadingVisitante,
  } = useQuery(
    ["EventoPartidaAtleta-visitante", partida.id_equipe_visitante],
    ({
      pageParam = {
        evento,
        idPartida: partida.id,
        idEquipe: partida.id_equipe_visitante,
        esporte,
      },
    }) => fetchEventoPartidaAtleta(pageParam)
  );

  const scoutsID = useMemo(() => {
    const scouts = [];
    if (atletasCasa && atletasCasa.length) {
      atletasCasa.forEach((element) => {
        if (element.scouts.length) {
          let teste = element.scouts.map((item) => item.id_scout);
          scouts.push(teste);
        }
      });
    }
    if (atletasVisitante && atletasVisitante.length) {
      atletasVisitante.forEach((element) => {
        if (element.scouts.length) {
          let teste = element.scouts.map((item) => item.id_scout);
          scouts.push(teste);
        }
      });
    }
    const scoutsIds = _.chain(scouts).flatten().uniq().value();
    return scoutsIds;
  }, [atletasCasa, atletasVisitante]);

  const scoutsPartida = useMemo(() => {
    if (scoutsID.length && tiposScouts.length) {
      let _scouts = scoutsID.map((item) => {
        let teste = _.filter(tiposScouts, function (o) {
          return o.id === item;
        });
        return _.orderBy(teste, ["id"], ["asc"]);
      });

      return _.flatten(_scouts);
    }
    return [];
  }, [scoutsID, tiposScouts]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {isLoadingCasa ? <LoadingTable /> : null}
        {isSuccessCasa ? (
          <TableAtleta
            tipo="casa"
            evento={evento}
            idPartida={partida.id}
            idEquipe={partida.id_equipe_casa}
            partida={partida}
            scoutsPartida={scoutsPartida}
            atletas={atletasCasa}
          />
        ) : null}
      </Grid>
      <Grid item xs={12}>
        {isLoadingVisitante ? <LoadingTable /> : null}
        {isSuccessVisitante ? (
          <TableAtleta
            tipo="visitante"
            evento={evento}
            idPartida={partida.id}
            idEquipe={partida.id_equipe_visitante}
            partida={partida}
            scoutsPartida={scoutsPartida}
            atletas={atletasVisitante}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
