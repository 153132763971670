import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';

//img
import pdfIcon from '../../assets/img/icones/icon-pdf.png'
import imgIcon from '../../assets/img/icones/icon-img.png'

import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    fileContainter: {
        padding: '15px 0px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.up('lg')]: {
            padding: '30px 20px',
        }
    },
    fileDenseContainter: {
        display: 'flex',
        padding: '15px 0px',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.up('lg')]: {
            padding: '15px 10px',
        }
    },
    imgContainer: {
        marginRight: '15px',
        '& img': {
            width: '40px'
        }
    },
    imgContainerDense: {
        marginRight: '15px',
        display: 'flex',
        '& img': {
            width: '30px'
        }
    },
    fileUrl: {
        color: theme.palette.primary.main,
        marginTop: '12px',
        fontSize: '1rem',
        lineHeight: '27px',
        fontWeight: 400,
        paddingBottom: '5px',
        textDecoration: 'none',
        '&:hover, &:focus': {
            color: 'gray'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.2rem',
        }
    },
    docTitle: {
        color: theme.palette.primary.main,
        marginTop: '12px',
        fontSize: '1rem',
        lineHeight: '27px',
        fontWeight: 400,
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.2rem',
        }
    },
    fileSize: {
        color: '#323232',
        marginLeft: '10px',
        fontWeight: 500,
        marginTop: '12px'
    },
    formControl: {
        minWidth: 300,
        '& .MuiInputBase-root': {
            borderRadius: 0,
            backgroundColor: 'rgba(255,255,255,0.8)',
            transition: 'all 300ms ease-in-out',
            '&:focus': {
                backgroundColor: 'rgba(255,255,255,1)',
            }
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function ListagemDocumentos(props) {
    const classes = useStyles();
    const documentos = props.documentos;
    const [orderDocs, setOrderDocs] = useState([])
    const dense = props.dense
    const hasFilter = props.hasFilter
    const [isDense, setIsDense] = useState(false)
    const [ano, setAno] = useState("");
    const [filterAnoArray, setFilterAnoArray] = useState([])
    const [yearFilteredDocs, setYearFilteredDocs] = useState([])

    useEffect(() => {
        if (documentos) {
            setOrderDocs(_.orderBy(documentos, 'titulo', 'desc'))
            if (dense) {
                setIsDense(true)
            }

            var anoArr = documentos.map(item => {
                var objAno = (new Date(item.created_at * 1000)).toISOString().substr(0, 4)
                return { ano: objAno }
            })

            setFilterAnoArray(_.uniqBy(anoArr, 'ano'))
        }
    }, [documentos, dense])

    const formatTitle = (title) => {
        if (title) {
            var ordem = title.slice(0, 3)
            if (/\d/.test(ordem)) {
                return title.slice(3, title.length)
            } else {
                return title
            }

        }

    }

    const getIcon = (arquivo) => {

        if (arquivo.nome_arquivo) {
            if (arquivo.nome_arquivo.includes('pdf')) {
                return pdfIcon
            } else if (arquivo.url.includes('pdf')) {
                return pdfIcon
            } else {
                return imgIcon
            }
        } else {
            return imgIcon
        }
    }

    // const localDate = (created_at) => {
    //     var date = new Date(created_at * 1000)
    //     var time = date.toTimeString().substr(0, 5)
    //     var formatedDate = date.toLocaleDateString()
    //     return (formatedDate + ' ' + time)
    // }


    const handleChange = (event) => {
        setAno(event.target.value);
    };

    useEffect(() => {
        if (Number(ano) > 0) {
            var _obj = orderDocs.map(item => {
                var objAno = (new Date(item.created_at * 1000)).toISOString().substr(0, 4)
                var isSelectedYear = false;
                if (Number(objAno) === Number(ano)) {
                    isSelectedYear = true
                }
                return ({ ...item, isSelectedYear })
            })

            var filteredDocs = _.filter(_obj, 'isSelectedYear')

            setYearFilteredDocs(filteredDocs)
        }

    }, [ano, orderDocs])


    const showListDocs = (docsList) => {
        if (Boolean(docsList[0])) {
            return (
                <>
                    {docsList.map((item, i) => (
                        <div key={i} className={isDense ? classes.fileDenseContainter : classes.fileContainter} style={i === 0 ? {} : { borderTop: '1px solid #afafaf' }}>
                            <div className={isDense ? classes.imgContainerDense : classes.imgContainer}>
                                <img src={getIcon(item)} alt="document icon" />
                            </div>
                            <div>
                                {item.url !== '' ?
                                    <a href={item.url} className={classes.fileUrl} target="_blank" rel="noreferrer">{formatTitle(item.titulo)}</a>
                                    :
                                    <React.Fragment>
                                        {item.nome_arquivo ?
                                            <a href={`https://sgefeemg.bigmidia.com/_uploads/orgaoAnexo/${item.nome_arquivo}`} className={classes.fileUrl} target="_blank" rel="noreferrer">{formatTitle(item.titulo)}</a>
                                            :
                                            <span className={classes.docTitle}>{formatTitle(item.titulo)}</span>
                                        }
                                    </React.Fragment>
                                }
                                {/* <span style={{ paddingLeft: '.3rem' }}>
                                    - {localDate(item.created_at)}
                                </span> */}
                            </div>

                        </div>
                    ))}
                </>
            )
        } else {
            return (
                <div style={{ height: '10vh', margin: '25px 0' }}>
                    <Alert severity="info">Não há registros.</Alert>
                </div>
            )
        }

    }


    return (
        <React.Fragment>
            {hasFilter ?
                <>
                    <div>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="ano-select-label">Filtrar por ano:</InputLabel>
                            <Select
                                labelId="ano-select-label"
                                id="ano-select"
                                value={ano}
                                onChange={handleChange}
                                label="Filtrar por ano:"
                            >
                                <MenuItem value="">Todos</MenuItem>
                                {filterAnoArray.map((item, key) => (
                                    <MenuItem key={key} value={item.ano}>{item.ano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    { ano > 0 ? showListDocs(yearFilteredDocs) : showListDocs(orderDocs)}
                </>
                :
                showListDocs(orderDocs)
            }

        </React.Fragment>
    )
}

export default ListagemDocumentos;