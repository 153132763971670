const esportesEspecificacoes = [
  { id: 1, descricao: "Geral" },
  { id: 2, descricao: "Ginástica" },
  {
    id: 3,
    nome: "futsal",
    descricao: "Futsal",
    pontuacao: "Gol",
    pontuacaoAbrev: "G",
  },
  {
    id: 4,
    nome: "handebol",
    descricao: "Handebol",
    pontuacao: "Gol",
    pontuacaoAbrev: "G",
  },
  {
    id: 5,
    nome: "basquete",
    descricao: "Basquete",
    pontuacao: "Ponto",
    pontuacaoAbrev: "P",
  },
  {
    id: 6,
    nome: "volei",
    descricao: "Voleibol",
    pontuacao: "Set",
    pontuacaoAbrev: "S",
  },
  { id: 7, descricao: "Taekwondo" },
  { id: 8, descricao: "Natação" },
  { id: 9, descricao: "Ciclismo" },
  { id: 10, descricao: "Judô" },
  { id: 11, descricao: "Karate" },
  { id: 12, descricao: "Wrestling" },
  { id: 13, descricao: "Atletismo" },
  { id: 14, descricao: "Tênis de Mesa" },
  { id: 15, descricao: "Xadrez" },
  { id: 17, descricao: "Volei de Praia" },
  { id: 18, descricao: "Wushu" },
  {
    id: 19,
    descricao: "Futebol de Campo",
    pontuacao: "Gol",
    pontuacaoAbrev: "G",
  },
];

export default esportesEspecificacoes;
