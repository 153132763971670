import React from "react";
import RowTempo from "./TableRows/RowTempo";
import RowPonto from "./TableRows/RowPonto";

function RowHandler({ scout, eventoPartida }) {
  if (scout.scout && scout.scout.tipo_render) {
    if (scout.scout.tipo_render === "tempo") {
      return <RowTempo scoutTimeline={scout} partida={eventoPartida} />;
    }
    return <RowPonto scout={scout} />;
  }
  
  return null;
}

export default RowHandler;
