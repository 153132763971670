import axios from "axios";
import { ApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchEventoStaff(props) {
  const { evento, idPartida, idEquipe } = props;
  const _api = ApiEsporte[evento.id_esporte] || ApiEsporte[1];

  const res = await axios.get(`${baseURL}/${_api}/api/evento-staff`, {
    params: {
      forcewhere: `id_partida:${idPartida},id_equipe:${idEquipe}`,
      expand: "scout,pessoa,funcao,estabelecimento",
      flag_del: 0,
    },
  });
  const data = res.data.items;
  return data;
}

export async function fetchStaff(props) {
  const { apiEsporte, params } = props;

  const res = await axios.get(`${baseURL}/${apiEsporte}/api/evento-staff`, {
    params: { ...params },
  });
  const data = res.data.items;
  return data;
}
