import React, { useEffect, useRef, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { gestaoApi } from '../../../../services/api';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import { PillButtonSuccess } from '../../../../components/Styles';
import TableTecnico from '../Tecnico/TableTecnico';

const useStyles = makeStyles((theme) => ({
    tableHead: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        '& .MuiTableCell-root': {
            fontSize: '1.1rem',
            fontWeight: 600,
            paddingBottom: '.5rem'
        }
    },
    linkPessoa: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover ': {
            color: theme.palette.primary.main
        }
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    formControl: {
        marginBottom: theme.spacing(3),
        minWidth: 200,
        maxWidth: 500
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'unset',
        '& a': {
            marginTop: 0
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
        },
    },
    avatarContainer: {
        display: 'flex',
        flexFlow: 'row'
    }
}));

export default function Tecnicos(params) {
    const classes = useStyles();

    const { escolaId, slugEscola, aluno_page, queryParam, t_page, t_queryParam } = params

    const [pessoas, setPessoas] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState(t_queryParam);

    let history = useHistory();
    const sectionTop = useRef(null);
    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: sectionTop.current.offsetTop - 350 });

    useEffect(() => {
        if (escolaId) {
            async function getTecnicos() {
                await gestaoApi.get('pessoa', {
                    params: {
                        id_estabelecimento: escolaId,
                        id_departamento: 1,
                        id_tipo: 30,
                        sort: 'nome_completo',
                        flag_del: 0,
                        nome_completo: t_queryParam,
                        page: t_page
                    },
                }).then((response) => {
                    setTotalPages(response.data._meta.pageCount)
                    setPessoas(response.data.items)
                });
            }
            getTecnicos()
        }
    }, [escolaId, t_page, t_queryParam])

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch()

        }
    }

    const handleSearch = () => {
        if (searchTerm !== "") {
            history.push(`/feemg/escola/${escolaId}/${slugEscola}${queryParam ? `?q=${queryParam}&` : '?'}${aluno_page ? `p=${aluno_page}&` : ''}t_q=${searchTerm}`);
        } else {
            history.push(`/feemg/escola/${escolaId}/${slugEscola}${queryParam ? `?q=${queryParam}&` : '?'}${aluno_page ? `p=${aluno_page}&` : ''}`);
        }

    }

    useEffect(() => {
        if (t_queryParam || t_page) {
            executeScroll();
        }
    }, [t_queryParam, t_page])

    return (
        <div style={{ padding: '0 0  20px 0' }}>
            <div ref={sectionTop}></div>
            <div className={classes.formControl}>
                <div className={classes.inputGroup}>
                    <TextField label="Pesquisar técnico" size="small" style={{ marginRight: '.4rem' }} fullWidth={true} value={searchTerm} onKeyDown={(e) => handleSearchKeyDown(e)} onChange={(e) => setSearchTerm(e.target.value)} variant="filled" />
                    <div>
                        {searchTerm ?
                            <Link component={PillButtonSuccess} className={classes.searchButton} to={`/feemg/escola/${escolaId}/${slugEscola}${queryParam ? `?q=${queryParam}&` : '?'}${aluno_page ? `p=${aluno_page}&` : ''}${searchTerm !== '' || searchTerm !== null ? `t_q=${searchTerm}` : ''}`} variant="contained" disableElevation>Pesquisar</Link>
                            :
                            <Link component={PillButtonSuccess} className={classes.searchButton} to={`/feemg/escola/${escolaId}/${slugEscola}${queryParam ? `?q=${queryParam}&` : '?'}${aluno_page ? `p=${aluno_page}&` : ''}`} variant="contained" disableElevation>Pesquisar</Link>
                        }
                    </div>
                </div>
            </div>


            <TableTecnico pessoas={pessoas} escolaId={escolaId} slugEscola={slugEscola} />

            <div className={classes.paginationContainer}>
                <Route>
                    {({ location }) => {
                        const query = new URLSearchParams(location.search);
                        const page = parseInt(query.get('t_p') || '1', 10);
                        return (
                            <Pagination
                                page={page}
                                count={totalPages}
                                renderItem={(item) => {
                                    if (!t_queryParam) {
                                        return (
                                            <PaginationItem
                                                component={Link}
                                                to={`/feemg/escola/${escolaId}/${slugEscola}?${queryParam ? `q=${queryParam}&` : ''}${aluno_page ? `p=${aluno_page}&` : ''}${item.page === 1 ? '' : `t_p=${item.page}`}`}
                                                {...item}
                                            />
                                        )
                                    } else {
                                        return (
                                            <PaginationItem
                                                component={Link}
                                                to={`/feemg/escola/${escolaId}/${slugEscola}${queryParam ? `?q=${queryParam}&` : '?'}${aluno_page ? `p=${aluno_page}&` : ''}${t_queryParam ? `t_q=${t_queryParam}&` : ''}${item.page === 1 ? '' : `t_p=${item.page}`}`}
                                                {...item}
                                            />
                                        )
                                    }

                                }
                                }
                            />
                        );
                    }}
                </Route>
            </div>
        </div>
    )
}