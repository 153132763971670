import axios from "axios";
import { esporteApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchEventoPartidaWOScout(props) {
  const { esporte, idPartida } = props;
  const _api = esporteApiEsporte[esporte]

  if (_api === "cbfutsal") {
    const res = await axios.get(
      `${baseURL}/cbfutsal/api/evento-partida-scout`,
      {
        params: {
          id_partida: idPartida,
          id_scout: 17,
          expand: "scout",
        },
      }
    );
    const data = res.data.items;
    return data;
  }
  return null;
}
