import { useState } from 'react'
import ReactFlow from 'react-flow-renderer'
import MetaTags from 'react-meta-tags'

//material-ui
import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

//components
import PageTitle from '../../../components/PageTitle'
import { OrganogramaNodes } from './OrganogramaChaves'
import OrganogramaDialog from './OrganogramaDialog'
import { useStyles } from './styles'

export default function Organograma() {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [pessoa, setPessoa] = useState({})
    const [modalPessoas, setModalPessoas] = useState([])
    const [openDialog, setOpenDialog] = useState(false)

    const handleClick = (event, _pessoa) => {
        setAnchorEl(event.currentTarget)
        setPessoa(_pessoa)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    // modal
    const handleClickOpenDialog = (obj) => {
        setOpenDialog(true)
        setModalPessoas(obj)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const organorgramaElm = OrganogramaNodes({ handleClick, handleClickOpenDialog })

    return (
        <>
            <MetaTags>
                <title>Organograma - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="FEEMG" pageTitle="Organograma" />
            <Paper square>
                <Container maxWidth="xl" style={{ overflow: 'auto', padding: '30px 0' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <div className={classes.root}>
                                <ReactFlow
                                    elements={organorgramaElm}
                                    nodesDraggable={false}
                                    selectNodesOnDrag={false}
                                    zoomOnScroll={false}
                                    paneMoveable={false}
                                    nodesConnectable={false}
                                    snapToGrid
                                />
                            </div>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                elevation={3}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <div className={classes.PopoverRoot}>
                                    {pessoa ?
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ marginRight: '10px' }}>
                                                <Avatar variant="rounded" src={pessoa.foto} alt={pessoa.nome} className={classes.bigAvatar} />
                                            </div>
                                            <div >
                                                <Typography className={classes.CardTitulo}>{pessoa.nome}</Typography>
                                                <div className={classes.cardText}>
                                                    <Typography variant="subtitle2" color="textSecondary">{pessoa.registroProfissional}</Typography>
                                                    <Typography variant="subtitle2" color="textSecondary">{pessoa.cargo}</Typography>
                                                    <Typography variant="subtitle2" color="textSecondary">{pessoa.email}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </Popover>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
            <OrganogramaDialog open={openDialog} handleClose={handleCloseDialog} modalPessoas={modalPessoas} />
        </>
    )
}