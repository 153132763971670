import React, { useState, useEffect } from "react";
import Slider from "react-slick";

// - api
import { portalApi } from "../../../services/api";
//css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Container from "@material-ui/core/Container";

//images
import { makeStyles } from "@material-ui/core/styles";
import arrowLeft from "../../../assets/img/arrow-left.png";
import arrowRight from "../../../assets/img/arrow-right.png";

import noticia670 from "../../../assets/img/noticias/670.png";

//data
// import noticias from '../../../assets/data/noticias';

import { Link } from "react-router-dom";

import { SquareButton } from "../../../components/Styles";
import { slugNoticia } from "../../../util/formatString";

const useStyles = makeStyles((theme) => ({
  cardNoticias: {
    backgroundColor: "rgba(255,255,255,0.9)",
    margin: "0 15px",
    position: "relative",
    overflow: "auto",
    padding: "0 10px 10px 10px",
    [theme.breakpoints.up("sm")]: {
      margin: "0 5px",
    },
  },
  cardTitle: {
    textDecoration: "none",
    "& h3": {
      color: theme.palette.primary.main,
      fontSize: "20px",
      lineHeight: "1",
      marginBottom: "0px",
      marginTop: "10px",
      paddingBottom: "5px",
      fontWeight: "700",
      textTransform: "uppercase",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  resumo: {
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#323232",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cardImgContainer: {
    maxHeight: 158,
    overflow: "hidden",
  },
  cardImg: {
    width: "100%",
    overflow: "hidden",
    height: "auto",
    objectFit: "cover",
    maxHeight: 158,

    [theme.breakpoints.up("md")]: {
      maxHeight: 118,
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: 158,
    },
  },
  date: {
    color: "#fff",
    backgroundColor: "#7b7b7b",
    display: "inline-block",
    fontSize: "14px",
    fontWeight: 500,
    padding: "5px",
  },
  slider: {
    width: "95%",
    margin: "auto",
    position: "static",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        height: 100,
        width: 50,
        backgroundImage: `url(${arrowRight})`,
        filter: "drop-shadow(0px 0px 4px black)",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        zIndex: 10,
        height: 100,
        width: 50,
        backgroundImage: `url(${arrowLeft})`,
        filter: "drop-shadow(0px 0px 4px black)",
      }}
      onClick={onClick}
    />
  );
}

export default function CarouselNoticias() {
  const classes = useStyles();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    async function getNews() {
      await portalApi
        .get("noticia", {
          params: {
            flag_del: 0,
            flag_destaque: 1,
            flag_publicado: 1,
             id_estabelecimento: 26,
            sort: "-created_at",
            lessField: "created_at",
            lessValue: new Date().getTime().toString().substr(0, 10),
            pageSize: 12,
          },
        })
        .then((response) => {
          setNewsList(response.data.items);
        });
    }
    getNews();
  }, []);

  const imgFallback = (e) => {
    e.target.src = noticia670;
  };

  const localDate = (created_at) => {
    var date = new Date(created_at * 1000);
    var formatedDate = date.toLocaleDateString();
    return formatedDate;
  };

  return (
    <div style={{ position: "relative", margin: "30px auto ", maxWidth: 1450 }}>
      <Container>
        <Slider {...settings} className={classes.slider}>
          {newsList.map((noticia, i) => {
            if (i > 2) {
              return (
                <div key={i}>
                  <div className={classes.cardNoticias}>
                    <div className={classes.date}>{localDate(noticia.created_at)}</div>
                    <Link
                      className={classes.cardTitle}
                      to={`/noticias/leitura/${noticia.id}/${slugNoticia(noticia.titulo)}`}
                    >
                      <h3>{noticia.titulo}</h3>
                    </Link>
                    <div className={classes.resumo}>{noticia.resumo}</div>
                    <div className={classes.cardImgContainer}>
                      <img
                        src={`https://sgefeemg.bigmidia.com/_uploads/26/noticias/${
                          noticia.foto_capa
                        }?time=${new Date().getTime()}`}
                        onError={(e) => imgFallback(e)}
                        className={classes.cardImg}
                        alt={noticia.titulo}
                      />
                    </div>
                    <SquareButton
                      component={Link}
                      to={`/noticias/leitura/${noticia.id}/${slugNoticia(noticia.titulo)}`}
                      fullWidth={true}
                    >
                      Ver detalhe
                    </SquareButton>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </Slider>
      </Container>
    </div>
  );
}
