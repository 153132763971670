import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 900,
        fontSize: '1.55rem',
        textTransform: 'uppercase'
    },
}));

export default function FaleConosco() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography className={classes.title} variant="h4" component="h4" color="primary">FEEMG</Typography>
            <p>
                Rua Boaventura, nº 754, bairro Indaiá - Belo Horizonte/MG.<br />
                CEP: 31270-020.<br />
                Tel: (31) 2512-7300.
            </p>
            <Typography className={classes.title} variant="h4" component="h4" color="primary">HORÁRIOS</Typography>
            <p>
                De segunda-feira a sexta-feira: Das 8:00 às 18:00 horas.
            </p>
        </React.Fragment>
    )
}
