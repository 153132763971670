import React, { useState, useEffect } from "react";
//fullcalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import { INITIAL_EVENTS, createEventId } from './event-utils'
import ptBRLocale from "@fullcalendar/core/locales/pt-br";

import { useHistory } from "react-router-dom";
import slugNormalize from "../../util/slugNormalize";

//material-ui
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Tooltip from "@material-ui/core/Tooltip";

//api
import { CBDE_api, gestaoApi } from "../../services/api";

//components
import PageTitle from "../../components/PageTitle";

import MetaTags from "react-meta-tags";
import moment from "moment";
import clsx from "clsx";
import { Box, Chip, Typography } from "@material-ui/core";
import esportesEspecificacoes from "../../assets/data/esporte/esportesEspecificacoes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "1rem",
    "& .calendar": {
      "& .fc-toolbar-title": {
        "&::first-letter": {
          textTransform: "uppercase",
        },
      },
      "& .fc .fc-col-header-cell-cushion": {
        textTransform: "capitalize",
      },
      "& .fc-v-event .fc-event-main": {
        display: "flex",
      },
      "& .fc-timegrid-event span span": {
        display: "none",
      },
      "& .fc-media-screen .fc-timegrid-event": {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 4,
      },
      "& a.fc-event, a.fc-event:hover": {
        opacity: 0.9,
      },
      "& .fc-timegrid-event .fc-event-main span": {
        whiteSpace: "unset",
      },

      "& .fc-button-primary": {
        backgroundColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
      "& .fc--button.fc-button.fc-button-primary": {
        display: "none",
      },
      "& .fc-button-primary.fc-button-active, .fc-button-primary:active": {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      "& .fc .fc-button-primary:focus, .fc .fc-button-primary.fc-button-active:focus ": {
        boxShadow: "0 0 0 0.2rem rgb(218, 37,28,0.5)",
      },
      "& .fc-header-toolbar": {
        display: "flex",
        flexDirection: "column-reverse",
        padding: "5px",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
        },
      },
      "& .fc-h-event": {
        marginBottom: 2,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  container: {
    paddingTop: "20px",
    paddingBottom: "50px",
  },
  eventItem: {
    padding: 2,
    margin: 2,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderRadius: 4,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&.partida-futsal": {
      backgroundColor: theme.palette.futsal.main,
      color: theme.palette.futsal.contrastText,
    },
    "&.partida-volei": {
      backgroundColor: theme.palette.volei.main,
      color: theme.palette.volei.contrastText,
    },
    "&.partida-basquete": {
      backgroundColor: theme.palette.basquete.main,
      color: theme.palette.basquete.contrastText,
    },
    "&.partida-handebol": {
      backgroundColor: theme.palette.handebol.main,
      color: theme.palette.handebol.contrastText,
    },
  },
  chipWapper: {
    "& .MuiChip-root": {
      marginRight: 5,
      "&.evento": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      "&.futsal": {
        backgroundColor: theme.palette.futsal.main,
        color: theme.palette.futsal.contrastText,
      },
      "&.volei": {
        backgroundColor: theme.palette.volei.main,
        color: theme.palette.volei.contrastText,
      },
      "&.basquete": {
        backgroundColor: theme.palette.basquete.main,
        color: theme.palette.basquete.contrastText,
      },
      "&.handebol": {
        backgroundColor: theme.palette.handebol.main,
        color: theme.palette.handebol.contrastText,
      },
    },
  },
  eventDate: {
    fontSize: "11px",
  },
}));

const BigTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

const esportes = [
  { label: "Evento" },
  { label: "Basquete" },
  { label: "Futsal" },
  { label: "Handebol" },
  { label: "Volei" },
];

export default function CalendarioEventos(params) {
  const classes = useStyles();
  const isArbitragem = params.arbitragem;

  const [datas, setDatas] = useState([]);
  const [orderDatas, setOrderDatas] = useState([]);

  const [canGetEvents, setCanGetEvents] = useState(false);

  const [date1, setDate1] = useState(`${moment().subtract(1, "months").format("YYYY-MM")}-01`);
  const [date2, setDate2] = useState(`${moment().add(1, "months").format("YYYY-MM")}-12`);

  function renderEventContent(eventInfo) {
    var time = new Date(eventInfo.event._def.extendedProps.data_inicio).toLocaleTimeString().substr(0, 5);
    let tipo_evento = !isArbitragem ? eventInfo.event._def.extendedProps.tipo : "";
    return (
      <BigTooltip arrow enterDelay={250} title={eventInfo.event.title}>
        <span className={clsx(classes.eventItem, `${tipo_evento}`)}>
          {eventInfo.timeText ? <span className={classes.eventDate}>{time} - </span> : ""}
          {eventInfo.event.title}
        </span>
      </BigTooltip>
    );
  }

  useEffect(() => {
    if (canGetEvents) {
      if (isArbitragem) {
        async function getEventsArbitragem() {
          await gestaoApi
            .get("evento", {
              params: {
                flag_del: 0,
                id_tipo: 3,
                id_estabelecimento: 26,
                sort: "-data_inicio",
                betweenField: "data_inicio",
                betweenValue1: date1,
                betweenValue2: date2,
                flag_oculto: 0,
                pageSize: 50,
              },
            })
            .then((response) => {
              setDatas(response.data.items);
            });
        }
        getEventsArbitragem();
      } else {
        async function getEventos() {
          await CBDE_api.get("evento/calendario", {
            params: {
              id_estabelecimento: 26,
              sort: "-data_inicio",
              flag_oculto: 0,
              data_inicio: date1,
              data_fim: date2,
              pageSize: 500,
            },
          }).then((response) => {
            setDatas(response.data.items);
          });
        }
        getEventos();
      }
    }
  }, [isArbitragem, canGetEvents, date1, date2]);

  useEffect(() => {
    var _orderdatas = [];
    if (datas) {
      _orderdatas = datas.map((item, key) => {
        return {
          ...item,
          title: item.descricao,
          start: item.data_inicio,
          end: item.data_fim,
        };
      });

      setOrderDatas(_orderdatas);
    }
  }, [datas]);

  let history = useHistory();

  const eventInfo = (info) => {
    const eventoData = info.event._def.extendedProps
    const esporte = esportesEspecificacoes.find((e) => e.id === parseInt(eventoData.id_esporte));
    const slugEsporte = slugNormalize(esporte.descricao) || 'geral'

    if (eventoData.id_partida && eventoData.id_partida !== 0) {
      return history.push(`/feemg/${slugEsporte}/${eventoData.id_evento}/${slugNormalize(eventoData.descricao)}/partida/${eventoData.id_partida}`);
    }
    if (isArbitragem) {
      return history.push(`/feemg/${slugEsporte}/evento/${info.event.id}/${slugNormalize(eventoData.descricao)}`);
    } else {
      return history.push(`/feemg/${slugEsporte}/evento/${eventoData.id_evento}/${slugNormalize(eventoData.descricao)}`);
    }
  };

  const handlechangeMonth = (info) => {
    setDate1(moment(info.start).format("YYYY-MM-DD"));
    setDate2(moment(info.end).format("YYYY-MM-DD"));
  };

  const legenda = () => {
    return (
      <Box style={{ margin: "15px 0" }}>
        <Typography variant="caption">Legenda: </Typography>
        <Box className={classes.chipWapper}>
          {esportes.map((item, key) => (
            <Chip size="small" className={item.label.toLowerCase()} label={item.label} />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {isArbitragem ? "Calendário de Reuniões" : "Calendário"} - FEEMG - Federação de Esportes Estudantis de Minas
          Gerais
        </title>
      </MetaTags>
      <PageTitle menuTitle={isArbitragem ? "Arbitragem" : "FEEMG"} pageTitle="Calendário" />
      <Paper square elevation={0} className={classes.root}>
        <Container maxWidth="lg" className={classes.container}>
          {!isArbitragem && legenda()}
          <div className="demo-app">
            <div className="demo-app-sidebar"></div>
            <div className="calendar" style={{ maxWidth: "100%", overflowX: "auto" }}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                locale={ptBRLocale}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={(info, successCallback) => {
                  handlechangeMonth(info);
                  setCanGetEvents(true);
                  successCallback(orderDatas);
                }}
                eventContent={renderEventContent}
                eventClick={eventInfo}
              />
            </div>
          </div>
          {!isArbitragem && legenda()}
        </Container>
      </Paper>
    </React.Fragment>
  );
}
