import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";

// - api
import { gestaoApi } from "../../services/api";

import { Link, useHistory} from "react-router-dom";

//material-ui
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

//components
import PageTitle from "../../components/PageTitle";
import { PillButtonSuccess } from "../../components/Styles";
import EventList from "./EventList";

import _ from "lodash";
import moment from "moment";
import MetaTags from "react-meta-tags";

// data
import esportesEspecificacoes from "../../assets/data/esporte/esportesEspecificacoes";
import { temporadasDesde } from "../../util/temporadas";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 0px",
  },
  Card: {
    maxWidth: "100%",
    height: "100%",
    overflow: "hidden",
  },

  paginationContainer: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 100,
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.up("lg")]: {
      flexWrap: "unset",
      "& a": {
        marginTop: 0,
      },
    },
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "2rem",
    paddingBottom: "4rem",
  },
  errorContainer: {
    paddingTop: "1rem",
    paddingBottom: "4rem",
  },
  pageTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: 900,
  },
}));

function Competicoes(params) {
  const classes = useStyles();
  const page = params.page;
  const queryParam = params.searchTerm;
  const temporada = params.temporada ? params.temporada : moment().format('YYYY');
  const escopo = params.escopo;
  const esporte = params.esporte;

  const [eventList, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchTerm, setSearchTerm] = useState(queryParam);
  const [escopoEvento, setEscopoEvento] = useState(escopo);
  const [temporadaEvento, setTemporadaEvento] = useState(temporada);
  const [esporteEvento, setEsporteEvento] = useState(esporte);
  const [eventoFinalizado, setEventoFinalizado] = useState(false);

  useEffect(() => {
    let data1 = "";
    let data2 = "";
    if (temporada) {
      data1 = temporada.concat("-01-01");
      data2 = temporada.concat("-12-31");
    }

    setLoading(true);
    setError(false);

    let params = {
      flag_del: 0,
      flag_oculto: 0,
      //id_tipo: 1,
      id_estabelecimento: 26,
      notField: "id_esporte",
      notValue: 1,
      id_esporte: esporteEvento,
      sort: "-data_inicio_inscricao",
      escopo: escopo,
      descricao: queryParam,
      betweenField: "data_inicio",
      betweenValue1: data1,
      betweenValue2: data2,
      page: page,
    };

    if (eventoFinalizado) {
      if (eventoFinalizado !== "todos") {
        params = {
          ...params,
          lessField: "data_fim",
          lessValue: moment().format("YYYY-MM-DD"),
        };
      }
    } else {
      params = {
        ...params,
        moreField: "data_fim",
        moreValue: moment().format("YYYY-MM-DD"),
      };
    }

    async function getEvents() {
          await gestaoApi
        .get("evento", {
          params: params,
        })
        .then((response) => {
          setList(response.data.items);
          setTotalPages(response.data._meta.pageCount);
          if (response.data._meta.totalCount < 1) {
            setError(true);
          }
          executeScroll();
        });
    }
    getEvents();

    setLoading(false);
  }, [page, queryParam, escopo, temporada, esporteEvento, eventoFinalizado]);

  const handleChangeEscopo = (event) => {
    setEscopoEvento(event.target.value);
    if (queryParam || esporteEvento || page || temporada) {
      history.push(
        `/competicoes${temporada ? `?temporada=${temporada}&` : "?"}${
          event.target.value ? `escopo=${event.target.value}&` : ""
        }${esporteEvento ? `esporte=${esporteEvento}` : ""}${queryParam ? `q=${queryParam}&` : ""}${
          page ? `p=${page}` : ""
        }`
      );
    } else {
      history.push(`/competicoes${event.target.value ? `?escopo=${event.target.value}` : ""}`);
    }
  };

  const handleChangeEsporte = (event) => {
    setEsporteEvento(event.target.value);
    if (queryParam || escopo || page || temporada) {
      history.push(
        `/competicoes${temporada ? `?temporada=${temporada}&` : "?"}${escopo ? `escopo=${escopo}` : ""}${
          event.target.value ? `esporte=${event.target.value}` : ""
        }${queryParam ? `&q=${queryParam}` : ""}${page ? `&p=${page}` : ""}`
      );
    } else {
      history.push(`/competicoes${event.target.value ? `?escopo=${event.target.value}` : ""}`);
    }
  };

  const handleChangeStatusEvento = (event) => {
    setEventoFinalizado(event.target.value);
  };

  const handleChangeTemporada = (event) => {
    setTemporadaEvento(event.target.value);
    if (queryParam || esporteEvento || page || escopo) {
      history.push(
        `/competicoes${event.target.value ? `?temporada=${event.target.value}&` : "?"}${
          escopo ? `escopo=${escopo}` : ""
        }${esporteEvento ? `esporte=${esporteEvento}` : ""}${queryParam ? `&q=${queryParam}` : ""}${
          page ? `&p=${page}` : ""
        }`
      );
    } else {
      history.push(`/competicoes${event.target.value ? `?temporada=${event.target.value}` : ""}`);
    }
  };

  const executeScroll = () => window.scrollTo({ behavior: "smooth", top: 0 });

  let history = useHistory();
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm !== "") {
        history.push(`/competicoes?q=${searchTerm}`);
      } else {
        history.push("/competicoes");
      }
    }
  };

  return (
    <>
      <MetaTags>
        <title>Competições - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
      </MetaTags>

      <PageTitle menuTitle="FEEMG" pageTitle="Competições" />

      <Paper className={classes.root} square>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
                <InputLabel>Escopo:</InputLabel>
                <Select value={escopoEvento} onChange={handleChangeEscopo} label="Escopo">
                  <MenuItem value={""}>Todos</MenuItem>
                  <MenuItem value={"municipal"}>Municipal</MenuItem>
                  <MenuItem value={"metropolitano"}>Metropolitano</MenuItem>
                  <MenuItem value={"estadual"}>Estadual</MenuItem>
                  <MenuItem value={"nacional"}>Nacional</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
                <InputLabel>Temporada:</InputLabel>
                <Select value={temporadaEvento} onChange={handleChangeTemporada} label="Temporada">
                  <MenuItem value={""}>Todas</MenuItem>
                  {temporadasDesde('2022').map(item => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
                <InputLabel>Esporte:</InputLabel>
                <Select value={esporteEvento} onChange={handleChangeEsporte} label="Esporte">
                  <MenuItem value={""}>Todos</MenuItem>
                  {_.orderBy(esportesEspecificacoes, ["descricao"], ["asc"]).map((item, key) => (
                    <MenuItem key={key} value={item.id}>
                      {item.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
                <InputLabel>Status:</InputLabel>
                <Select value={eventoFinalizado} onChange={handleChangeStatusEvento} label="Status">
                  <MenuItem value="todos">Todos</MenuItem>
                  <MenuItem value={true}>Finalizados</MenuItem>
                  <MenuItem value={false}>Não Finalizados</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={8}>
              <div className={classes.formControl}>
                <div className={classes.inputGroup}>
                  <TextField
                    label="Pesquisar"
                    size="small"
                    style={{ marginRight: ".4rem" }}
                    fullWidth={true}
                    value={searchTerm}
                    onKeyDown={(e) => handleSearchKeyDown(e)}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    variant="outlined"
                  />
                  <div>
                    {!searchTerm ? (
                      <Link
                        component={PillButtonSuccess}
                        className={classes.searchButton}
                        to="/competicoes"
                        variant="contained"
                        disableElevation
                      >
                        Pesquisar
                      </Link>
                    ) : (
                      <Link
                        component={PillButtonSuccess}
                        className={classes.searchButton}
                        to={`/competicoes${searchTerm !== "" || searchTerm !== null ? `?q=${searchTerm}` : ""}`}
                        variant="contained"
                        disableElevation
                      >
                        Pesquisar
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          {loading ? (
            <div className={classes.spinnerContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {error ? (
                <div className={classes.errorContainer}>
                  <Alert severity="error">A pesquisa não retornou resultados.</Alert>
                </div>
              ) : (
                <>
                  <EventList eventList={eventList} />

                  <div className={classes.paginationContainer}>
                    <Route>
                      {({ location }) => {
                        const query = new URLSearchParams(location.search);
                        const page = parseInt(query.get("p") || "1", 10);

                        return (
                          <Pagination
                            page={page}
                            count={totalPages}
                            renderItem={(item) => {
                              if (queryParam || escopo || temporada) {
                                return (
                                  <PaginationItem
                                    component={Link}
                                    to={`/competicoes?${temporada ? `temporada=${temporada}&` : ""}${
                                      escopo ? `escopo=${escopo}&` : ""
                                    }${queryParam ? `q=${queryParam}&` : ""}${item.page === 1 ? "" : `p=${item.page}`}`}
                                    {...item}
                                  />
                                );
                              } else {
                                return (
                                  <PaginationItem
                                    component={Link}
                                    to={`/competicoes${item.page === 1 ? "" : `?p=${item.page}`}`}
                                    {...item}
                                  />
                                );
                              }
                            }}
                          />
                        );
                      }}
                    </Route>
                  </div>
                </>
              )}
            </>
          )}
        </Container>
      </Paper>
    </>
  );
}

export default Competicoes;
