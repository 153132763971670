import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "25px",
  },
  cardTitle: {
    fontWeight: 900,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  imgWraper: {
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "100%",
    width: "100%",
    paddingBottom: "100%",
    maxHeight: 250,
  },
  btnWrapper: {
    textAlign: "center",
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 10,
    "& .MuiButton-root": {
      borderRadius: 50,
      padding: "4px 12px",
      "&.color-default": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
          color: theme.palette.primary.main,
          boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.primary.contrastText,
        },
        "&.MuiButton-outlined": {
          backgroundColor: "unset",
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        },
      },
      "&.color-primary": {
        backgroundColor: theme.palette.vidaAtleta.main,
        color: theme.palette.vidaAtleta.contrastText,
        "&:hover": {
          color: theme.palette.vidaAtleta.main,
          boxShadow: `inset 0px 0px 0px 1px ${theme.palette.vidaAtleta.main}`,
          backgroundColor: theme.palette.vidaAtleta.contrastText,
        },
        "&.MuiButton-outlined": {
          backgroundColor: "unset",
          borderColor: theme.palette.vidaAtleta.main,
          color: theme.palette.vidaAtleta.main,
        },
      },
      "&.color-accent": {
        backgroundColor: theme.palette.vidaAtletaVerde.main,
        color: theme.palette.vidaAtleta.main,
        "&:hover": {
          color: theme.palette.vidaAtletaVerde.contrastText,
          borderColor: theme.palette.vidaAtletaVerde.main,
          backgroundColor: theme.palette.vidaAtleta.main,
        },
        "&.MuiButton-outlined": {
          backgroundColor: "unset",
          borderColor: theme.palette.vidaAtletaVerde.main,
          color: theme.palette.vidaAtletaVerde.main,
        },
      },
      "&.color-secondary": {
        backgroundColor: theme.palette.vidaAtletaRoxo.main,
        color: theme.palette.vidaAtletaRoxo.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.vidaAtletaAzul.main,
          color: theme.palette.vidaAtletaRoxo.main,
        },
        "&.MuiButton-outlined": {
          backgroundColor: "unset",
          borderColor: theme.palette.vidaAtletaRoxo.main,
          color: theme.palette.vidaAtletaRoxo.main,
        },
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiButton-root": {
        padding: "4px 20px",
      },
    },
  },
  btnContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 15,
  },
}));
