import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    root: {
        height: 1500,
        width: 1280,
        margin: 'auto',
        overflow: 'auto',
        '& .react-flow__node': {
            cursor: 'default !important',
        },
        '& .react-flow__node ': {
            border: 'none',
            padding: 0,
            boxShadow: ' none !important',
            width: '120px'
        },
        '& .react-flow__handle': {
            backgroundColor: '#1a192b',
        },
        //   '& .selectable:hover': {
        //       boxShadow: 'none !important'
        //   },
        //   '& [data-id="interaction-10"]': {
        //       width: '210px'
        //   },
        //   '& [data-id="interaction-11"]': {
        //       width: '600px'
        //   },
        //   '& [data-id="interaction-12"]': {
        //       transform: 'translate(720px, 16px) !important'
        //   },
        //   '& [data-id="interaction-13"]': {
        //       transform: 'translate(720px, 156px) !important'
        //   },
        //   '& .react-flow__handle-bottom, & .react-flow__handle-top ': {
        //       backgroundColor: 'transparent',
        //       border: 'none'
        //   },

    },
    CardOrganogramaLabel: {
        backgroundColor: '#f2f2f2',
        border: '1px solid #d9d9d9',
        fontWeight: 'bold',

    },
    CardOrganogramaAction: {
        padding: theme.spacing(1),
    },
    CardOrganograma: {
        borderRadius: '6px',
        overflow: 'hidden',
    },
    CardTitulo: {
        fontSize: '.9rem',
        fontWeight: 500,
    },
    cardText: {
        '& .MuiTypography-subtitle2': {
            fontSize: '.75rem',
            lineHeight: 1.7
        }
    },
    TituloOrganograma: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '6px 4px',

        fontSize: '12px',
        fontWeight: 500,
    },
    imgContainer: {
        padding: 5,
        '& img': {
            padding: '0 3px',
            height: 50,
            cursor: 'pointer',
        }
    },
    Avatar: {
        display: 'inline-block',
        margin: 3,
        width: 50,
        height: 50,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.75,
        }
    },
    bigAvatar: {
        margin: 3,
        width: 80,
        height: 110,
    },
    PopoverRoot: {
        padding: theme.spacing(1),
    },
}))

export const itemWidth = 100
export const organogramaItem = {
    padding: 0,
    border: 'none',
    width: itemWidth
}

export const organogramaSyles = makeStyles((theme) => ({
    nodeDep: {
        color: 'white',
        backgroundColor: '#da251c',
        textAlign: 'center',
        border: '1px solid #dedede',
        padding: theme.spacing(1),
        width: itemWidth,
        '& .MuiTypography-root': {
            fontWeight: 700,
            fontFamily: '"Montserrat", sans-serif',
            fontSize: 9,
            textAlign: 'center',
            textTransform: 'uppercase',
            lineHeight: 1.2,
            letterSpacing: '.1em'
        }
    },
    nodePessoa: {
        textAlign: 'center',
        border: '1px solid #dedede',
        width: itemWidth,
        '& .MuiButtonBase-root': {
            padding: theme.spacing(0.7),
        },
        '& .MuiTypography-root': {
            fontSize: 10,
            fontWeight: 600,
            fontFamily: "'Nunito Sans', sans-serif",
            textAlign: 'center',
            lineHeight: 1.1,
            letterSpacing: '.025em'
        }
    },
    nodeModal: {
        textAlign: 'center',
        border: '1px solid #dedede',
        width: itemWidth,
        '& .MuiButtonBase-root': {
            padding: theme.spacing(0.75),
            backgroundColor: theme.palette.grey[200]
        },
        '& .MuiButtonBase-root:hover': {
            backgroundColor: theme.palette.grey[300]
        },
        '& .MuiButtonBase-root:active': {
            backgroundColor: theme.palette.grey[400]
        },
        '& .MuiTypography-root': {
            fontSize: 10,
            fontWeight: 700,
            fontFamily: "'Montserrat', sans-serif",
            textAlign: 'center',
            lineHeight: 1.1,
            letterSpacing: '.025em'
        }
    },
}))

