import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Capitalize from "../../../../util/Capitalize";
import slugNormalize from "../../../../util/slugNormalize";
import {
  // formataCNPJ,
  formataCEP,
} from "../../../../util/formatString";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  Card: {
    height: "100%",
  },
  CardContent: {
    height: "100%",
    display: "flex",
    flexFlow: "column",
    "& .MuiTypography-body1": {
      color: theme.palette.primary.main,
      fontSize: "15px",
      fontWeight: 900,
    },
    "& .MuiGrid-root + p": {
      color: "gray",
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "22px",
    },
    "& .link": {
      textTransform: "lowercase",
    },
  },
  logoFiliados: {
    textAlign: "center",
    height: 45,
    "& img": {
      maxHeight: 45,
    },
  },
  cardLink: {
    textDecoration: "none",
    color: "inherit",
    height: "100%",
    "& .link": {
      textTransform: "lowercase",
    },
  },
}));

export default function CardEscola(params) {
  const escolas = params.escolas;
  const classes = useStyles();

  var logoClube =
    "https://sge.cbhb.org.br/_uploads/estabelecimento/logo/noLogo.png";
  const imgFallback = (e) => {
    e.target.src = logoClube;
  };

  const returnRepresentante = (representante) => {
    if (representante) {
      var filtered = _.filter(representante, function (o) {
        return o.flag_del === 0;
      });
      if (filtered[0]) {
        return (
          <>
            <b>Responsável:</b>
            <br /> Prof: {Capitalize(filtered[0].nome_completo)}
          </>
        );
      }
    }
    return null;
  };

  const returnMunicipio = (endereco) => {
    if (endereco) {
      if (endereco.municipio) {
        return `${Capitalize(endereco.municipio.descricao)} | `;
      }
    }
    return null;
  };

  const returnEndereco = (endereco) => {
    if (endereco) {
      return (
        <>
          <div>
            {`${endereco.tipo_logradouro ? `${endereco.tipo_logradouro} ` : ""
              } ${endereco.logradouro ? `${Capitalize(endereco.logradouro)}` : ""
              }${endereco.numero ? `, nº ${endereco.numero}` : ""}`}
          </div>
          <div>
            {`${endereco.bairro ? `${Capitalize(endereco.bairro)} -` : ""
              } CEP: ${formataCEP(endereco.cep)}`}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={2}>
      {escolas.map((filiado, i) => (
        <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.Card}>
            <CardContent className={classes.CardContent}>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={2} style={{ height: "auto" }}>
                  <div className={classes.logoFiliados}>
                    <img
                      src={`https://sgefeemg.bigmidia.com/_uploads/estabelecimento/logo/${filiado.url_logo}`}
                      onError={(e) => imgFallback(e)}
                      alt={filiado.sigla}
                    />
                  </div>
                </Grid>
                <Grid item xs={9} sm={10}>
                  <Typography className={classes.title} gutterBottom>
                    {filiado.descricao
                      ? Capitalize(filiado.descricao.toLowerCase())
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
              <p className={classes.textcnpj}>
                {returnMunicipio(filiado.endereco)}
                {filiado.uf}
              </p>
              <p style={{ marginTop: 0 }}>
                {filiado.representante
                  ? returnRepresentante(filiado.representante)
                  : ""}
              </p>
              {filiado.endereco ? (
                <p style={{ marginTop: 0 }}>
                  {returnEndereco(filiado.endereco)}
                  Tel: {filiado.telefone_fixo} <br />
                  Celular: {filiado.telefone_celular}
                </p>
              ) : null}
              <a
                href={`//${filiado.dominio}`}
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                {filiado.dominio}
              </a>

              <div style={{ marginTop: 5 }}>
                <Button
                  component={Link}
                  to={`/feemg/escola/${filiado.id}/${filiado.descricao ? slugNormalize(filiado.descricao) : null
                    }`}
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  Visualizar relação de filiados
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
