import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Paper from '@material-ui/core/Paper';

import { PillButtonPrimary } from '../Styles';

const useStyles = makeStyles({
    root: { 
        display: 'flex',
        flexDirection: 'column',
        minHeight: '70vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    h1: {
        color: 'gray',
        fontWeight: 500,
        fontSize: 'calc(2.5rem + 2vw)',
        marginBottom: '.5rem',
    },
    subtitle1: {
        marginBottom: '3rem',
        color: 'gray',
        fontSize: 'calc(1rem + .5vw)'
    }
});

export default function PageNotFound() {
    const classes = useStyles();

    return (
        <Paper className={classes.root} square>
            <Typography variant="h1" className={classes.h1} component="h2" align="center" gutterBottom>404</Typography>
            <Typography variant="subtitle1" className={classes.subtitle1} component="p" align="center" gutterBottom>A página solicitada não foi encontrada.</Typography>
            <div>
                <PillButtonPrimary startIcon={<HomeIcon />} color="primary" variant="contained" component={Link} to='/'>voltar para página inicial</PillButtonPrimary>
            </div>
        </Paper>
    )
}