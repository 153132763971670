import React from "react";
import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import slugNormalize from "../../../../util/slugNormalize";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    "& .MuiTableCell-root": {
      fontSize: "1.1rem",
      fontWeight: 600,
      paddingBottom: ".5rem",
    },
  },
  linkAluno: {
    color: "inherit",
    textDecoration: "none",
    "&:hover ": {
      color: theme.palette.primary.main,
    },
  },
  avatarContainer: {
    display: "flex",
    flexFlow: "row",
  },
}));

export default function TableAlunos(params) {
  const classes = useStyles();
  const { escolaId, slugEscola } = params;
  const atletas = params.atletas;
  return (
    <TableContainer component={Paper} elevation={1}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell style={{ minWidth: "250px" }}>Nome</TableCell>
            <TableCell align="center">Gênero</TableCell>
            <TableCell align="center">Nasc.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {atletas.map((item, key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  className={classes.avatarContainer}
                >
                  <Grid item>
                    <Avatar
                      src={`https://sgefeemg.bigmidia.com/_uploads/fotoAtleta/${item.foto}`}
                      alt={item.nome_completo}
                    />
                  </Grid>
                  <Grid item>
                    {item.estabelecimento ? (
                      <Link
                        to={`/feemg/escola/${
                          item.estabelecimento.id
                        }/${slugNormalize(
                          item.estabelecimento.descricao
                        )}/aluno/${item.id}/${slugNormalize(
                          item.nome_completo
                        )}`}
                        className={classes.linkAluno}
                      >
                        <Typography variant="subtitle2" gutterBottom>
                          {item.nome_completo}
                        </Typography>
                      </Link>
                    ) : (
                      <Link
                        to={`/feemg/escola/${escolaId}/${slugEscola}/aluno/${
                          item.id
                        }/${slugNormalize(item.nome_completo)}`}
                        className={classes.linkAluno}
                      >
                        <Typography variant="subtitle2" gutterBottom>
                          {item.nome_completo}
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell component="td" align="center">
                {item.sexo === "M" ? "Masculino" : "Feminino"}
              </TableCell>
              <TableCell component="td" align="center">
                {item.data_nascimento}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
