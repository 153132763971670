export default function Capitalize(word) {
    var captalized = ''
    captalized= word.toLowerCase().replace(/(?:^|\s)\S/g, function(capitalize) { return capitalize.toUpperCase(); });
  
    var PreposM = ["Da","Do","Das","Dos","De","A", "E","Mg","Bh","Xii"];
    var prepos = ["da","do","das","dos","de","a", "e","MG","BH","XII"];
 
    for (var i = PreposM.length - 1; i >= 0; i--) {
        captalized = captalized.replace(RegExp("\\b" + PreposM[i].replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&') + "\\b", "g"), prepos[i] );
    }

    var text = captalized.replaceAll("prof.","Prof.").replaceAll("e.e.","E.E.").replaceAll("e. e.","E.E.").replaceAll("e.m.","E.M.").replaceAll("e. m.","E.M.").replaceAll("e. M.","E.M.")
    return text
}