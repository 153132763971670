import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useParams, Link } from 'react-router-dom'
import moment from "moment";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import LanguageIcon from "@material-ui/icons/Language";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import useStyles from "../style";
import cepApi from "../../../services/fetchCEP";
import Capitalize from "../../../util/Capitalize";
import TableArbitros from "../Partida/components/TableArbitros";
import slugNormalize from "../../../util/slugNormalize";

function EventoInfo({ evento, idPartida }) {
  const { esporte } = useParams();
  const classes = useStyles();
  const [eventoCEP, setEventoCEP] = useState({});

  useEffect(() => {
    if (evento) {
      if (evento.cep) {
        cepApi
          .get(`${evento.cep}/json/`)
          .then((res) => {
            setEventoCEP(res.data);
            // console.log(res.data)
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [evento]);


  return (
    <Card>
      <CardContent>
        {evento && evento.descricao ? <Link to={`/feemg/${esporte}/evento/${evento.id}/${slugNormalize(evento.descricao)}`} style={{ textDecoration: 'none' }}>
          <Typography
            className={classes.heading}
            variant="h5"
            component="h1"
            color="primary"
            gutterBottom
          >
            {evento.descricao}
          </Typography>
        </Link> : null}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={evento.id_esporte === 1 ? 4 : 12}>
            <SimpleReactLightbox>
              <SRLWrapper>
                <div className={classes.eventoImg} style={{ minHeight: 250 }}>
                  <img
                    src={`https://sgefeemg.bigmidia.com/_uploads/relatorioEvento/${evento.arte_evento
                      }?time=${new Date().getTime()}`}
                    alt={evento.descricao}
                    style={!evento.arte_evento ? { display: "none" } : {}}
                  />
                </div>
              </SRLWrapper>
            </SimpleReactLightbox>
          </Grid>
          <Grid item xs={12} sm={12} md={evento.id_esporte === 1 ? 8 : 12}>
            <Grid
              container
              style={evento.id_esporte === 1 ? {} : { marginTop: "1rem" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.iconText}>
                  <EventAvailableIcon />
                  <span>
                    <b>Inicio:</b>{" "}
                    {moment(evento.data_inicio).format("DD/MM/YYYY HH:mm")}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.iconText}>
                  <EventBusyIcon color="primary" />
                  <span>
                    <b>Final:</b> {moment(evento.data_fim).format("DD/MM/YYYY")}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.iconText}>
                  <EventAvailableIcon />
                  <span>
                    <b>Limite de inscrição:</b>{" "}
                    {moment(evento.data_limit_inscricao).format("DD/MM/YYYY")}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {evento.data_limit_pagamento && (
                  <div className={classes.iconText}>
                    <EventBusyIcon color="primary" />
                    <span>
                      <b>Limite do pagamento:</b>{" "}
                      {moment(evento.data_limit_pagamento).format("DD/MM/YYYY")}
                    </span>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classes.iconText}>
                  <LocationOnIcon />
                  <span>
                    <b>Local:</b> {evento.local}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.iconText}>
                  <LanguageIcon />{" "}
                  <span>
                    <b>Escopo:</b> {evento.escopo}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12}>
                {evento.cep ? (
                  <p>
                    <b>Endereço: </b>{" "}
                    {eventoCEP.logradouro
                      ? `${eventoCEP.logradouro},`
                      : `${evento.logradouro ? Capitalize(evento.logradouro) : ""
                      }`}{" "}
                    {evento.numero ? `nº ${evento.numero}` : ""}
                    <br />
                    {evento.bairro
                      ? `${Capitalize(evento.bairro)},`
                      : `${eventoCEP.bairro}`}{" "}
                    {eventoCEP.localidade} - {eventoCEP.uf} | CEP:{" "}
                    {eventoCEP.cep}
                  </p>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12}>
                <div className={classes.textEvento}>
                  {evento.texto ? (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: evento.texto.replace(
                          /href/g,
                          "target='_blank' href"
                        ),
                      }}
                    />
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {idPartida ? (
          <TableArbitros idPartida={idPartida} esporte={esporte} />
        ) : null}
      </CardContent>
    </Card>
  );
}

export default EventoInfo;
