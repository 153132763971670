import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#edeff4",
    paddingBottom: "30px",
  },
  topSection: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #d4d4d4",
  },
  tabsSection: {
    marginBottom: 30,
    backgroundColor: "#fff",
    borderBottom: "1px solid #d4d4d4",
    "& .MuiTab-root": {
      fontWeight: 700,
      letterSpacing: ".05em",
      fontFamily: '"Montserrat", sans-serif',
    },
  },
  heading: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "1.2rem",
    letterSpacing: "0.05em",
    fontFamily: "Montserrat",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.35rem",
    },
  },
  iconText: {
    display: "flex",
    // alignItems: 'flex-end',
    marginRight: "12px",
    fontSize: "15px",
    marginBottom: "10px",
    "& svg": {
      marginRight: "3px",
    },
  },
  // textEvento: {
  //     padding: '20px 0'
  // },
  eventoImg: {
    height: "auto",
    width: "fit-content",
    borderRadius: "5px",
    overflow: "hidden",
    background: "#dfdfdf",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      width: "100%",
      cursor: "pointer",
      transition: "all 250ms ease-in",
      "&:hover": {
        opacity: 0.6,
      },
    },
    [theme.breakpoints.up("lg")]: {
      height: 300,
    },
  },
  tablePartidas: {
    "& td": {
      padding: 4,
      [theme.breakpoints.up("lg")]: {
        padding: 12,
      },
    },
  },
  rowHeader: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: ".8rem",
  },
  rowFooter: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: ".8rem",
    "& svg": {
      fontSize: ".8rem",
    },
  },
  gridEscola: {
    alignItems: "center",
    display: "flex",
  },
  timeCasa: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  timeVisitante: {
    justifyContent: "flex-start",
  },
  timeDetalhes: {
    textAlign: "inherit",
    margin: "0 10px",
    fontSize: ".7rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    "& a": {
      display: "block",
      fontWeight: "bold",
      fontSize: ".7rem",
      textDecoration: "none",
      lineHeight: 1.2,
      color: theme.palette.primary.main,
      "&:hover": {
        opacity: 0.7,
      },
    },
    [theme.breakpoints.up("md")]: {
      fontSize: ".8rem",
      "& a": {
        fontSize: ".9rem",
      },
    },
  },
  resultado: {
    fontSize: "0.9rem",
    color: "#8b8b8b",
    fontWeight: 500,
    display: "flex",
    "& .score-winner": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
      "& span": {
        margin: "0 5px",
      },
    },
  },
  vstext: {
    fontFamily: "Montserrat",
    fontSize: "1.2rem",
    fontWeight: 500,
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.75rem",
    },
  },
  avatarEscola: {
    height: 35,
    width: 35,
    [theme.breakpoints.up("sm")]: {
      height: 45,
      width: 45,
    },
    [theme.breakpoints.up("lg")]: {
      height: 70,
      width: 70,
    },
  },
  titleClassificacao: {
    backgroundColor: "#f5f7f9",
    fontSize: "11px",
    lineHeight: "1.2em",
    textAlign: "center",
    textTransform: "uppercase",
    padding: "12px",
    borderBottom: "1px solid #e4e7ed",
    color: "#31404b",
    fontWeight: 700,
    fontFamily: '"Montserrat",sans-serif',
    letterSpacing: ".02em",
  },
  tabelaClassificacao: {
    verticalAlign: "midddle",
    "& th, & td": {
      padding: 6,
    },
    "& td, & .link-escola": {
      lineHeight: 1.1,
      fontSize: ".7rem",
      fontFamily: "Montserrat",
    },
    "& td": {
      fontWeight: 500,
      textAlign: "center",
    },

    "& th": {
      letterSpacing: "0.05em",
      fontFamily: "Montserrat",
      textTransform: "uppercase",
      fontSize: ".75rem",
      fontWeight: 700,
      backgroundColor: "#414142",
      color: "#fff",
    },
    "& .MuiAvatar-root": {
      height: "40px !important",
      width: "40px !important",
    },
    [theme.breakpoints.up("md")]: {
      "& td": {
        fontSize: ".7rem",
      },
    },
  },
  columnTime: {
    display: "flex",
    textAlign: "left",
    "& .MuiAvatar-root": {
      height: 35,
      width: 35,
      marginRight: 5,
      [theme.breakpoints.up("sm")]: {
        height: 45,
        width: 45,
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: 10,
        height: 70,
        width: 70,
      },
    },
    "& a": {
      display: "block",
      fontWeight: "bold",
      fontSize: ".7rem",
      textDecoration: "none",
      lineHeight: 1.2,
      color: theme.palette.primary.main,
      "&:hover": {
        opacity: 0.7,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& a": {
        fontSize: ".9rem",
      },
    },
  },
  tableLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
