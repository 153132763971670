import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

// import { getAno } from '../../../../util/formatString';
import slugNormalize from '../../../../util/slugNormalize';

const useStyles = makeStyles((theme) => ({
    tableHead: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        '& .MuiTableCell-root': {
            fontSize: '1.1rem',
            fontWeight: 600,
            paddingBottom: '.5rem'
        }
    },
    linkPessoa: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover ': {
            color: theme.palette.primary.main
        }
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    formControl: {
        marginBottom: theme.spacing(3),
        minWidth: 200,
        maxWidth: 500
    },
    inputGroup: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'unset',
        '& a': {
            marginTop: 0
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
        },
    },
    avatarContainer: {
        display: 'flex',
        flexFlow: 'row'
    }
}));

export default function Tecnicos(params) {
    const classes = useStyles();

    const { slugEscola, escolaId,  pessoas } = params

    return (
        <TableContainer component={Paper} elevation={1}>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell style={{ minWidth: '250px' }}>Nome</TableCell>
                        <TableCell align="center">Gênero</TableCell>
                        {/* <TableCell align="center">Nasc.</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pessoas.map((item, key) => (
                        <TableRow key={key}>
                            <TableCell component="th" scope="row">
                                <Grid container alignItems="center" spacing={2} className={classes.avatarContainer}>
                                    <Grid item>
                                        <Avatar src={`https://sgefeemg.bigmidia.com/_uploads/fotoPessoa/${item.foto}`} alt={item.nome_completo} />
                                    </Grid>
                                    <Grid item>
                                        <Link to={`/feemg/escola/${escolaId}/${slugEscola}/tecnico/${item.id}/${slugNormalize(item.nome_completo)}`} className={classes.linkPessoa}>
                                            <Typography variant="subtitle2" gutterBottom>{item.nome_completo}</Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell component="td" align="center">
                                {item.sexo === 'M' ? 'Masculino' : 'Feminino'}
                            </TableCell>
                            {/* <TableCell component="td" align="center">
                                {getAno(item.data_nascimento)}
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}