import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Chip, TableCell, TableRow, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { fetchStaff } from "../../../../Eventos/hooks/getEventoStaff";
import { createSlug } from "../../../../../util/createSlug";
import moment from "moment";

function TabelaStaffHand({ pessoaId }) {
  const classes = useStyles();

  const { data, isSuccess } = useQuery(
    [`EventoStaffPessoa-${pessoaId}-cbhb`],
    ({
      pageParam = {
        apiEsporte: "cbhb",
        params: {
          id_pessoa: `${pessoaId}`,
          expand: "evento,funcao",
          sort: "-created_at",
          flag_del: 0,
          "evento.flag_del":0,
        },
      },
    }) => fetchStaff(pageParam)
  );


  if (isSuccess) {
    return (
      <React.Fragment>
        {data.map((item, key) => (
          <TableRow key={key}>
            <TableCell>
              <Link
                className={classes.link}
                to={`/feemg/handebol/${item.id_evento}/${
                  createSlug(item.evento?.descricao) || "partida"
                }/partida/${item.id_partida}`}
              >
                <Typography>
                  {item.evento?.descricao || "Visualizar partida"}
                </Typography>
              </Link>
            </TableCell>
            <TableCell>{item.funcao?.descricao}</TableCell>
            <TableCell>
              <Chip label="HANDEBOL" className={classes.handebol} />
            </TableCell>
            <TableCell>
              {moment(item.created_at * 1000).format("DD/MM/YYYY")}
            </TableCell>
            <TableCell>{item.evento.local}</TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
}

export default TabelaStaffHand;
