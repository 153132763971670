import React from 'react';

//img
// import FormBG from '../../assets/img/form-bg.jpg';

//components
import PageTitle from '../../components/PageTitle';
import SocialMidiaLinks from '../../components/SocialMidiaLinks';
import MapIframe from '../../components/MapIframe';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

//forms
import OuvidoriaFrom from './form/ouvidoria';
import OuvidoriaSection from './sections/ouvidoria';
import FaleConoscoFrom from './form/faleConosco';
import FaleConoscoSection from './sections/faleConosco';

import MetaTags from 'react-meta-tags'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        fontSize: '1rem'
    },
    contentSection: {
        paddingTop: '50px',
        paddingBottom: '50px',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    contentForm: {
        paddingTop: '50px',
        paddingBottom: '50px',
    },

}));


function Contato(params) {
    const classes = useStyles();
    const menuTitle = params.menuTitle;
    const pageTitle = params.pageTitle;
    const form = params.form;

    return (
        <>
            <MetaTags>
                <title>{pageTitle} - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle={menuTitle} pageTitle={pageTitle} />
            <Paper className={classes.root} square elevation={0}>
                <Container>
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6} >
                            <div className={classes.contentForm}>
                                {form === "ouvidoria" ?
                                    <OuvidoriaFrom />
                                    :
                                    <FaleConoscoFrom />
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={classes.contentSection}>
                                {form === "ouvidoria" ?
                                    <OuvidoriaSection />
                                    :
                                    <FaleConoscoSection />
                                }
                                <div style={{ marginTop: 'auto' }}>
                                    <SocialMidiaLinks title="SIGA-NOS" align="left" iconSize="small" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <MapIframe />
            </Paper>
        </>
    )
}

export default Contato;