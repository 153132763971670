import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import useStyles from "../style";

function TableClassificacaoFinal({ eventoGrupoClubes }) {
  const classes = useStyles();
  return (
    <Table className={classes.tabelaClassificacao}>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 80 }}>Colocação</TableCell>
          <TableCell>Equipe</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {eventoGrupoClubes.map((item, key) => (
            <TableRow key={key}>
              <TableCell>
                <Typography variant="subtitle2">{key + 1}</Typography>
              </TableCell>
              <TableCell>
                <div className={classes.columnTime}>
                  <div>
                    <Avatar
                      className={classes.avatarEscola}
                      variant="square"
                      src={item.estabelecimento.urlLogo}
                      alt={item.estabelecimento.descricao}
                    />
                  </div>
                  <div>
                    <Link
                      className="link-escola"
                      to={`/feemg/escola/${
                        item.estabelecimento.id
                      }/${createSlug(item.estabelecimento.descricao)}`}
                    >
                      {item.estabelecimento.sigla}
                    </Link>
                    <span className="team-meta__place">
                      {item.estabelecimento.uf}
                    </span>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      </TableBody>
    </Table>
  );
}

export default TableClassificacaoFinal;
