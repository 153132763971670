import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

//img
import CBDE from "../../assets/img/footer/CBDE.png";
import FTEMG from "../../assets/img/footer/FTEMG.png";
import GOV_Minas from "../../assets/img/footer/gov_minas.png";
import JEMG from "../../assets/img/footer/JEMG.png";
import ISF from "../../assets/img/footer/ISF.png";
import Mixtemper from "../../assets/img/footer/mixtemper2.png";
import Segasp from "../../assets/img/footer/segasp.png";
import Thefer from "../../assets/img/footer/thefer.png";
import bigmidiaWhite from "../../assets/img/footer/bigmidia_white-70x124.png";


import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "rgba(255,255,255,0.7)",
    textAlign: "center",
    padding: "50px 0",
    overflow: "auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      margin: 10,
      maxHeight: 70,
      maxWidth: 170,
    },
  },
  linksContainer: {
    backgroundColor: "rgba(255,255,255,0.9)",
    padding: "15px 0",
    color: "#6f6f6f",
    "& a": {
      color: theme.palette.primary.main,
      fontWeight: 700,
      textDecoration: "none",
      "&:hover": {
        color: "#000",
      },
    },
  },
  linksWraper: {
    width: "85%",
    margin: "auto",
  },

  gridTitle: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "17px",
    padding: "5px 0",
    marginBottom: "10px",
    borderBottom: "1px solid #6f6f6f",
    display: "block",
  },

  appGrid: {
    "& img": {
      width: "160px",
      margin: "0 5px 0 0",
    },
  },

  contatoGrid: {
    fontWeight: 500,
    fontSize: "13px",
  },

  copyrightContainer: {
    backgroundColor: "#414142",
    padding: "15px 0",
    fontSize: "12px",
    fontWeight: 500,
    color: "#fff",
    textAlign: "center",
    fontFamily: '"Montserrat","Roboto","Nunito Sans", sans-serif',
    "& img": {
      maxHeight: 70,
      transition: "all 250ms ease-in-out",
      "&:hover": {
        opacity: "0.8",
      },
    },
    "& p": {
      marginTop: 0,
      opacity: ".9",
    },
  },
}));

const Patrocionadores = [

  { img: FTEMG, url: "https://www.ftemg.com.br/", alt: "FTEMG" },
  { img: Segasp, url: "https://segaspsport.com.br/", alt: "SEGASP SPORT" },
  {
    img: Mixtemper,
    url: "https://mixtemper.com.br/",
    alt: "TEMPEROS MIXTEMPER",
  },
  { img: Thefer, url: "", alt: "Thefer Solucoes" },
  { img: ISF, url: "https://www.isfsports.org/", alt: "ISF" },
  { img: CBDE, url: "http://www.cbde.org.br/", alt: "CBDE" },
  { img: JEMG, url: "http://jogosescolares.esportes.mg.gov.br/", alt: "JEMG" },
  { img: GOV_Minas, url: "http://www.agenciaminas.mg.gov.br/", alt: "logo MG" },
];

export default function Footer() {
  const classes = useStyles();

  return (
    <footer>
      <div className={classes.container}>
        {Patrocionadores.map((item, key) => (
          <a key={key} href={item.url} target="_blank" rel="noreferrer">
            <img
              src={item.img}
              alt={item.alt}
              style={
                key === 4
                  ? { maxHeight: '65px' }
                  : key === 5
                  ? { maxWidth: '100px', maxHeight: '100px' }
                  : key === 3
                  ? { maxWidth: '80px', maxHeight: '80px' }
                  : {}
              }
            />
          </a>
        ))}
      </div>
      <div className={classes.linksContainer}>
        <Container style={{ padding: 0 }}>
          <Grid
            container
            spacing={6}
            justify="center"
            className={classes.linksWraper}
          >
            <Grid item sm={12} md={6} className={classes.contatoGrid}>
              <span className={classes.gridTitle}>CONTATO</span>
              <div>
                <b>© FEEMG</b>
                <br />
                Rua Boaventura nº 754, Bairro Indaiá-Belo Horizonte/MG.
                <br />
                CEP: 31270-020
                <br />
                Telf. (31) 2512-7300
                <br />
                <a href="mailto:feemg@feemg.com.br">feemg@feemg.com.br</a>
                <br />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <span className={classes.gridTitle}>LGPD</span>
              <p style={{ textAlign: "justify" }}>
                LGPD é a sigla usada o termo Lei Geral de Proteção de Dados. que
                foi sancionada em agosto de 2018, esta lei estabelece regras,
                sobre a coleta, armazenamento, tratamento e compartilhamento de
                dados pessoais dos usuários.
                <br />
                Confira as nossas{" "}
                <Link component="a" to="/politica-de-privacidade">
                  {" "}
                  Políticas de Privacidade
                </Link>
                .
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.copyrightContainer}>
        <a href="https://bigmidia.com/" target="_blank" rel="noreferrer">
          <img src={bigmidiaWhite} alt="logo" />
        </a>
        <p>Desenvolvimento de Softwares sob medida</p>
      </div>
    </footer>
  );
}
