import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    color: "#31404b",
    fontWeight: "bolder",
    fontSize: "1rem",
    lineHeight: "1.2em",
    fontFamily: '"Montserrat",sans-serif',
    borderBottom: "1px solid #dbdfe9",
    padding: theme.spacing(1.5, 2.5),
    position: "relative",
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      height: "100%",
      left: 0,
      bottom: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: 5,
      backgroundColor: theme.palette.primary.main,
    },
    "&.visitante:before": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  tableAtleta: {
    "& tr td, & tr th": {
      fontSize: 11,
      lineHeight: 1.4,
      fontFamily: '"Montserrat",sans-serif',
    },
    "& thead th": {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    "& .card-atleta": {
      display: "flex",
      alignItems: "center",
      "& .MuiAvatar-root": {
        height: 26,
        width: 26,
        marginRight: 14,
      },
    },
  },
  tableDividerText: {
    lineHeight: 1.4,
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: 900,
    fontSize: 10,
    color: "#31404b",
    fontFamily: '"Montserrat",sans-serif',
  },
  links: {
    fontWeight: 600,
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color:theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
}));

export default useStyles;
