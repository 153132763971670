
import React, { useEffect, useState, useRef } from 'react';

// - api
import { governancaApi } from '../../../services/api';

import ListagemDocumentos from '../../../components/ListagemDocumentos';

import { makeStyles } from '@material-ui/core/styles';

import Pagination from '@material-ui/lab/Pagination';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '90%',
            margin: 'auto'
        },
    },
    paginationContainer: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    errorContainer: {
        paddingTop: '1rem'
    },
}));

export default function Documentos(params) {
    const searchTerm = params.searchTerm
    const classes = useStyles()
    const [page, setPage] = useState(1)
    const [orgao, setOrgao] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const [totalResults, setTotalResults] = useState(1)

    const sectionTop = useRef(null);

    useEffect(() => {
        if (searchTerm) {
            async function getNoticia() {
                await governancaApi.get('orgao-anexo', {
                    params: {
                        titulo: searchTerm,
                        page: page
                    },
                }).then((response) => {
                    setOrgao(response.data.items);
                    setTotalPages(response.data._meta.pageCount)
                    setTotalResults(response.data._meta.totalCount)
                });
            }

            getNoticia();
        }
    }, [searchTerm, page])

    const handleChange = (event, value) => {
        setPage(value);
        if (value === 1) {
            executeScroll()
        }
    };

    useEffect(() => {
        if (page > 1) {
            executeScroll()
        }
    }, [page])

    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: sectionTop.current.offsetTop - 350 });

    return (
        <React.Fragment>
            <div ref={sectionTop}></div>
            {totalResults < 1 ?
                <div className={classes.errorContainer}>
                    <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
                </div>
                :
                <ListagemDocumentos documentos={orgao} dense={true} />
            }
            {totalPages > 1 ?
                <div className={classes.paginationContainer}>
                    <Pagination
                        page={page}
                        count={totalPages}
                        onChange={handleChange}
                    />
                </div>
                :
                <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    )

}