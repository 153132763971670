
 
import MetaTags from 'react-meta-tags';

//components
import PageTitle from '../../../components/PageTitle';

//material-ui
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import mapaMG from '../../../assets/img/mapaMinas.png';

import regionais from '../../../assets/data/feemg/regionais';
//styles
import ReactFlow from 'react-flow-renderer';
import { Accordion, AccordionDetails, AccordionSummary } from './styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative', height: '900px', width: 1280, overflow: 'auto',
    },
    wrapper: {
        padding: '30px 0',
        '& .react-flow__node': {
            cursor: 'auto'
        },

        '& .react-flow__node-output': {
            background: 'transparent',
            border: 'none',
            boxShadow: 'none !important',
        },
        '& .react-flow__node-default .react-flow__handle': {
            background: 'transparent',
            border: 'transparent',
        },
        '& .react-flow__node-default': {
            // background: theme.palette.primary.main,
            // borderColor: theme.palette.primary.main,
            // color: theme.palette.primary.contrastText,
            padding: '0px !important',
            background: 'transparent',
            borderColor: 'transparent',
            border: 'transparent',
            boxShadow: 'none !important',
            width: 240,
        },
        '& .react-flow__node-output .react-flow__handle': {
            background: theme.palette.primary.main,
            height: '12px',
            width: '12px',
        },
    },

    AccordionDetails: {
        fontSize: 11,
        '& p': {
            margin: '8px 0'
        },
        '& .nome': {
            marginTop: 0,
            fontSize: 14
        },
        '& .sectionTitle': {
            opacity: 0.8
        },
        '& .list': {
            paddingLeft: 12,
            margin: 0,
            columns: 2
        }
    }
}));

export default function Regionais() {
    const classes = useStyles();

    const renderAccordion = (regional) => {

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${regional.id}a-content`}
                    id={`panel${regional.id}a-header`}
                >
                    <Typography className={classes.heading}>{regional.titulo}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.AccordionDetails}>
                        <div className="sectionTitle">
                            PROFESSOR RESPONSÁVEL
                        </div>
                        <p className="nome">
                            <b>{regional.professor}</b>
                        </p>
                        <div className="sectionTitle">
                            CONTATO
                        </div>
                        <Link href={`mailto:${regional.email}`}>{regional.email}</Link>
                        <p>
                            <div className="sectionTitle">
                                SRE'S INTEGRANTES
                            </div>
                            <ul className="list">
                                {regional.sre.map((item, key) => (
                                    <li key={key}>{item.titulo}</li>
                                ))}
                            </ul>
                        </p>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    const elements = [
        {
            id: '1', data: {
                label: (
                    renderAccordion(regionais[0])
                )
            }, sourcePosition: 'right', position: { x: 180, y: 80 }
        },
        { id: '2', data: {}, type: 'output', position: { x: 485, y: 240 } },
        {
            id: '3', data: {
                label: (
                    renderAccordion(regionais[1])
                )
            }, sourcePosition: 'right', position: { x: 450, y: 0 }
        },
        { id: '4', data: {}, type: 'output', position: { x: 635, y: 370 } },
        {
            id: '5', data: {
                label: (
                    renderAccordion(regionais[2])
                )
            }, sourcePosition: 'left', position: { x: 750, y: 60 }
        },
        { id: '6', data: {}, type: 'output', position: { x: 670, y: 315 } },
        {
            id: '7', data: {
                label: (
                    renderAccordion(regionais[3])
                )
            }, sourcePosition: 'right', position: { x: 60, y: 350 }
        },
        { id: '8', data: {}, type: 'output', position: { x: 310, y: 430 } },
        {
            id: '9', data: {
                label: (
                    renderAccordion(regionais[4])
                )
            }, sourcePosition: 'bottom', position: { x: 350, y: 270 }
        },
        { id: '10', data: {}, type: 'output', position: { x: 510, y: 450 } },
        {
            id: '11', data: {
                label: (
                    renderAccordion(regionais[5])
                )
            }, sourcePosition: 'left', position: { x: 750, y: 410 }
        },
        { id: '12', data: {}, type: 'output', position: { x: 580, y: 430 } },
        {
            id: '13', data: {
                label: (
                    renderAccordion(regionais[6])
                )
            }, sourcePosition: 'left', position: { x: 250, y: 650 }
        },
        { id: '14', data: {}, type: 'output', position: { x: 470, y: 525 } },
        {
            id: '15', data: {
                label: (
                    renderAccordion(regionais[7])
                )
            }, sourcePosition: 'top', position: { x: 610, y: 650 }
        },
        { id: '16', data: {}, type: 'output', position: { x: 550, y: 490 } },

        { id: 'e1-2', source: '1', target: '2', type: 'step' },
        { id: 'e3-4', source: '3', target: '4', type: 'step' },
        { id: 'e5-6', source: '5', target: '6', type: 'step' },
        { id: 'e7-8', source: '7', target: '8', type: 'step' },
        { id: 'e9-10', source: '9', target: '10', type: 'step' },
        { id: 'e11-12', source: '11', target: '12', type: 'step' },
        { id: 'e13-14', source: '13', target: '14', type: 'step' },
        { id: 'e15-16', source: '15', target: '16', type: 'step' },
    ];

    return (
        <>
            <MetaTags>
                <title>Regionais - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="FEEMG" pageTitle="Regionais" />
            <Paper className={classes.wrapper} square>

                <Container maxWidth="lg" square >
                    <div className={classes.root} >
                        <div style={{ position: 'absolute', top: '200px', left: '300px' }}>
                            <img src={mapaMG} alt="mapa minas gerais" />
                        </div>

                        <ReactFlow
                            elements={elements}
                            nodesDraggable={false}
                            selectNodesOnDrag={false}
                            zoomOnScroll={false}
                            paneMoveable={false}
                            nodesConnectable={false}
                            panOnScrollMode={false}
                        />
                    </div>
                </Container>

            </Paper>
        </>
    )
}