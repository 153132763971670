import axios from "axios";
import { esporteApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchArbitragem(props) {
  const { esporte, idPartida } = props;
  const _api = esporteApiEsporte[esporte];

  const res = await axios.get(`${baseURL}/${_api}/api/evento-partida-arbitro`, {
    params: {
      forcewhere: `id_partida:${idPartida}`,
      expand: "tb_pessoa,funcao",
      pageSize: 20,
    },
  });
  const data = res.data.items;
  return data;
}

export async function fetchPartidaArbitro(props) {
  const { apiEsporte, params } = props;
  const res = await axios.get(`${baseURL}/${apiEsporte}/api/evento-partida-arbitro`, {
    params: { ...params },
  });
  const data = res.data.items;
  return data;
}
