import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Grid, Button } from "@material-ui/core";

import Instagram from "./SocialMidia/Instagram";
import FlickrAlbums from "./SocialMidia/Flickr";
import Youtube from "./SocialMidia/Youtube";

const useStyles = makeStyles((theme) => ({
  instagram: {
    backgroundColor: "#251f21",
    height: "100%",
  },
  facebook: {
    backgroundColor: "#373032",
    color: "#fff",
    height: "100%",
    minHeight: 600,
  },
  youtube: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    height: "100%",
  },
  flickr: {
    backgroundColor: "#373032",
    color: "#fff",
    height: "100%",
  },

  buttonLink: {
    color: "#da251c",
    "& :hover": {
      color: "gray",
    },
  },
  wrapper: {
    margin: "auto",
    height: "100%",
    padding: "20px 30px",
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      padding: "20px 40px",
    },
    "& img": {
      maxWidth: "100%",
      paddingRight: "10px",
      paddingBottom: "10px",
    },
  },
  whiteWrapper: {
    margin: "auto",
    height: "100%",
    padding: "20px 30px",
    color: "#fff",
    [theme.breakpoints.up("lg")]: {
      padding: "20px 40px 0 ",
    },
    "& img": {
      maxWidth: "100%",
      paddingRight: "10px",
      paddingBottom: "10px",
    },
  },
  contentWrapper: {
    margin: "auto",
    height: "100%",
    padding: "20px 30px",
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      padding: "20px 40px",
    },
  },
  facebookContainer: {
    border: "none",
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "42vw",
    },
  },
}));

export default function SocialMidia() {
  const classes = useStyles();

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));

  const iframeFacebook = (height, width) => {
    return (
      <iframe
        title="facebook-smUp"
        src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffeemg.oficial&tabs=timeline&width=340&height=430&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=514073877525625`}
        width="100%"
        height={height}
        style={{ border: "none", overflow: "hidden" }}
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <div className={classes.instagram}>
          <div className={classes.contentWrapper}>
            <h3 className="heading-white">INSTAGRAM</h3>
            <Instagram />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <div className={classes.facebook}>
          <div className={classes.wrapper}>
            <h3 className="heading-white">FACEBOOK</h3>
            {matchesSM
              ? matchesLG
                ? iframeFacebook(900, 450)
                : iframeFacebook(700, 400)
              : iframeFacebook(500, 310)}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <div className={classes.youtube}>
          <div className={classes.whiteWrapper}>
            <h3 className="heading">CANAL YOUTUBE</h3>
            <Youtube />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <div className={classes.flickr}>
          <div className={classes.contentWrapper}>
            <h3 className="heading-white">FLICKR</h3>
            <FlickrAlbums />
            <div>
              <Button
                href="https://www.flickr.com/photos/feemg/sets/"
                target="_blank"
                rel="noreferrer"
                color="primary"
              >
                VER MAIS
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
