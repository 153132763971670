import React from 'react'
import { useQuery } from '@tanstack/react-query';
import fetchEventos from '../../../lib/fetchEvento';
import moment from 'moment';
import { CircularProgress, Container, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core';
import PageTitle from '../../../components/PageTitle';
import { MetaTags } from 'react-meta-tags';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import LanguageIcon from '@material-ui/icons/Language';
import { Link } from 'react-router-dom';
import slugNormalize from '../../../util/slugNormalize';
import imgAcesso from '../../../assets/img/acesso-rapido/16.png';
import FiltrosArbitragemCursos from './filtros';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "30px 0px",
    },
    paginationContainer: {
        padding: "20px 0",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-end",
        },
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 100,
    },
    inputGroup: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up("lg")]: {
            flexWrap: "unset",
            "& a": {
                marginTop: 0,
            },
        },
    },
    spinnerContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "2rem",
        paddingBottom: "4rem",
    },
    errorContainer: {
        paddingTop: "1rem",
        paddingBottom: "4rem",
    },
    pageTitle: {
        color: theme.palette.primary.main,
        fontSize: "1.5rem",
        fontWeight: 900,
    },
    Card: {
        height: '100%',
        maxWidth: 306,
        margin: 'auto',
        overflow: 'hidden'
    },
    cardTitle: {
        textdecoration: 'none',
        marginBottom: '0.5rem',
        fontSize: '1rem',
        fontWeight: 900,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        transition: 'all 200ms ease-in',
        '&:hover': {
            color: 'gray'
        }
    },
    cardImgContainer: {
        background: '#2e2e2e',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 230,
        overflow: 'hidden',
    },
    cardImgContainerSM: {
        background: '#2e2e2e',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,
        overflow: 'hidden',
    },
    cardImg: {
        width: 'auto',
        height: '100%',
    },
    date: {
        fontSize: '.85rem',
        marginBottom: '0.5rem'
    },
    resumo: {
        fontSize: '1rem'
    },
    listIcon: {
        minWidth: 'unset',
        paddingRight: '10px'
    },
    List: {
        '& .MuiListItem-gutters': {
            padding: '4px 0'
        }
    }
}));
function ArbitragemCursos(params) {
    const classes = useStyles();
    const escopo = params.escopo || '';
    const queryParam = params.searchTerm || '';
    const page = params.page || 1;
    const temporada = params.temporada ? params.temporada : moment().format('YYYY');
    let data1 = temporada.concat("-01-01");
    let data2 = temporada.concat("-12-31");

    const localDate = (data_criacao) => {
        var date = new Date(data_criacao)
        var formatedDate = date.toLocaleDateString()
        return formatedDate
    }

    const imgFallback = (e) => {
        e.target.src = imgAcesso
    }

    const { data, isSuccess, isLoading, isError } = useQuery(
        ["arbitragem-eventos-cursos", temporada, queryParam],
        () => fetchEventos({
            flag_del: 0,
            flag_oculto: 0,
            id_tipo: 8,
            id_estabelecimento: 26,
            sort: "-data_inicio_inscricao",
            escopo: escopo,
            descricao: queryParam,
            betweenField: "data_inicio",
            betweenValue1: data1,
            betweenValue2: data2,
            page: page,
            pageSize: 100
        })
    );

    return (
        <div>
            <MetaTags>
                <title>Competições - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="FEEMG" pageTitle="Arbitragem Cursos" />
            <Paper className={classes.root} square>
                <Container maxWidth="lg">
                    <FiltrosArbitragemCursos params={params} />
                    {isLoading && (
                        <div className={classes.spinnerContainer}>
                            <CircularProgress />
                        </div>
                    )}
                    {!isLoading && !data.length && (
                        <div className={classes.errorContainer}>
                            <Alert severity="error">A pesquisa não retornou resultados.</Alert>
                        </div>
                    )}
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        {isSuccess && data ? data.map((evento) => (
                            <Grid key={evento.id} xs={12} sm={6} md={4} lg={3} item>
                                <Paper elevation={2} className={classes.Card}>
                                    <div className={classes.cardImgContainer}>
                                        <img src={`https://sgefeemg.bigmidia.com/_uploads/relatorioEvento/${evento.arte_evento}?time=${(new Date()).getTime()}`} loading="lazy" onError={(e) => imgFallback(e)} className={classes.cardImg} alt={evento.descricao} />
                                    </div>
                                    <div style={{ padding: 8 }}>
                                        <Link to={`/feemg/geral/evento/${evento.id}/${slugNormalize(evento.descricao)}`} className={classes.cardTitle}>
                                            {evento.descricao}
                                        </Link>
                                        <List dense={true} className={classes.List}>
                                            <ListItem>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <EventAvailableIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={`Início: ${localDate(evento.data_inicio)}`}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <EventBusyIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={`Fim: ${localDate(evento.data_fim)}`}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <LanguageIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={evento.escopo}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon className={classes.listIcon}>
                                                    <LocationOnIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={evento.local}
                                                />
                                            </ListItem>
                                        </List>
                                    </div>
                                </Paper>
                            </Grid>
                        )) : <div className={classes.errorContainer}></div>}
                    </Grid>
                </Container>
            </Paper>
        </div>
    )
}

export default ArbitragemCursos