import React from "react";
//material ui
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

// imagens
import clube1 from "../../../assets/img/clube-vantagens/p1.webp";
import clube2 from "../../../assets/img/clube-vantagens/p2.webp";
import clube3 from "../../../assets/img/clube-vantagens/p3.webp";

import educ1 from "../../../assets/img/feemg-educacional/1.webp";
import educ2 from "../../../assets/img/feemg-educacional/2.webp";
import educ3 from "../../../assets/img/feemg-educacional/3.webp";
import educ4 from "../../../assets/img/feemg-educacional/4.webp";
import educ5 from "../../../assets/img/feemg-educacional/5.webp";
import educ6 from "../../../assets/img/feemg-educacional/6.webp";

import academica1 from "../../../assets/img/feemg-academica/1.png";
import academica2 from "../../../assets/img/feemg-academica/2.png";
import academica3 from "../../../assets/img/feemg-academica/3.png";
import academica4 from "../../../assets/img/feemg-academica/4.png";
import academica5 from "../../../assets/img/feemg-academica/5.png";
import academica6 from "../../../assets/img/feemg-academica/6.png";

import beneficios1 from "../../../assets/img/feemg-beneficios/1.png";
import beneficios2 from "../../../assets/img/feemg-beneficios/2.png";
import beneficios3 from "../../../assets/img/feemg-beneficios/3.png";
import beneficios4 from "../../../assets/img/feemg-beneficios/4.png";
import beneficios5 from "../../../assets/img/feemg-beneficios/5.png";
import beneficios6 from "../../../assets/img/feemg-beneficios/6.png";

import { useStyles } from "./styles/ClubeVantagens";

const SectionBanners = [
  {
    id: 1,
    title: "FEEMG Acadêmica",
    banners: [
      { src: academica1 },
      { src: academica2 },
      { src: academica3 },
      { src: academica4 },
      { src: academica5 },
      { src: academica6 },
    ],
    buttons: [
      {
        href: "https://academica.feemg.com.br/",
        text: "ACESSAR PLATAFORMA",
        style: { color: "default", variant: "contained" },
      },
    ],
  },
  {
    id: 2,
    title: "FEEMG Educacional",
    banners: [
      { src: educ1 },
      { src: educ2 },
      { src: educ3 },
      { src: educ4 },
      { src: educ5 },
      { src: educ6 },
    ],
    buttons: [
      {
        href: "https://educacional.feemg.com.br/",
        text: "ACESSAR PLATAFORMA",
        style: { color: "secondary", variant: "contained" },
      },
    ],
  },
  {
    id: 3,
    title: "Clube de Vantagens",
    banners: [{ src: clube1 }, { src: clube2 }, { src: clube3 }],
    buttons: [
      {
        href: "https://sge.feemg.com.br/vantagens",
        text: "Veja aqui alguns descontos",
        style: { color: "primary", variant: "contained" },
      },
      {
        href: "https://sge.feemg.com.br/cadastroExterno/pessoa?cod=oiturygtrLV]qTQ--&type=associado",
        text: "Quero Participar",
        style: { color: "accent", variant: "contained" },
      },
      {
        href: "https://sge.feemg.com.br/site/login",
        text: "Já sou filiado a FEEMG",
        style: { color: "primary", variant: "contained" },
      },
    ],
  },
  {
    id: 4,
    title: "FEEMG Benefícios",
    banners: [
      { src: beneficios1 },
      { src: beneficios2 },
      { src: beneficios3 },
      { src: beneficios4 },
      { src: beneficios5 },
      { src: beneficios6 },
    ],
    buttons: [
      {
        href: "https://beneficios.feemg.com.br/",
        text: "ACESSAR PLATAFORMA",
        style: { color: "default", variant: "contained" },
      },
    ],
  },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function ClubeVantagens() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });

  return (
    <Box
      style={{ overflow: "hidden", maxWidth: "100%", backgroundColor: "#fff" }}
    >
      <Container className={classes.container}>
        <Grid container spacing={1}>
          {SectionBanners.map((item, key) => (
            <Grid item key={key} xs={12} sm={6} md={3}>
              <Typography variant="h6" className={classes.cardTitle}>
                {item.title}
              </Typography>
              <AutoPlaySwipeableViews
                axis="x"
                interval={9000}
                index={activeStep[item.id]}
                onChangeIndex={(step) =>
                  setActiveStep({ ...activeStep, [item.id]: step })
                }
                enableMouseEvents
              >
                {item.banners.map((item, index) => (
                  <div key={index}>
                    <div
                      className={classes.imgWraper}
                      style={{ backgroundImage: `url(${item.src})` }}
                    />
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              <div className={classes.btnContainer}>
                {item.buttons.map((item, key) => (
                  <div key={key} className={classes.btnWrapper}>
                    <Button
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                      className={`color-${item.style.color}`}
                      variant={item.style.variant}
                      size="small"
                      disableElevation
                    >
                      {item.text}
                    </Button>
                  </div>
                ))}
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
