import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import TableStaff from "./TableStaff";
import clsx from "clsx";
import useStyles from "./styles";
import TableScoutStyles from "../../../../../components/TableScouts/styles";
import { Skeleton } from "@material-ui/lab";
import _ from "lodash";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../../util/createSlug";

function TableAtleta({
  tipo,
  evento,
  idPartida,
  idEquipe,
  partida,
  scoutsPartida,
  atletas,
  isLoading,
}) {
  const classes = useStyles();
  const TBScoutClasses = TableScoutStyles();

  const handleScouts = (scouts) => {
    const groupScouts = _.groupBy(scouts, "id_scout");
    const results = Object.entries(groupScouts);

    let teste;
    if (results) {
      let simpleResults = results.map((item) => ({
        id: item[0],
        qtd: item[1].length,
      }));
      teste = scoutsPartida.map((item) => {
        let itemIndex = _.findIndex(
          simpleResults,
          (o) => o.id === item.id.toString()
        );
        if (itemIndex !== -1) {
          return { qtd: simpleResults[itemIndex].qtd, obj: item };
        } else {
          return { qtd: 0, obj: {} };
        }
      });
    }

    return teste.map((item, key) => (
      <TableCell key={key}>
        {item.obj.icon ? <i className={`icon-svg ${item.obj.icon}`}></i> : null}{" "}
        {item.qtd}
      </TableCell>
    ));
  };

  return (
    <Paper>
      <TableContainer>
        <Typography variant="h6" className={clsx(classes.tableTitle, tipo)}>
          {tipo === "casa"
            ? partida.casa.sigla_evento
            : partida.visitante.sigla_evento}
        </Typography>
        <Table
          className={clsx(classes.tableAtleta, TBScoutClasses.tableScouts)}
        >
          <TableHead>
            <TableRow>
              <TableCell>Camisa</TableCell>
              <TableCell>Atleta</TableCell>
              {scoutsPartida
                ? scoutsPartida.map((item, key) => (
                    <TableCell key={key} title={item.descricao}>
                      {item.id === 1 ? item.descricao : item.sigla}
                    </TableCell>
                  ))
                : null}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              {Array.from(new Array(5)).map((item, key) => (
                <TableRow key={key}>
                  <TableCell
                    colSpan={scoutsPartida ? scoutsPartida.length + 2 : 4}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
          <TableBody>
            {atletas && scoutsPartida ? (
              <React.Fragment>
                {atletas.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell>{item.scouts.num_camisa || item.numero}</TableCell>
                    <TableCell>
                      <Link
                        className={classes.links}
                        to={`/feemg/escola/${
                          item.id_estabelecimento
                        }/${createSlug(
                          item.atleta.atleta.estabelecimento.descricao
                        )}/aluno/${item.atleta.id_atleta}/${createSlug(
                          item.atleta.nome_completo ||
                            item.atleta.nome_evento ||
                            "atleta"
                        )}`}
                      >
                        <Box className="card-atleta">
                          <Avatar
                            src={item.atleta.atleta.urlFoto}
                            alt={item.atleta.nome_completo}
                          />
                          {item.atleta.nome_evento}
                        </Box>
                      </Link>
                    </TableCell>
                    {handleScouts(item.scouts)}
                  </TableRow>
                ))}
              </React.Fragment>
            ) : null}
            <TableStaff
              evento={evento}
              idEquipe={idEquipe}
              idPartida={idPartida}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TableAtleta;
