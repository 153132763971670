import React from 'react';
import MetaTags from 'react-meta-tags'
import ReactHtmlParser from 'react-html-parser';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//components
import PageTitle from '../../components/PageTitle';
import politicasPrivacidade from '../../assets/data/politicas-privacidade';
//styles
import { Accordion, AccordionSummary, AccordionDetails } from '../../components/Styles';

const useStyles = makeStyles({
    wrapper: {
        padding: '30px 0'
    },
});

export default function PoliticaPrivacidade() {
    const classes = useStyles();

    return (
        <>
            <MetaTags>
                <title>Políticas de Privacidade - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="FEEMG" pageTitle="Políticas de Privacidade" />
            <Paper className={classes.wrapper} square>
                <Container maxWidth="lg" square>
                    {politicasPrivacidade.map((item, i) => (
                        <Accordion key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${i}-content`}
                                id={`panel${i}-header`}
                            >
                                <Typography className={classes.heading}>{item.titulo}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {ReactHtmlParser(item.corpo)}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Container>
            </Paper>
        </>
    )
}