const ouvidoria = {
    id_estabelecimento: 26,
    protocolo: "",
    id_estado_solicitacao: 1,
    tipo_contato: "ouvidoria",
    nome_solicitante: "",
    email: "",
    telefone: "",
    motivo: "",
    mensagem: "",
    flag_del: 0,
    created_at: "",
    updated_at: "",
    created_by: "",
    updated_by: ""
}

export default ouvidoria