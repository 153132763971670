import IconButton from '@material-ui/core/IconButton';
// import SvgIcon from '@material-ui/core/SvgIcon';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PrintIcon from '@material-ui/icons/Print';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import PinterestIcon from '@material-ui/icons/Pinterest';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton
} from "react-share";

function SocialLinks(props) {

    const shareUrl = props.shareUrl;

    return (
        <div>
            <FacebookShareButton url={shareUrl}>
                <IconButton aria-label="Facebook" style={{ color: '#3B5998' }} color="inherit">
                    <FacebookIcon />
                </IconButton>
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
                <IconButton aria-label="Twitter" style={{ color: '#08a0e9' }} color="inherit">
                    <TwitterIcon />
                </IconButton>
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl}>
                <IconButton aria-label="WhatsApp" style={{ color: '#25D366' }} color="inherit">
                    <WhatsAppIcon />
                </IconButton>
            </WhatsappShareButton>
            <EmailShareButton url={shareUrl}>
                <IconButton aria-label="Email" style={{ color: '#F39C12' }} color="inherit">
                    <MailOutlineIcon />
                </IconButton>
            </EmailShareButton>

            <IconButton aria-label="Imprimir" style={{ color: '#3E81AD' }} color="inherit" onClick={() => window.print()}>
                <PrintIcon />
            </IconButton>
        </div>
    )
}

export default SocialLinks;