import React, { useState, useEffect } from 'react';
import imgAcesso from '../../assets/img/acesso-rapido/16.png';
import esportesEspecificacoes from '../../assets/data/esporte/esportesEspecificacoes'

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import slugNormalize from '../../util/slugNormalize';

//icon
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
    Card: {
        height: '100%',
        maxWidth: 306,
        margin: 'auto',
        overflow: 'hidden'
    },
    cardTitle: {
        marginBottom: '0.5rem',
        fontSize: '1rem',
        fontWeight: 900,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        transition: 'all 200ms ease-in',
        '&:hover': {
            color: 'gray'
        }
    },
    cardImgContainer: {
        background: '#2e2e2e',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 230,
        overflow: 'hidden',
    },
    cardImgContainerSM: {
        background: '#2e2e2e',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,
        overflow: 'hidden',
    },
    cardImg: {
        width: 'auto',
        height: '100%',
    },
    date: {
        fontSize: '.85rem',
        marginBottom: '0.5rem'
    },
    resumo: {
        fontSize: '1rem'
    },
    listIcon: {
        minWidth: 'unset',
        paddingRight: '10px'
    },
    List: {
        '& .MuiListItem-gutters': {
            padding: '4px 0'
        }
    }
}));

export default function EventList(params) {
    const classes = useStyles();
    const eventList = params.eventList;
    const dense = params.dense
    const [isDense, setIsDense] = useState(false)

    const imgFallback = (e) => {
        e.target.src = imgAcesso
    }

    const localDate = (data_criacao) => {
        var date = new Date(data_criacao)
        var formatedDate = date.toLocaleDateString()
        return formatedDate
    }

    useEffect(() => {
        if (dense) {
            setIsDense(true)
        }
    }, [dense])

    return (
        <Grid container spacing={2} style={{ marginTop: 10 }}>

            {eventList.map((evento, i) => {
                const esporte = esportesEspecificacoes.find((esporte) => esporte.id === evento.id_esporte);
                return (
                    <Grid key={i} xs={12} sm={6} md={4} lg={3} item>

                        <Paper elevation={2} className={classes.Card}>
                            <div className={isDense ? classes.cardImgContainerSM : classes.cardImgContainer}>
                                <img src={`https://sgefeemg.bigmidia.com/_uploads/relatorioEvento/${evento.arte_evento}?time=${(new Date()).getTime()}`} loading="lazy" onError={(e) => imgFallback(e)} className={classes.cardImg} alt={evento.descricao} />
                            </div>

                            <div style={{ padding: 8 }}>
                                <Typography className={classes.cardTitle}
                                    to={`/feemg/${esporte.nome ?? slugNormalize(esporte.descricao)}/evento/${evento.id}/${slugNormalize(evento.descricao)}`}
                                    variant="h6" color="primary"
                                    component={Link}
                                    gutterBottom>
                                    {evento.descricao}
                                </Typography>


                                <List dense={true} className={classes.List}>
                                    <ListItem>
                                        <ListItemIcon className={classes.listIcon}>
                                            <EventAvailableIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`Início: ${localDate(evento.data_inicio)}`}
                                            />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listIcon}>
                                            <EventBusyIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`Fim: ${localDate(evento.data_fim)}`}
                                            />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listIcon}>
                                            <LanguageIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={evento.escopo}
                                            />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listIcon}>
                                            <LocationOnIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={evento.local}
                                            />
                                    </ListItem>
                                </List>
                            </div>
                        </Paper>

                    </Grid>
                );
            })}

        </Grid>
    )
}