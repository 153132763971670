import { Box, TableCell, TableRow } from "@material-ui/core";
import React from "react";

function RowPonto({ scout }) {
  const handleName = (scoutAtleta) => {
    if (scoutAtleta) {
      if (scoutAtleta.nome_evento !== "") {
        return scoutAtleta.nome_evento;
      }
      return scoutAtleta.atleta.nome_completo;
    }
    return "";
  };

  let pontoNaoConvertido =
    // process.env.REACT_APP_DOMAIN === "CBBC" &&
    scout.scout.tipo_render === "ponto" && scout.convertido === 1
      ? {
        position: "relative",
        "& i::before": { filter: "grayscale(1)" },
        "& span": {
          position: "absolute",
          right: "25%",
          top: 8,
          fontSize: "1rem",
          fontWeight: 900,
        },
      }
      : null;

  if (!scout) {
    return (
      <tr>
        <td></td>
      </tr>
    );
  }

  return (
    <TableRow>
      <TableCell className="alc-commentary-cell alc-commentary-cell--team1">
        <div className="alc-commentary-cell__inner">
          {!scout.timeCasa ? (
            <div className="alc-commentary-cell__inner">
              <div>Período: {scout.quarto}</div>
            </div>
          ) : (
            <div className="alc-commentary-cell__inner">
              <div className="alc-commentary-cell__text">
                {scout.scout.descricao}
                <strong>
                  {scout.atleta && ` - ${scout.atleta.nome_evento}`}
                </strong>
              </div>
              <Box
                className="alc-commentary-cell__icon"
                sx={pontoNaoConvertido}
              >
                <i className={`icon-svg ${scout.scout.icon}`}></i>
                {pontoNaoConvertido ? (
                  <div className="penalty">x</div>
                ) : null}
              </Box>
            </div>
          )}
        </div>
      </TableCell>
      <TableCell className="alc-time-stamp">{scout.tempo / 100}'</TableCell>
      <TableCell className="alc-commentary-cell alc-commentary-cell--team2">
        {scout.timeCasa ? (
          <div className="alc-commentary-cell__inner">
            <div>Período: {scout.quarto}</div>
          </div>
        ) : (
          <div className="alc-commentary-cell__inner">
            <div className="alc-commentary-cell__text">
              {scout.scout.descricao}
              <strong>{` - ${handleName(scout.atleta)}`}</strong>
            </div>
            <Box className="alc-commentary-cell__icon" sx={pontoNaoConvertido}>
              <i className={`icon-svg ${scout.scout.icon}`}></i>
              {pontoNaoConvertido ? (
                <div className="penalty">x</div>
              ) : null}
            </Box>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
}

export default RowPonto;
