import { makeStyles } from "@material-ui/core";
import iconSprite from "../../assets/img/icones/sprite-icons.svg";

const TableScoutStyles = makeStyles((theme) => ({
  tableScouts: {
    "& .icon-svg:before": {
      content: "''",
      verticalAlign: "middle",
      display: "inline-block",
      backgroundRepeat: "no-repeat",
      backgroundSize: "13em 62.3em",
      backgroundImage: `url(${iconSprite})`,
    },
    "& .icon-svg.icon-basketball": {
      '&:before': {
        content: "''",
        backgroundSize: 'unset',
        width: "1.8em",
        height: "1.8em",
        backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23EE6730" d="M86.6 64l85.2 85.2C194.5 121.7 208 86.4 208 48c0-14.7-2-28.9-5.7-42.4C158.6 15 119 35.5 86.6 64zM64 86.6C35.5 119 15 158.6 5.6 202.3C19.1 206 33.3 208 48 208c38.4 0 73.7-13.5 101.3-36.1L64 86.6zM256 0c-7.3 0-14.6 .3-21.8 .9C238 16 240 31.8 240 48c0 47.3-17.1 90.5-45.4 124L256 233.4 425.4 64C380.2 24.2 320.9 0 256 0zM48 240c-16.2 0-32-2-47.1-5.8C.3 241.4 0 248.7 0 256c0 64.9 24.2 124.2 64 169.4L233.4 256 172 194.6C138.5 222.9 95.3 240 48 240zm463.1 37.8c.6-7.2 .9-14.5 .9-21.8c0-64.9-24.2-124.2-64-169.4L278.6 256 340 317.4c33.4-28.3 76.7-45.4 124-45.4c16.2 0 32 2 47.1 5.8zm-4.7 31.9C492.9 306 478.7 304 464 304c-38.4 0-73.7 13.5-101.3 36.1L448 425.4c28.5-32.3 49.1-71.9 58.4-115.7zM340.1 362.7C317.5 390.3 304 425.6 304 464c0 14.7 2 28.9 5.7 42.4C353.4 497 393 476.5 425.4 448l-85.2-85.2zM317.4 340L256 278.6 86.6 448c45.1 39.8 104.4 64 169.4 64c7.3 0 14.6-.3 21.8-.9C274 496 272 480.2 272 464c0-47.3 17.1-90.5 45.4-124z"/></svg>')`
      }
    },
    "& .icon-soccer-ball": {
      "&:before": {
        backgroundPosition: "0 -29.4em",
        width: "1.6em",
        height: "1.6em",
      },
    },
    "& .icon-soccer-ball-penalty": {
      "&:before": {
        backgroundPosition: "0 -34.4em",
        width: "1.8em",
        height: "1.8em",
      },
    },
    "& .icon-yellow-card:before": {
      backgroundPosition: "0 -60.7em",
      width: "1.6em",
      height: "1.6em",
    },
    "& .icon-red-card:before": {
      backgroundPosition: "0 -19.4em",
      width: "1.6em",
      height: "1.6em",
    },
    "& .icon-svg.icon-whistle:before": {
      backgroundPosition: "0 -59em",
      width: "1.6em",
      height: "1.7em",
    },
    "& .icon-svg.icon-stopwatch:before": {
      backgroundPosition: "0 -51.8em",
      width: "1.7em",
      height: "1.9em",
    },
    //
    "& .alc-commentary-subheader__icon": {
      marginBottom: "13px",
    },
    "& .alc-commentary-subheader__title": {
      textTransform: "uppercase",
      color: "#31404b",
      fontWeight: 700,
      marginBottom: "0.75em",
    },
    "& .alc-time-stamp": {
      textAlign: "center",
      width: "4%",
      fontWeight: 700,
      color: "#31404b",
    },

    //
    "& .alc-commentary-cell": {
      width: "48%",
      "& strong": {
        fontWeight: 400,
      },
    },
    "& .alc-commentary-cell--team1": {
      "& .alc-commentary-cell__inner": {
        justifyContent: "flex-end",
        textAlign: "right",
      },
      "& .alc-commentary-cell__text": {
        order: 1,
      },
      "¨& .alc-commentary-cell__icon": {
        order: 2,
      },
    },
    //
    "& .alc-commentary-cell--team2": {
      "& .alc-commentary-cell__inner": {
        justifyContent: "flex-start",
        textAlign: "left",
      },

      "& .alc-commentary-cell__text": {
        order: 2,
      },

      "& .alc-commentary-cell__icon": {
        order: 1,
      },
    },
    "& .alc-commentary-cell__inner": {
      display: "flex",
      alignItems: "center",
    },
    "& .alc-commentary-cell__text": {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
    "& .alc-commentary-cell__icon": {
      flexBasis: "30px",
      minWidth: "30px",
      textAlign: "center",
      position: 'relative',
      "& @media (min-width: 992px)": {
        flexBasis: "50px",
        minWidth: "50px",
      },
    },
    "& .penalty": {
      position: 'absolute',
      left: 0,
      top: 6,
      color: "#fff",
      height: '1rem',
      width: '1rem',
      fontSize: '.7rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bolder',
      backgroundColor: '#000000',
      borderRadius: 100
    }
  },
}));
export default TableScoutStyles;
