import React from 'react';

//img
// import FormBG from '../../../assets/img/form-bg.jpg';

//components
import PageTitle from '../../../components/PageTitle';
import SocialMidiaLinks from '../../../components/SocialMidiaLinks';

//material ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

//forms
import FormCadastroFornecedor from './form/FormCadastro'
//section
import InfoSection from './sections/infoSection';

import MetaTags from 'react-meta-tags'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        fontSize: '1rem'
    },
    contentSection: {
        paddingTop: '50px',
        paddingBottom: '50px',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    contentForm: {
        paddingTop: '50px',
        paddingBottom: '50px',
    },
    // formSection: {
    //     padding: 0, margin: 0,
    //     backgroundImage: `url(${FormBG})`,
    //     backgroundPosition: 'top',
    //     backgroundSize: 'cover'
    // }

}));


function CadastroFornecedores(params) {
    const classes = useStyles();

    return (
        <>
            <MetaTags>
                <title>Cadastro de Fornecedores - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="Fornecedores" pageTitle="Cadastro de Fornecedores" />
            <Paper className={classes.root} square elevation={0}>
                <Container>
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6} >
                            <div maxWidth="lg" className={classes.contentForm}>
                                <FormCadastroFornecedor />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div maxWidth="lg" className={classes.contentSection}>
                                <InfoSection />
                                <div style={{ marginTop: 'auto' }}>
                                    <SocialMidiaLinks title="SIGA-NOS" align="left" iconSize="small" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    )
}

export default CadastroFornecedores;