import axios from "axios";
import { esporteApiEsporte } from "./ApiEsporte";
const baseURL = process.env.REACT_APP_API_URL;

export async function fetchTipoScouts(props) {
  const { esporte, formatMap } = props;
  const _api = esporteApiEsporte[esporte];

  const res = await axios.get(`${baseURL}/${_api}/api/tipo-scout`, {
    params: {
      pageSize: 100,
    },
  });
  const data = res.data.items;

  if (formatMap) {
    const map = new Map(data.map((obj) => [obj.id, obj]));
    return map;
  }

  return data;
}
