import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import useStyles from "../style";

function TableGrupoClubes({ eventoGrupoClubes, esporteEspecificacoes }) {
  const classes = useStyles();

  return (
    <Table className={classes.tabelaClassificacao}>
      <TableHead>
        <TableRow>
          <TableCell styele={{ width: "60%" }}>Equipe</TableCell>
          <TableCell align="center">J</TableCell>
          <TableCell align="center">P</TableCell>
          <TableCell align="center">V</TableCell>
          <TableCell align="center">D</TableCell>
          <TableCell align="center">E</TableCell>
          <TableCell align="center">
            {esporteEspecificacoes?.pontuacaoAbrev ?? "G"}P
          </TableCell>
          <TableCell align="center">
            {esporteEspecificacoes?.pontuacaoAbrev ?? "G"}C
          </TableCell>
          <TableCell align="center">
            {esporteEspecificacoes?.pontuacaoAbrev ?? "G"}AV
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {eventoGrupoClubes.map((item, key) => (
            <TableRow key={key}>
              <TableCell style={{ width: "60%" }}>
                <div className={classes.columnTime}>
                  <div>
                    <Avatar
                      className={classes.avatarEscola}
                      variant="square"
                      src={item.estabelecimento.urlLogo}
                      alt={item.estabelecimento.descricao}
                    />
                  </div>
                  <div>
                    <Link
                      className="link-escola"
                      to={`/feemg/escola/${
                        item.estabelecimento.id
                      }/${createSlug(item.estabelecimento.descricao)}`}
                    >
                      {item.estabelecimento.sigla}
                    </Link>
                    <span className="team-meta__place">
                      {item.estabelecimento.uf}
                    </span>
                  </div>
                </div>
              </TableCell>
              <TableCell colSpan={8}></TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      </TableBody>
    </Table>
  );
}

export default TableGrupoClubes;
