import React from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import CarouselNoticias from './CarouselNoticias';

import { PillButtonPrimary } from '../../../components/Styles';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    heading: {
        fontSize: '1.2rem',
        fontWeight: 900,
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        }
    },
    noticiasHeading: {
        background: '#fff',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '15px 0',
        '& p': {
            fontSize: '15px',
            margin: 0,
        }
    },
}));

export default function Noticias() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.noticiasHeading}>
                <Container>
                    <Typography className={classes.heading} color="primary" variant="h3">NOTÍCIAS</Typography>
                    <p>Mantenha-se informado de todas as nossas novidades</p>
                </Container>
            </div>
            <CarouselNoticias />
            <div style={{ textAlign: 'center', margin: 20 }}>
                <PillButtonPrimary component={Link} to="/noticias" color="primary" variant="contained" disableElevation>ver mais notícias</PillButtonPrimary>
            </div>
        </React.Fragment>
    )

}