import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import React from 'react'

const useStyles = makeStyles((theme) => ({
  AccordionSummaryRoot: {
    background: theme.palette.primary.light,
    // minHeight: '1rem !important', 
    // "& .MuiAccordionSummary-content": {
    //   margin: 0,
    // },
    // "& .Mui-expanded": {
    //   minHeight: '1rem !important',
    //   margin: 0,
    // },
    "& .MuiTypography-root": {
      color: '#FFF',
      fontSize: '0.9rem',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    "& .MuiButtonBase-root": {
      color: "white",
      // padding: 6,
    }
  },
  TableAnexos: {
    '& .MuiTableBody-root .MuiTableCell-root': {
      fontSize: 13
    },
    '& .MuiButtonBase-root': {
      fontSize: 10,
      fontWeight: 600
    }
  }
}));

function AnexosPartida({ documentos, expanded }) {
  const classes = useStyles();
  const baseurl = `${process.env.REACT_APP_SGE_URL}/_uploads/relatorioEvento/`
 
  return (
    <div>
      <Accordion expanded={expanded}>
        <AccordionSummary
          className={classes.AccordionSummaryRoot}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content`}
          id={`panel-header`}
        >
          <Typography>DOCUMENTOS DA PARTIDA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table size='small' className={classes.TableAnexos}>
            <TableBody>
              {documentos.map((item, key) => (
                <TableRow>
                  <TableCell>{item.descricao}</TableCell>
                  <TableCell>{moment(item.created_at * 1000).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell align='center' style={{ width: 130 }}>
                    <Button size='small' variant='contained' color='secondary'
                      target="_blank"
                      href={item.link || `${baseurl}${item.arquivo}`}
                    >download</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AnexosPartida