import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

import InputMask from "react-input-mask";
import swal from "sweetalert";

import geraProtocolo from "../../../util/geraProtocolo";

import { GreenCheckbox } from "../../../components/Styles";

import ouvidoria from "../../../assets/data/models/ouvidoria";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "1rem",
  },
  title: {
    fontWeight: 900,
    fontSize: "1.55rem",
  },
  contentContainer: {
    padding: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    "& .MuiInputBase-root": {
      borderRadius: 0,
      backgroundColor: "rgba(255,255,255,0.8)",
      transition: "all 300ms ease-in-out",
      "&:focus": {
        backgroundColor: "rgba(255,255,255,1)",
      },
    },
  },
  btnEnviar: {
    backgroundColor: theme.palette.success.main,
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
    color: "white",
    fontWeight: "bold",
    fontSize: "1rem",
    borderRadius: "30px",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  linkPolitica: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      color: "gray",
    },
  },
}));

function FaleConoscoFrom() {
  const classes = useStyles();
  const [departamento, setDepartamento] = useState("");
  const [formValues, setFormValues] = useState(ouvidoria);
  const [termo, setTermo] = useState(false);

  const handleChangeValues = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const [protocolo, setProtocolo] = useState("");
  function validateForm(event) {
    event.preventDefault();
    if (termo) {
      var protocolo = geraProtocolo();
      var datenow = Math.round(new Date() / 1000);
      setFormValues({
        ...formValues,
        motivo: departamento,
        protocolo: protocolo,
        updated_at: datenow,
        created_at: datenow,
      });
      setProtocolo(protocolo);
    } else {
      swal({
        title: "Aceite nossa política de privacidade",
        text: "Você precisa marcar que leu e aceita nossa política de privacidade para realizar o cadastro.",
        icon: "info",
      });
    }
  }

  useEffect(() => {
    if (formValues.created_at !== "") {
      async function postFornecedor() {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/governanca/api/solicitacao-ouvidoria`,
            formValues
          )
          .then((res) => {
            // console.log(res);
            // console.log(res.data);
            setTermo(false);
            setFormValues({
              ...formValues,
              nome_solicitante: "",
              telefone: "",
              email: "",
              mensagem: "",
              created_at: "",
              updated_at: "",
              created_by: "",
              updated_by: "",
            });
            setPhone("");
            setDepartamento("");
            swal({
              title: "Sua mensagem foi enviada!",
              text: `O protocolo da sua solicitação é: ${protocolo}`,
              icon: "success",
            });
          })
          .catch((err) => {
            console.error(err);
            setProtocolo("");
            setFormValues({
              ...formValues,
              created_at: "",
              updated_at: "",
            });
            setTermo(false);
            swal({
              title: "Erro ao cadastrar",
              text: "Houve um erro ao realizar o cadastro, tente novamente.",
              icon: "error",
            });
          });
      }
      postFornecedor();
    }
  }, [formValues.created_at, formValues, protocolo]);

  const [phone, setPhone] = useState("");

  const changePhone = (e) => {
    var _phone = e.target.value;
    setPhone(_phone);
    if (_phone) {
      setFormValues({
        ...formValues,
        telefone: _phone
          .replace(/ /g, "")
          .replace(/-/g, "")
          .replace(/\(|\)/g, ""),
      });
    }
  };

  const handleChangeDepartamento = (e) => {
    setDepartamento(e.target.value);
  };

  const handleChangeCheckbox = (e) => {
    setTermo(e.target.checked);
  };

  return (
    <form onSubmit={validateForm}>
      <Alert
        severity="info"
        style={
          protocolo !== "" ? { marginBottom: "1rem" } : { display: "none" }
        }
      >
        Seu protocolo é: {protocolo}
      </Alert>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Nome"
            value={formValues.nome_solicitante}
            required
            type="name"
            name="nome_solicitante"
            onChange={handleChangeValues}
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            mask="(99) 99999-9999"
            value={phone}
            onChange={changePhone}
            name="telefone"
            maskChar=" "
          >
            {() => (
              <TextField
                label="Telefone"
                variant="outlined"
                className={classes.formControl}
                fullWidth
                required
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            value={formValues.email}
            required
            type="email"
            name="email"
            onChange={handleChangeValues}
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            variant="outlined"
            required
            fullWidth={true}
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Departamento:
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={departamento}
              fullWidth={true}
              onChange={handleChangeDepartamento}
              label="Departamento"
            >
              <MenuItem value="">&nbsp;</MenuItem>
              <MenuItem value="arbitragem">Arbitragem</MenuItem>
              <MenuItem value="assessoria de comunicação">
                Assessoria de Comunicação
              </MenuItem>
              <MenuItem value="dep. financeiro">
                Departamento Financeiro
              </MenuItem>
              <MenuItem value="dep. técnico">Departamento Técnico</MenuItem>
              <MenuItem value="presidência">Presidência</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Mensagem"
            value={formValues.mensagem}
            required
            name="mensagem"
            onChange={handleChangeValues}
            multiline
            rows={6}
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <GreenCheckbox checked={termo} onChange={handleChangeCheckbox} />
            }
            label={
              <>
                Eu li e aceito a{" "}
                <Link
                  to="/politica-de-privacidade"
                  className={classes.linkPolitica}
                >
                  {" "}
                  política de privacidade
                </Link>
              </>
            }
          />
          <div>
            <Button
              type="submit"
              className={classes.btnEnviar}
              variant="contained"
            >
              Enviar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default FaleConoscoFrom;
