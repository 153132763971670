import React, { useEffect, useRef, useState } from "react";
import { Link, Route, useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import { PillButtonSuccess } from "../../../../components/Styles";

import { makeStyles } from "@material-ui/core/styles";

// - api
import { gestaoApi } from "../../../../services/api";

import CardEscola from "./CardEscola";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 200,
    maxWidth: 500,
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-root": {
      backgroundColor: "white",
    },
    "& .MuiFilledInput-root": {
      borderRadius: "4px !important",
      overflow: "hidden",
    },
  },
  paginationInvertido: {
    "& .MuiButtonBase-root, & .MuiPaginationItem-ellipsis": {
      color: "white",
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3);",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
  },
}));

export default function Filiados(params) {
  const classes = useStyles();
  const page = params.page;
  const queryParam = params.queryParam;
  const [escolas, setEscolas] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState(queryParam);

  let history = useHistory();

  useEffect(() => {
    async function getEscolas() {
      await gestaoApi
        .get("estabelecimento", {
          params: {
            id_estabelecimento_tipo: 8,
            descricao: queryParam,
            expand: "endereco,representante,tels",
            flg_ativo: 1,
            flag_del: 0,
            "estabelecimentoStatus.id_estabelecimento_pai": 26,
            "estabelecimentoStatus.status": "A",
            //"estabelecimentoStatus.data_validade": "2021-12-31",
            sort: "registro_federacao",
            pageSize: 24,
            page: page,
          },
        })
        .then((response) => {
          setEscolas(response.data.items);
          setTotalPages(response.data._meta.pageCount);
        });
    }
    getEscolas();
  }, [page, queryParam]);

  useEffect(() => {
    if (page || queryParam) {
      executeScroll();
    }
  }, [queryParam, page]);

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm !== "") {
        history.push(`/feemg/escolas-filiadas?q=${searchTerm}`);
      } else {
        history.push("/feemg/escolas-filiadas");
      }
    }
  };

  const filiadosTopEl = useRef(null);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: filiadosTopEl.current.offsetTop - 240,
    });

  return (
    <div ref={filiadosTopEl}>
      <h3 className="heading-white">Filiados</h3>
      <div>
        <div className={classes.formControl}>
          <div className={classes.inputGroup}>
            <TextField
              label="Pesquisar"
              size="small"
              style={{ marginRight: ".4rem" }}
              fullWidth={true}
              value={searchTerm}
              onKeyDown={(e) => handleSearchKeyDown(e)}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="filled"
            />
            <div>
              {searchTerm ? (
                <Link
                  component={PillButtonSuccess}
                  className={classes.searchButton}
                  to={`/feemg/escolas-filiadas${searchTerm !== "" || searchTerm !== null
                      ? `?q=${searchTerm}`
                      : ""
                    }`}
                  variant="contained"
                  disableElevation
                >
                  Pesquisar
                </Link>
              ) : (
                <Link
                  component={PillButtonSuccess}
                  className={classes.searchButton}
                  to="/feemg/escolas-filiadas"
                  variant="contained"
                  disableElevation
                >
                  Pesquisar
                </Link>
              )}
            </div>
          </div>
        </div>

        <CardEscola escolas={escolas} />

        <div
          style={{
            padding: "20px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Route>
            {({ location }) => {
              const query = new URLSearchParams(location.search);
              const page = parseInt(query.get("p") || "1", 10);
              return (
                <Pagination
                  page={page}
                  count={totalPages}
                  className={classes.paginationInvertido}
                  renderItem={(item) => {
                    if (!queryParam) {
                      return (
                        <PaginationItem
                          component={Link}
                          to={`/feemg/escolas-filiadas${item.page === 1 ? "" : `?p=${item.page}`
                            }`}
                          {...item}
                        />
                      );
                    } else {
                      return (
                        <PaginationItem
                          component={Link}
                          to={`/feemg/escolas-filiadas${item.page === 1 ? "" : `?q=${queryParam}`
                            }&p=${item.page}`}
                          {...item}
                        />
                      );
                    }
                  }}
                />
              );
            }}
          </Route>
        </div>
      </div>
    </div>
  );
}
