import React, { useEffect, useState } from 'react';

import quemSomos from '../../../assets/img/quem-somos-historia.jpg'
import quemSomosBG from '../../../assets/img/quem-somos-bg.jpg'
import { Link } from 'react-router-dom';

//components
import PageTitle from '../../../components/PageTitle';
import { PillButtonPrimary } from '../../../components/Styles';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// - api
import { governancaApi } from '../../../services/api';

// //data
// import codigoEtica from '../../../assets/data/codigo-etica';

import ReactHtmlParser from 'react-html-parser';

import MetaTags from 'react-meta-tags'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: theme.palette.primary.contrastText,
        paddingTop: '40px',
        fontSize: '1rem'
    },
    title: {
        fontWeight: 900,
        fontSize: '1.55rem'
    },
    section1: {
        paddingBottom: '40px',
        '& p': {
            textAlign: 'justify'
        }
    },
    section1Img: {
        background: `url(${quemSomos}) center`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
        maxHeight: 350
    },
    section2: {
        background: `url(${quemSomosBG}) center`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: 'white',
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    section3: {
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    section4: {
        background: '#e6e6e6',
        paddingTop: '40px',
        paddingBottom: '40px'
    },
    destaque: {
        padding: '30px',
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main
    },
    DoubleColumn: {
        '& p': {
            marginTop: 0,
            textAlign: 'justify'
        },
        [theme.breakpoints.up('md')]: {
            columnCount: 2,
            columnGap: '32px',
        }
    },
    DoubleColumnUl: {
        '& ul': {
            [theme.breakpoints.up('md')]: {
                columnCount: 2,
                columnGap: '32px',
            }
        }
    }

}));


function QuemSomos() {
    const classes = useStyles();
    const [orgao, setOrgao] = useState([])


    useEffect(() => {
        async function getQuemSomos() {
            await governancaApi.get('orgao', {
                params: {
                    id_menu: 10
                },
            }).then((response) => {
                setOrgao(response.data.items)
                // console.log(response.data.items)
            });
        }

        getQuemSomos()
    }, [])

    const Section1 = (section) => {
        return (
            <Container maxWidth="lg" className={classes.section1}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.title} variant="h4" component="h4" gutterBottom color="primary">{section.titulo}</Typography>
                        {ReactHtmlParser(section.corpo)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.section1Img}></div>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    const Section2 = (sections) => {
        return (
            <Paper square elevation={0} className={classes.section2}>
                <Container maxWidth="lg">
                    <Grid container spacing={4}>
                        {sections.map((item, key) => {
                            if (key > 0 && key < 5) {
                                return (
                                    <Grid item xs={12} md={6} key={key}>
                                        <Typography className={classes.title} variant="h4" component="h4" gutterBottom color="inherit">{item.titulo}</Typography>
                                        {ReactHtmlParser(item.corpo)}
                                    </Grid>
                                )
                            } else {
                                return(<React.Fragment></React.Fragment>)
                            }
                        })}
                    </Grid>
                </Container>
            </Paper>
        )

    }

    const Section3 = (section) => {
        return (
            <Container maxWidth="lg" className={classes.section3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h4" component="h4" gutterBottom color="primary">{section.titulo}</Typography>

                        <div className={classes.DoubleColumn}>
                            {ReactHtmlParser(section.corpo)}
                            <PillButtonPrimary component={Link} to="/feemg/organograma" color="primary" variant="contained" disableElevation>Conheça nossa estrutura organizacional</PillButtonPrimary>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    const Section4 = (section) => {
        return (
            <Paper square elevation={0} className={classes.section4}>
                <Container maxWidth="lg">
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.title} variant="h4" component="h4" gutterBottom color="primary">{section.titulo}</Typography>

                            <div className={classes.DoubleColumnUl} >
                                {ReactHtmlParser(section.corpo)}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {Section5(orgao[7])}
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        )
    }

    const Section5 = (section) => {
        return (
            <p className={classes.destaque}>
                {ReactHtmlParser(section.corpo)}
            </p>
        )
    }

    return (
        <>
            <MetaTags>
                <title>Quem Somos - FEEMG - Federação de Esportes Estudantis de Minas Gerais</title>
            </MetaTags>
            <PageTitle menuTitle="FEEMG" pageTitle="Quem Somos" />
            <div className={classes.root}>
                {orgao.length > 0 ?
                    <React.Fragment>
                        {Section1(orgao[0])}
                        {Section2(orgao)}
                        {Section3(orgao[5])}
                        {Section4(orgao[6])}

                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                }
            </div>
        </>
    )
}

export default QuemSomos;